import { Box, Button, ButtonBase, Popover } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledButton = styled(ButtonBase)(() => ({
  padding: '9px',
  marginRight: '-10px',
  borderRadius: '50%',
}));

export const StyledDialog = styled(Popover)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '4px',
    backgroundColor: '#FAFCFF',
    minHeight: '420px',
    padding: '50px 40px 40px',
    margin: '16px 0',
    width: '600px',
    [theme.breakpoints.up('xl')]: {
      width: '800px',
    },
  },
}));

export const SectionBox = styled(Box)(() => ({
  marginBottom: '40px',
}));

export const StyledTextField = {
  width: '100%',
  '& .MuiInputBase-root': { borderRadius: '16px' },
  '& .MuiSvgIcon-root': {
    color: '#004D43',
  },
  '& .MuiFormLabel-root.Mui-error': {
    color: '#595959 !important',
  },
};

export const SubmitBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'end',
  gap: '20px',
  marginTop: '40px',
}));

export const CancelButton = styled(Button)(() => ({
  width: '156px',
  border: '1px solid #00B39B',
  backgroundColor: '#FAFCFF',
  fontWeight: '700',
  color: '#00B39B',
  '&:hover': {
    backgroundColor: '#00B39B',
  },
  '&:active': {
    backgroundColor: '#00B39B',
  },
}));

export const SubmitButton = styled(Button)(() => ({
  width: '156px',
  backgroundColor: '#00B39B',
  '&:hover': {
    backgroundColor: '#00B39B',
    filter: 'brightness(85%)',
  },
  '&:active': {
    backgroundColor: '#00B39B',
  },
  '&:disabled': {
    opacity: 0.8,
  },
}));

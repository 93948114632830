import {
  Accordion,
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  Select,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledbBoxContainer = styled(Box)(() => ({
  position: 'absolute',
  textAlign: 'center',
  top: '49vh',
  left: '51%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#FAFCFF',
  padding: '20px',
  width: '940px',
  borderRadius: '16px',
  height: '618px',
  display: 'block',
  overflow: 'auto',
}));

export const TitleMoldal = styled(Typography)(() => ({
  color: '#707070',
  fontFamily: 'Montserrat',
  fontWeight: 500,
  fontSize: '18px',
  lineHeight: '22px',
  padding: '10px',
}));

export const SaveCoverageButton = styled(Button)(() => ({
  width: '210px',
  display: 'flex',
  gap: '10px',
  border: '1px solid #004D43',
  borderRadius: '30px',
  fontWeight: '600',
  height: '48px',
  color: '#FAFCFF',
  backgroundColor: '#004D43',
  fontFamily: 'Montserrat',
  textTransform: 'lowercase',
  '&:hover': {
    backgroundColor: '#00796B',
    borderColor: '#00796B',
  },
}));

export const CancelButton = styled(Button)(() => ({
  width: '210px',
  display: 'flex',
  gap: '10px',
  border: '1px solid #004D43',
  borderRadius: '30px',
  color: '#004D43',
  fontFamily: 'Montserrat',
  fontWeight: '600',
  height: '48px',
  backgroundColor: '#FAFCFF',
  textTransform: 'lowercase',
  '&:hover': {
    backgroundColor: '#EDEFF2',
    color: '#004D43',
  },
}));

export const ScrollableGrid = styled(Grid)(() => ({
  height: '406px',
  border: '1px solid #B9BABB',
  borderRadius: '6px',
  width: '872px',
  overflowY: 'scroll', // Mantém a barra de rolagem visível
  margin: '0px auto',
  padding: '4px',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#8B8C8E',
    borderRadius: '4px',
  },
}));

export const CustomAccordion = styled(Accordion)(() => ({
  backgroundColor: '#FAFCFF',

  boxShadow: 'none',
  '&:before': {
    display: 'none',
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: '#06DFC2',
    marginLeft: '60px',
  },
  '& .MuiAccordionSummary-content': {
    margin: '0 !important',
    backgroundColor: 'transparent',
  },
  '& .MuiAccordionSummary-root': {
    backgroundColor: 'transparent',
    minHeight: '48px !important',
    '&.Mui-expanded': {
      backgroundColor: 'transparent',
      minHeight: '48px !important',
    },
  },
}));

export const StyledSelect = styled(Autocomplete)(() => ({
  width: '252px',
  textAlign: 'start',
  fontSize: '14px',
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: 16,
  },
  '&.MuiOutlinedInput-root': {
    height: 48,
    color: '#707070',
    '& fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#00B39B',
    },
  },
}));

export const StyledSelectUf = styled(Select)(() => ({
  width: '122px',
  textAlign: 'start',
  fontSize: '14px',
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: 16,
  },
  '&.MuiOutlinedInput-root': {
    height: 48,
    color: '#707070',
    '& fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#00B39B',
    },
  },
}));

export const AddNewPoleButton = styled(Button)(() => ({
  width: '184px',
  display: 'flex',
  gap: '10px',
  border: '1px solid #00B39B',
  borderRadius: '30px',
  color: '#00B39B',
  fontWeight: '600',
  height: '48px',
  backgroundColor: '#FAFCFF',
  textTransform: 'lowercase',
  '&:hover': {
    backgroundColor: '#00B39B',
  },
  '&:disabled': {
    color: '#00B39B',
  },
}));

export const DeleteMicroregionButton = styled(IconButton)(() => ({
  color: '#06DFC2',
  width: '42px',
  border: '1px solid #06DFC2',
  borderRadius: '12px',
  marginRight: '35px',
  '&:hover': {
    backgroundColor: 'rgba(6, 223, 194, 0.1)',
  },
  '&.Mui-disabled': {
    color: 'rgba(0, 0, 0, 0.26)',
    border: '1px solid rgba(0, 0, 0, 0.12)',
  },
}));

export const AddButton = styled(IconButton)(() => ({
  color: '#06DFC2',
  width: '42px',
  border: '1px solid #06DFC2',
  borderRadius: '12px',
  '&:hover': {
    backgroundColor: 'rgba(6, 223, 194, 0.1)',
  },
  '&.Mui-disabled': {
    color: 'rgba(0, 0, 0, 0.26)',
    border: '1px solid rgba(0, 0, 0, 0.12)',
  },
}));

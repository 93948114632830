/* eslint-disable react/no-array-index-key */
import { AccordionDetails, Grid, IconButton, Skeleton } from '@mui/material';
import { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';

import {
  IconExpandMoreMS,
  IconDeleteMS,
} from '../../../../../../constants/icons';
import { useTypologyOperations } from '../ModalAddTypology/typologyOperations';
import {
  CoverageContainer,
  CoverageTitle,
  NoContentText,
  CityText,
  StyledAccordionSummary,
  StyledAccordion,
  AccordionTitle,
  DisplacementText,
  DisplacementValue,
  ContentContainer as StyledContentContainer,
  ScrollableGrid,
} from './styles';
import { Property, TypologyGroupGridProps } from './types';

export function TypologyGroupGrid({
  data,
  fetchTypologyGroups,
}: TypologyGroupGridProps): JSX.Element {
  const [expandedPanel, setExpandedPanel] = useState<string | false>(false);
  const scrollableGridRef = useRef<HTMLDivElement>(null);
  const { notice } = useParams();
  const noticeId = parseInt(notice as string, 10);

  const { removeTypology, isLoading } = useTypologyOperations({
    noticeId,
    onSuccess: () => {
      fetchTypologyGroups();
    },
  });

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedPanel(isExpanded ? panel : false);

      if (isExpanded && panel === Object.keys(data).pop()) {
        setTimeout(() => {
          scrollableGridRef.current?.scrollTo({
            top: scrollableGridRef.current.scrollHeight,
            behavior: 'smooth',
          });
        }, 300);
      }
    };
  const handleDelete = async (
    property: Property,
    event: React.MouseEvent
  ): Promise<void> => {
    event.stopPropagation();
    await removeTypology(property.id);
  };

  const formatPropertyType = (type: string): string => {
    const typeMap: { [key: string]: string } = {
      house: 'Casa',
      apartment: 'Apartamento',
      building: 'Prédio',
      parking_space: 'vaga de garagem',
      store: 'loja',
      land: 'terreno',
      commercial_room: 'sala comercial/pavimento corrido',
      mall_store: 'loja em shopping',
      shed: 'galpões',
      commercial_residential_mix_building:
        'prédios comerciais/residenciais misto',
      gleba: 'gleba',
    };
    return typeMap[type] || type;
  };

  const formatArea = (area: number | number[]): JSX.Element => {
    if (Array.isArray(area)) {
      if (area.length === 2) {
        return (
          <>
            entre <DisplacementValue>{area[0]}</DisplacementValue> e{' '}
            <DisplacementValue>{area[1]}</DisplacementValue> m²
          </>
        );
      }
      return (
        <>
          até <DisplacementValue>{area[0]}</DisplacementValue> m²
        </>
      );
    }
    return (
      <>
        até <DisplacementValue>{area}</DisplacementValue> m²
      </>
    );
  };

  const formatValue = (value: number): string => {
    return value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  };

  if (isLoading) {
    return (
      <CoverageContainer item container xs={12} mt={2}>
        <Grid item container>
          <Skeleton
            width={200}
            height={32}
            style={{
              backgroundColor: '#fafcff',
              boxShadow: '0px 3px 6px #00000029',
            }}
          />
        </Grid>
        <ScrollableGrid item container mt={2}>
          {[...Array(Object.keys(data || {}).length || 1)].map((_, index) => (
            <Skeleton
              style={{
                backgroundColor: '#fafcff',
                boxShadow: '0px 3px 6px #00000029',
              }}
              key={index}
              variant="rectangular"
              width="98%"
              height={45}
              sx={{ mb: 2, borderRadius: 1 }}
            />
          ))}
        </ScrollableGrid>
      </CoverageContainer>
    );
  }

  return (
    <CoverageContainer item container xs={12} mt={2}>
      <Grid item container>
        <CoverageTitle>Grupos de Tipologia</CoverageTitle>
      </Grid>
      <ScrollableGrid item container mt={2} ref={scrollableGridRef}>
        {Object.keys(data || {}).length === 0 ? (
          <NoContentText>Nenhuma tipologia adicionada</NoContentText>
        ) : (
          Object.entries(data).map(([groupKey, group]) => (
            <StyledAccordion
              key={groupKey}
              expanded={expandedPanel === groupKey}
              onChange={handleAccordionChange(groupKey)}
            >
              <StyledAccordionSummary
                aria-controls={`${groupKey}-content`}
                id={`${groupKey}-header`}
                sx={{
                  '& .MuiAccordionSummary-content': {
                    margin: 0,
                    flex: 1,
                  },
                }}
              >
                <Grid
                  container
                  alignItems="center"
                  sx={{
                    width: '100%',
                    position: 'relative',
                  }}
                >
                  <Grid
                    item
                    container
                    alignItems="center"
                    xs
                    sx={{
                      '& .expandIcon': {
                        color: '#00B39B',
                        marginLeft: '12px',
                      },
                    }}
                  >
                    <AccordionTitle>{group.title}</AccordionTitle>
                    <span className="expandIcon">{IconExpandMoreMS}</span>
                  </Grid>
                  <Grid item>
                    <IconButton
                      onClick={(e) => handleDelete(group.properties[0], e)}
                      sx={{
                        color: '#707070',
                        marginRight: '32px',
                        '&:hover': {
                          color: '#00B39B',
                        },
                      }}
                    >
                      {IconDeleteMS}
                    </IconButton>
                  </Grid>
                </Grid>
              </StyledAccordionSummary>
              <AccordionDetails
                style={{
                  padding: 0,
                }}
              >
                <StyledContentContainer>
                  <DisplacementText>
                    Valor:{' '}
                    <DisplacementValue>
                      {formatValue(group.value)}
                    </DisplacementValue>
                  </DisplacementText>
                  <Grid item container direction="column">
                    {group.properties.map((property, propertyIndex) => (
                      <Grid item key={propertyIndex} mt={0.6}>
                        <CityText>
                          <DisplacementValue>
                            {formatPropertyType(property.type)}
                          </DisplacementValue>{' '}
                          {formatArea(property.area)}
                        </CityText>
                      </Grid>
                    ))}
                  </Grid>
                </StyledContentContainer>
              </AccordionDetails>
            </StyledAccordion>
          ))
        )}
      </ScrollableGrid>
    </CoverageContainer>
  );
}

import { Box, Grid, styled, Typography } from '@mui/material';

export const GridContainer = styled(Grid)(() => ({
  padding: '47px 60px 0 160px',
  backgroundColor: '#FAFCFF',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
}));

export const TitleBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '0 0 40px 20px',
  alignItems: 'center',
  gap: '20px',
}));

export const TitleTypography = styled(Typography)(() => ({
  fontSize: '28px',
  fontWeight: '600',
  textTransform: 'uppercase',
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  flex: '1',
  '& span': { fontSize: '26px', color: '#00B39B' },
}));

import { Navigate } from 'react-router-dom';

import { useStoragedJwt } from './hooks/useDecodedJwt';

export default function PrivateRoute({
  children,
  allowedToBank = true,
  allowedToUser = true,
}: {
  children: React.ReactElement;
  allowedToBank?: boolean;
  allowedToUser?: boolean;
}): JSX.Element {
  const isBank = process.env.REACT_APP_IS_BANK === 'true';

  try {
    const decoded = useStoragedJwt();

    if (decoded === null || decoded.expired) {
      localStorage.removeItem('control-center-token');
      return <Navigate to="/login" />;
    }
    if (isBank && !allowedToBank) {
      return <Navigate to="*" />;
    }
    if (!isBank && !allowedToUser) {
      return <Navigate to="*" />;
    }
    return children;
  } catch {
    return <Navigate to="/login" />;
  }
}

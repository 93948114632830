import { Box, InputBase, styled } from '@mui/material';

export const GridContainer = styled(Box)(() => ({ theme }) => ({
  [theme.breakpoints.up('xl')]: {
    padding: '40px 60px',
  },
}));

export const SearchBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '20px',
  alignItems: 'center',
  '& span': { fontSize: '30px' },
  [theme.breakpoints.up('xl')]: {
    marginBottom: '32px',
  },
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  borderRadius: '32px',
  border: '1px solid #004D43',
  padding: '0 18px',
  '& .MuiInputBase-input': {
    width: '200px',
    padding: '10px 0 10px',
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('md')]: {
      width: '200px',
      '&:focus': {
        width: '300px',
      },
    },
  },
}));

/* eslint-disable react-hooks/exhaustive-deps */
import { Table, TableBody, TableHead } from '@mui/material';
import { useState } from 'react';

import { NotificationsModal } from '../Modal';
import { Cell, Row, StyledTableCell, StyledTableContainer } from './styles';

// interface TableArchivedProps {
//   tableData: WorkOrderData[] | undefined;
// }

export function NotificationsTable(): JSX.Element {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = (): void => {
    setOpenModal(true);
  };
  const handleCloseModal = (): void => {
    setOpenModal(false);
  };

  return (
    <>
      <StyledTableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <Row>
              <Cell align="center" width={250} sx={{ borderTopLeftRadius: 8 }}>
                data de entrada
              </Cell>
              <Cell align="center" width={200}>
                número OS
              </Cell>
              <Cell align="center">empresa</Cell>
              <Cell align="center" sx={{ borderTopRightRadius: 8 }} />
            </Row>
          </TableHead>
          <TableBody>
            <Row onClick={() => handleOpenModal()}>
              <StyledTableCell align="center">18/12/2023</StyledTableCell>
              <StyledTableCell align="center">01234567</StyledTableCell>
              <StyledTableCell align="center">Gama Company</StyledTableCell>
              <StyledTableCell align="center">
                * espaço para mensagem *
              </StyledTableCell>
            </Row>
            <Row onClick={() => handleOpenModal()}>
              <StyledTableCell align="center">18/12/2023</StyledTableCell>
              <StyledTableCell align="center">01234567</StyledTableCell>
              <StyledTableCell align="center">
                Beta empreendimentos
              </StyledTableCell>
              <StyledTableCell align="center">
                * espaço para mensagem *
              </StyledTableCell>
            </Row>
            <Row onClick={() => handleOpenModal()}>
              <StyledTableCell align="center">18/12/2023</StyledTableCell>
              <StyledTableCell align="center">01234567</StyledTableCell>
              <StyledTableCell align="center">Gama Company</StyledTableCell>
              <StyledTableCell align="center">
                * espaço para mensagem *
              </StyledTableCell>
            </Row>
          </TableBody>
        </Table>
      </StyledTableContainer>
      <NotificationsModal open={openModal} onClose={handleCloseModal} />
    </>
  );
}

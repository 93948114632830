import { Box, Modal, Paper, styled } from '@mui/material';

export const ModalStyled = styled(Modal)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  left: 460,
  '& .MuiBackdrop-root': {
    backgroundColor: 'transparent',
  },
}));
export const PaperStyled = styled(Paper)(() => ({
  width: 609,
  height: 727,

  '& header': {
    width: '100%',
    right: 0,
    display: 'flex',
    justifyContent: 'end',
    padding: 16,

    '& span': {
      fontWeight: 600,
      fontSize: 32,
      color: '#004E43',
    },
  },
}));
export const HeaderDescription = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  color: '#004E43',
  justifyContent: 'center',
  gap: 32,
  margin: '48px 0',
  // background: 'red',
}));

export const DescriptionContainer = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  padding: '16px',
  justifyContent: 'flex-start',
}));

export const CitiesServerd = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '45%',
  borderRight: '1px solid #DCDDE0',
  '&:first-of-type': {
    fontWeight: '400 !important',
  },
  '& p': {
    font: '500 16px Montserrat',
    margin: '12px',
  },
}));

export const CompanyTextDescription = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  '& p': {
    font: '400 16px Montserrat',
    margin: '12px',
    '& strong': {
      fontWeight: 500,
    },
  },
}));

import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledbBoxContainer = styled(Box)(() => ({
  position: 'absolute',
  textAlign: 'center',
  top: '49vh',
  left: '51%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#FAFCFF',
  transition: 'height 0.3s ease',
  padding: '20px',
  width: '940px',
  borderRadius: '16px',
  display: 'block',
  overflow: 'auto',
}));

export const TitleMoldal = styled(Typography)(() => ({
  color: '#707070',
  fontFamily: 'Montserrat',
  fontWeight: 500,
  fontSize: '18px',
  lineHeight: '22px',
  padding: '10px',
}));

export const SaveCoverageButton = styled(Button)(() => ({
  width: '210px',
  display: 'flex',
  gap: '10px',
  border: '1px solid #004D43',
  borderRadius: '30px',
  fontWeight: '600',
  height: '48px',
  color: '#FAFCFF',
  backgroundColor: '#004D43',
  fontFamily: 'Montserrat',
  textTransform: 'lowercase',
  '&:hover': {
    backgroundColor: '#00796B',
    borderColor: '#00796B',
  },
}));

export const ScrollableGrid = styled(Grid)(() => ({
  height: '236px',
  borderRadius: '6px',
  width: '860px',
  overflowY: 'auto',
  margin: '0px auto',
  padding: '4px',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#8B8C8E',
    borderRadius: '4px',
  },
}));

export const CloseButton = styled(IconButton)(() => ({
  color: '#00B39B',
  padding: '8px',
  '&:hover': {
    backgroundColor: 'rgba(0, 179, 155, 0.04)',
  },
}));

export const TableDivider = styled('div')(() => ({
  width: '100%',
  height: '1px',
  backgroundColor: '#00000029',
}));

export const StyledTextFieldGroup = styled(TextField)(() => ({
  width: '308px',
  fontSize: '14px',

  '& .MuiOutlinedInput-root': {
    height: 46,
    borderRadius: 16,
    color: '#707070',
    display: 'flex',
    alignItems: 'center',
    padding: '0 14px',
    '& fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#00B39B',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#707070',
    '&.Mui-focused': {
      color: '#00B39B',
    },
    top: '-4px',
  },
}));

export const StyledTextFieldMultiline = styled(TextField)(() => ({
  width: '100%',
  fontSize: '14px',
  '& .MuiOutlinedInput-root': {
    borderRadius: 16,
    color: '#707070',
    padding: '14px',
    '& fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#00B39B',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#707070',
    '&.Mui-focused': {
      color: '#00B39B',
    },
  },
}));

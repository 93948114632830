import {
  Box,
  IconButton,
  Modal,
  styled,
  TextField,
  Typography,
} from '@mui/material';

export const StyledModal = styled(Modal)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const StyledContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#FAFCFF',
  padding: '6.5rem 4rem',
  width: '50%',
  height: '80%',
  borderRadius: '20px',
  position: 'relative',

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: '1.5rem',

  '&.MuiBox-root': {
    outline: 'none',
    border: 'none',
  },

  [theme.breakpoints.down('xl')]: {
    width: '70%',
  },
}));

export const StyledHeaderModal = styled(Box)(() => ({
  width: '95%',
  paddingRight: 48,
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
}));

export const StyledLineHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: '#0B2623',

  [theme.breakpoints.down('xl')]: {
    gap: '2rem',
  },
}));

export const MessagesContainer = styled(Box)(() => ({
  border: '1px solid #D7D8DB',
  borderRadius: 4,
  minHeight: 250,
  height: '100%',
  position: 'relative',
  width: '95%',

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  padding: '16px 8px',
}));

export const Messages = styled(Box)(({ theme }) => ({
  overflowY: 'scroll',
  height: '85%',
  position: 'relative',
  padding: 8,

  [theme.breakpoints.down('xl')]: {
    height: '75%',
  },

  [theme.breakpoints.down('lg')]: {
    height: '72%',
  },
}));

export const StyledTextField = styled(TextField)(() => ({
  width: '90%',
  bottom: 16,
  position: 'absolute',

  '& .MuiOutlinedInput-root': {
    borderRadius: 32,
  },
}));

export const StyledDateMessage = styled(Typography)(() => ({
  fontSize: 10,
}));

export const StyledUserNameMessage = styled(Typography)(() => ({
  fontSize: 15,
  fontWeight: 500,
  color: '#0B2723',
}));

export const StyledMessage = styled(Typography)(() => ({
  fontSize: 12,
  color: '#8B8C8E',
}));

export const CloseButton = styled(IconButton)(() => ({
  position: 'absolute',
  right: 40,
  top: 38,
  height: 'fit-content',
  '& span': {
    fontSize: '32px',
    fontWeight: '600',
    color: '#00B39B',
    '&:hover': {
      color: '#004D43',
    },
  },
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

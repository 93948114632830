import { Box, Dialog, IconButton, Typography, styled } from '@mui/material';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 30,
    backgroundColor: theme.palette.common.white,
    padding: '48px',
    minHeight: 'auto',
    [theme.breakpoints.down('xl')]: {
      minWidth: 690,
      '& .css-biqaug': {
        margin: '48px 60px',
      },
    },
    [theme.breakpoints.up('xl')]: {
      minWidth: 790,
    },
  },
  '& .MuiDialog-paperScrollPaper': {
    '&::-webkit-scrollbar': {
      width: 10,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#004D43',
      borderRadius: 10,
      '&:hover': {
        backgroundColor: '#004D43CC',
      },
    },
    '&::-webkit-scrollbar-track': {
      margin: '60px 0',
    },
  },
}));

export const TopBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const CloseButton = styled(IconButton)(() => ({
  margin: '-28px -28px 0 0',
  height: 'fit-content',
  '& span': {
    fontSize: '24px',
    fontWeight: '600',
    color: '#00B39B',
    '&:hover': {
      color: '#004D43',
    },
  },
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

export const DialogTitle = styled(Typography)(() => ({
  font: '700 21px Montserrat',
  textTransform: 'capitalize',
  color: '#595959',
  marginBottom: 24,
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  '& span': { color: '#00B39B' },
}));

export const textFieldDefaultStyle = {
  width: '100%',
  '& .MuiInputBase-root': { borderRadius: '16px' },
  '& .MuiSvgIcon-root': {
    color: '#004D43',
  },
  '& .MuiFormLabel-root.Mui-error': {
    color: '#595959 !important',
  },
};

export const AssingData = styled(Box)(() => ({
  width: '100%',
  height: 46,
  border: '1px solid #00B39B',
  color: '#004E43',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 2,
}));

import {
  Button,
  CardMedia,
  Grid,
  IconButton,
  Select,
  styled,
  TextField,
  Typography,
} from '@mui/material';

export const BackButton = styled(IconButton)(() => ({
  margin: '-20px 0 -10px -30px',
  '& span': {
    fontSize: 40,
    color: '#00B39B',
  },
}));

export const GridSection = styled(Grid)(({ theme }) => ({
  background: '#FFFFFE',
  boxShadow: '0px 3px 6px #00000029',
  borderRadius: 4,
  padding: '32px',
  [theme.breakpoints.down('xl')]: {
    padding: '24px',
  },
}));

export const GridItem = styled(Grid)(({ theme }) => ({
  display: 'flex',
  gap: 8,
  marginTop: '28px',
  [theme.breakpoints.down('xl')]: {
    marginTop: '20px',
  },
}));

export const GridItemX = styled(Grid)(({ theme }) => ({
  display: 'flex',
  gap: 8,
  marginTop: '28px',
  padding: '0 12px',
  [theme.breakpoints.down('xl')]: {
    marginTop: '20px',
  },
}));

export const LicenseButton = styled(Button)(() => ({
  width: '100%',
  mt: '8px',
  lineHeight: 1.2,
}));

export const SectionTitle = styled(Typography)(() => ({
  fontSize: '21px',
  fontWeight: '600',
  color: '#707070',
}));

export const StyledInput = styled(Typography)(() => ({
  fontWeight: '500',
  fontSize: '16px',
  marginRight: 8,
}));

export const StyledTextField = styled(TextField)(() => ({
  width: '100%',
  '& .MuiInputBase-root': {
    borderRadius: '16px',
  },
}));

export const OutlinedButton = styled(Button)(() => ({
  width: '100%',
  border: '2px solid #00B39B',
  borderRadius: '30px',
  color: '#00B39B',
  fontWeight: '600',
  backgroundColor: '#FAFCFF',
  lineHeight: 1.2,
  gap: '8px',
  '&:hover': {
    backgroundColor: '#00B39B',
  },
  '&:active': {
    backgroundColor: '#00B39B',
  },
}));

export const StyledImage = styled(CardMedia)(({ theme }) => ({
  width: '100%',
  height: 320,
  [theme.breakpoints.up('xl')]: {
    height: 380,
  },
}));

export const AddButton = styled(IconButton)(() => ({
  color: '#00B39B',
  width: '50px',
  height: '50px',
  border: '1px solid #00B39B',
  borderRadius: '12px',
  '& span': {
    fontWeight: '600',
  },
  '&:hover': {
    backgroundColor: '#00B39B',
    color: 'white',
  },
  '&:disabled': {
    color: '#A9A9A9',
    border: '1px solid #A9A9A9',
  },
}));

export const textFiledDefaultStyle = {
  width: '100%',
  '& .MuiInputBase-root': { borderRadius: '16px' },
  '& .MuiSvgIcon-root': {
    color: '#004D43',
  },
  '& .MuiFormLabel-root.Mui-error': {
    color: '#595959 !important',
  },
};

export const StyledSelect = styled(Select)(() => ({
  borderRadius: '16px',
  '& .MuiSelect-select': {
    padding: '14px 24px 14px 14px',
  },
}));

export const PatternFormatStyle = {
  width: '100%',
  '& .MuiInputBase-root': { borderRadius: '16px' },
  '& .MuiSvgIcon-root': {
    color: '#004D43',
  },
  '& .MuiFormLabel-root.Mui-error': {
    color: '#595959 !important',
  },
};

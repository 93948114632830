/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/require-default-props */
import { TextField } from '@mui/material';
import { ChangeEvent } from 'react';

interface CustomTextFieldProps {
  id: string;
  label?: string;
  value: string;
  setValue?: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
  maxLength?: number;
  required?: boolean;
  error?: boolean;
  type?: string;
  onChange?: (value: boolean) => void;
}

export function CustomTextField({
  id,
  label,
  value,
  setValue,
  disabled,
  placeholder,
  maxLength,
  required,
  error,
  type,
  onChange,
}: CustomTextFieldProps): JSX.Element {
  const handleInvalid = (event: ChangeEvent<HTMLInputElement>): void => {
    if (required) {
      event.target.setCustomValidity('Preencha este campo');
    }
  };

  const handleInput = (event: ChangeEvent<HTMLInputElement>): void => {
    if (required) {
      event.target.setCustomValidity('');
    }
  };
  return (
    <TextField
      type={type}
      id={id}
      label={label}
      placeholder={placeholder}
      disabled={disabled}
      required={required}
      color="secondary"
      value={value}
      inputProps={{ maxLength }}
      error={error}
      onInvalid={handleInvalid}
      onInput={handleInput}
      onChange={(e) => {
        if (setValue) {
          setValue(e.target.value);
        }
        if (onChange) {
          onChange(true);
        }
      }}
      sx={{
        width: '100%',
        '& .MuiInputBase-root': { borderRadius: '16px' },
        'input:-webkit-autofill': {
          WebkitBoxShadow: '0 0 0px 1000px #F5F5F4 inset',
        },
      }}
    />
  );
}

import { BaseUrl, myHeadersWithTokenContentType } from '../../global';
import {
  AnnouncementDetailProps,
  CreateAnnouncementData,
  UpdateAnnouncementData,
} from './types';

export const createAnnouncement = (
  noticeId: number,
  data: CreateAnnouncementData
): Promise<AnnouncementDetailProps> => {
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };

  return fetch(
    `${BaseUrl}/api/V1/public-notices/${noticeId}/announcements`,
    requestOptions
  )
    .then((response) => response.json())
    .catch((error) => new Error(error));
};

export const listAnnouncements = (
  noticeId: number,
  page: number,
  size: number
): Promise<AnnouncementDetailProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };

  return fetch(
    `${BaseUrl}/api/V1/public-notices/${noticeId}/announcements?page=${page}&size=${size}`,
    requestOptions
  )
    .then((response) => response.json())
    .catch((error) => new Error(error));
};

export const deleteAnnouncement = (
  announcementId: number
): Promise<AnnouncementDetailProps> => {
  const requestOptions = {
    method: 'DELETE',
    headers: myHeadersWithTokenContentType(),
  };

  return fetch(
    `${BaseUrl}/api/V1/notice-announcements/${announcementId}`,
    requestOptions
  )
    .then((response) => response.json())
    .catch((error) => new Error(error));
};

export const updateAnnouncement = (
  announcementId: number,
  data: UpdateAnnouncementData
): Promise<AnnouncementDetailProps> => {
  const requestOptions = {
    method: 'PATCH',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };

  return fetch(
    `${BaseUrl}/api/V1/notice-announcements/${announcementId}`,
    requestOptions
  )
    .then((response) => response.json())
    .catch((error) => new Error(error));
};

/* eslint-disable react/require-default-props */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  ButtonBase,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { IconFilePresentMS } from '../../../../../constants/icons';
import { GlobalContext } from '../../../../../context/global';
import useErrorMessage from '../../../../../hooks/useErrorMessage';
import useGeneral from '../../../../../hooks/useGeneral';
import { PresentTableHead, PresentTableValues } from './presenter';
import {
  StyledCell,
  StyledPagination,
  StyledRow,
  TableCellHeader,
} from './styles';

interface ApiResponse<T> {
  detail: {
    status: string;
    status_code: number;
    description?: string;
    current_page?: number;
    total_pages?: number;
    total_records?: number;
  };
  data?: T[];
}

interface TableProps<T> {
  presentTableHead: () => PresentTableHead[];
  presentTableValues: (data: T, action?: () => void) => PresentTableValues[];
  dataCallback: (
    id: number,
    page: number,
    size: number
  ) => Promise<ApiResponse<T>>;
  companyId: number;
  type: string;
  resetTable?: boolean;
  action?: () => void;
  downloadIcon?: boolean;
}

export function TableInfo<
  T extends { id: number; file?: string; url?: string }
>({
  presentTableHead,
  presentTableValues,
  dataCallback,
  companyId,
  type,
  resetTable,
  action,
  downloadIcon = false,
}: TableProps<T>): JSX.Element {
  const [tableData, setTableData] = useState<T[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const dataPerPage = 6;

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const { getErrorMessage } = useErrorMessage();
  const { handleDownloadFile } = useGeneral();

  const getDataCallback = useCallback(async () => {
    try {
      const response = await dataCallback(companyId, page, dataPerPage);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.detail.total_pages) {
        setTotalPages(response.detail.total_pages);
      }

      setTableData(response.data);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  }, [page]);

  useEffect(() => {
    getDataCallback();
  }, [getDataCallback, resetTable]);

  const handleIcon = (data: T): React.ReactElement => {
    return (
      <ButtonBase
        sx={{ span: { color: '#00B39B' } }}
        onClick={() => {
          if (data.file && data.url) handleDownloadFile(data.url, data.file);
        }}
      >
        {IconFilePresentMS}
      </ButtonBase>
    );
  };

  return (
    <Box>
      {tableData.length > 0 ? (
        <>
          <TableContainer component={Paper} sx={{ marginTop: '30px' }}>
            <Table>
              <TableHead>
                <TableRow>
                  {presentTableHead().map((cell) => (
                    <TableCellHeader align="center" key={cell.id}>
                      {cell.label}
                    </TableCellHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((element) => (
                  <StyledRow key={element.id}>
                    {presentTableValues(element, action).map((cell) => (
                      <StyledCell key={cell.id} align="center">
                        {cell.value}
                      </StyledCell>
                    ))}
                    {downloadIcon && (
                      <StyledCell align="center">
                        {handleIcon(element)}
                      </StyledCell>
                    )}
                  </StyledRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <StyledPagination
            page={page}
            count={totalPages}
            onChange={(_, v) => setPage(v)}
          />
        </>
      ) : (
        <Box mt="20px">nenhum {type} cadastrado</Box>
      )}
    </Box>
  );
}

import Box from '@mui/material/Box';
import { useState } from 'react';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';

import logo from '../../assets/images/logo-realprice-menu.svg';
import {
  IconArchiveMS,
  IconArrowBackMS,
  IconArrowForwardMS,
  IconCalendarMonthMS,
  IconEngineeringMS,
  IconHomeMS,
  IconHubMS,
  IconListAltMS,
  IconLogoutMS,
  IconNotificationsMS,
  IconPersonMS,
  IconTuneMS,
  IconWarningMS,
} from '../../constants/icons';
import {
  active,
  ArrowBox,
  BackArrowBox,
  BoxContainer,
  Drawer,
  DrawerHeader,
  inactive,
  LogoutItem,
  MenuButton,
  MenuIcon,
  MenuList,
  MenuListItem,
  OutletContainer,
  StyledLogo,
  StyledTooltip,
} from './styles';

export default function Menu(): JSX.Element {
  const [open, setOpen] = useState(false);
  const [showTitle, setShowTitle] = useState(false);
  const isBank = process.env.REACT_APP_IS_BANK === 'true';

  const navigate = useNavigate();

  const menuList = isBank
    ? [
        { name: 'início', icon: IconHomeMS, url: '/home' },
        { name: 'the hive', icon: IconHubMS, url: '/the-hive' },
        {
          name: 'notificações',
          icon: IconNotificationsMS,
          url: '/notifications',
        },
        { name: 'formulários', icon: IconListAltMS, url: '/forms' },
        { name: 'usuários', icon: IconPersonMS, url: '/users' },
      ]
    : [
        { name: 'início', icon: IconHomeMS, url: '/home' },
        { name: 'arquivados', icon: IconArchiveMS, url: '/archived' },
        { name: 'ajustes', icon: IconTuneMS, url: '/adjustment' },
        { name: 'usuários', icon: IconPersonMS, url: '/users' },
        { name: 'formulários', icon: IconListAltMS, url: '/forms' },
        {
          name: 'gestão de usuários',
          icon: IconEngineeringMS,
          url: '/user-management',
        },
        {
          name: 'agenda',
          icon: IconCalendarMonthMS,
          url: '/agenda',
        },
        {
          name: 'erros reportados',
          icon: IconWarningMS,
          url: '/error-report',
        },
      ];

  const handleDrawerOpen = (): void => {
    setOpen(!open);
    if (!open) {
      setTimeout(() => {
        setShowTitle(true);
      }, 150);
    } else {
      setShowTitle(false);
    }
  };

  const handleLogout = (): void => {
    localStorage.removeItem('control-center-token');
    navigate('/login');
  };

  return (
    <>
      <BoxContainer>
        <Drawer variant="permanent" open={open}>
          <Box>
            <DrawerHeader>
              <StyledLogo image={logo} title="logo" />
            </DrawerHeader>
            <MenuList>
              <Box>
                {menuList.map((data) => (
                  <MenuListItem key={data.name} disablePadding>
                    <NavLink
                      to={data.url}
                      style={({ isActive }) => (isActive ? active : inactive)}
                    >
                      {!open ? (
                        <StyledTooltip title={data.name} placement="bottom">
                          <MenuButton open={open} disableTouchRipple>
                            <MenuIcon open={open}>{data.icon}</MenuIcon>
                          </MenuButton>
                        </StyledTooltip>
                      ) : (
                        <MenuButton open={open} disableTouchRipple>
                          <MenuIcon open={open}>
                            {data.icon}
                            {showTitle && data.name}
                          </MenuIcon>
                        </MenuButton>
                      )}
                    </NavLink>
                  </MenuListItem>
                ))}
              </Box>
              <LogoutItem disablePadding>
                {!open ? (
                  <StyledTooltip title="sair" placement="bottom">
                    <MenuButton
                      open={open}
                      disableTouchRipple
                      onClick={handleLogout}
                    >
                      <MenuIcon open={open}>
                        {IconLogoutMS}
                        {open && 'sair'}
                      </MenuIcon>
                    </MenuButton>
                  </StyledTooltip>
                ) : (
                  <MenuButton
                    open={open}
                    disableTouchRipple
                    onClick={handleLogout}
                  >
                    <MenuIcon open={open}>
                      {IconLogoutMS}
                      {open && 'sair'}
                    </MenuIcon>
                  </MenuButton>
                )}
              </LogoutItem>
            </MenuList>
          </Box>
          <BackArrowBox open={open} />
          <ArrowBox open={open} onClick={handleDrawerOpen}>
            {open ? IconArrowBackMS : IconArrowForwardMS}
          </ArrowBox>
        </Drawer>
      </BoxContainer>
      <OutletContainer>
        <Outlet />
      </OutletContainer>
    </>
  );
}

import { convertStatus, convertStatusUrl, Status } from '../api/enumerations';
import {
  GetAllWorkOrderDataProps,
  GetTopWorkOrdersData,
  WorkOrderData,
} from '../api/workOrders/types';

export const Constants = {
  title: 'Plataforma de Controle',
  bankTitle: 'Painel de Acompanhamento de OS',
  buttonAdd: 'adicionar nova OS',
  TVMode: 'Modo TV',
  DesktopMode: 'Modo desktop',
  os: 'número OS:',
  client: 'cliente:',
  clientType: 'tipo do cliente:',
  address: 'localização:',
  report: 'tipo de laudo:',
  goal: 'objetivo:',
  function: 'finalidade:',
  inspector: 'vistoriador:',
  schedule: 'agendamento:',
  limitFinish: 'limite para entrega:',
  reason: 'motivo:',
  company: 'empresa:',
};

export interface ColumnValues {
  name: string;
  workOrders: WorkOrderData[] | undefined;
  link: string;
  status: Status;
}

export const presentColumnData = (
  pipelineData: GetAllWorkOrderDataProps | GetTopWorkOrdersData | undefined
): ColumnValues[] => {
  return [
    {
      name: convertStatus(Status.ENTRANCE),
      workOrders:
        pipelineData?.[Status.ENTRANCE] &&
        'items' in pipelineData[Status.ENTRANCE]
          ? pipelineData?.[Status.ENTRANCE]?.items
          : (pipelineData?.[Status.ENTRANCE] as WorkOrderData[]),
      link: convertStatusUrl(Status.ENTRANCE),
      status: Status.ENTRANCE,
    },
    {
      name: convertStatus(Status.PEPT),
      workOrders:
        pipelineData?.[Status.PEPT] && 'items' in pipelineData[Status.PEPT]
          ? pipelineData?.[Status.PEPT]?.items
          : (pipelineData?.[Status.PEPT] as WorkOrderData[]),
      link: convertStatusUrl(Status.PEPT),
      status: Status.PEPT,
    },
    {
      name: convertStatus(Status.SCHEDULE),
      workOrders:
        pipelineData?.[Status.SCHEDULE] &&
        'items' in pipelineData[Status.SCHEDULE]
          ? pipelineData?.[Status.SCHEDULE]?.items
          : (pipelineData?.[Status.SCHEDULE] as WorkOrderData[]),
      link: convertStatusUrl(Status.SCHEDULE),
      status: Status.SCHEDULE,
    },
    {
      name: convertStatus(Status.INSPECTION),
      workOrders:
        pipelineData?.[Status.INSPECTION] &&
        'items' in pipelineData[Status.INSPECTION]
          ? pipelineData?.[Status.INSPECTION]?.items
          : (pipelineData?.[Status.INSPECTION] as WorkOrderData[]),
      link: convertStatusUrl(Status.INSPECTION),
      status: Status.INSPECTION,
    },
    {
      name: convertStatus(Status.SAMPLE_CREATION),
      workOrders:
        pipelineData?.[Status.SAMPLE_CREATION] &&
        'items' in pipelineData[Status.SAMPLE_CREATION]
          ? pipelineData?.[Status.SAMPLE_CREATION]?.items
          : (pipelineData?.[Status.SAMPLE_CREATION] as WorkOrderData[]),
      link: convertStatusUrl(Status.SAMPLE_CREATION),
      status: Status.SAMPLE_CREATION,
    },
    {
      name: convertStatus(Status.CALCULATION),
      workOrders:
        pipelineData?.[Status.CALCULATION] &&
        'items' in pipelineData[Status.CALCULATION]
          ? pipelineData?.[Status.CALCULATION]?.items
          : (pipelineData?.[Status.CALCULATION] as WorkOrderData[]),
      link: convertStatusUrl(Status.CALCULATION),
      status: Status.CALCULATION,
    },
    {
      name: convertStatus(Status.REPORT),
      workOrders:
        pipelineData?.[Status.REPORT] && 'items' in pipelineData[Status.REPORT]
          ? pipelineData?.[Status.REPORT]?.items
          : (pipelineData?.[Status.REPORT] as WorkOrderData[]),
      link: convertStatusUrl(Status.REPORT),
      status: Status.REPORT,
    },
    {
      name: convertStatus(Status.REVISION),
      workOrders:
        pipelineData?.[Status.REVISION] &&
        'items' in pipelineData[Status.REVISION]
          ? pipelineData?.[Status.REVISION]?.items
          : (pipelineData?.[Status.REVISION] as WorkOrderData[]),
      link: convertStatusUrl(Status.REVISION),
      status: Status.REVISION,
    },
  ];
};

export const Constants = {
  accept: 'aceitar',
  acceptWithIssues: 'aceitar com pendência',
  acceptWorkOrder: 'aceitar o atendimento desta ordem de serviço',
  approve: 'aprovar',
  cancelOs: 'cancelar OS',
  cancel: 'cancelar',
  cancelOsText: 'cancelar esta OS',
  changeStatus:
    'gravar os dados atuais e alterar o status da OS ** de Entrada para Agendamento',
  confirmEdit: 'confirmar alterações',
  dataEntrance: 'Entrada de Dados',
  dataEdition: 'Edição de Dados',
  downloadText:
    'Você pode realizar o download dos seguintes documentos anexados:',
  download: 'download',
  iptu: 'Cartela do IPTU',
  lat: 'Latitude: ',
  lng: 'Longitude:',
  messages: 'mensagens',
  typeMessage: 'Digite uma mensagem',
  pept: 'pendência técnica',
  propertyAddress: 'Localização do imóvel',
  propertyData: 'Dados do imóvel',
  propertyDetails: 'Características do imóvel',
  propertyRegister: 'Matrícula do imóvel',
  reason: 'Escreva aqui o motivo',
  reportData: 'Dados do relatório',
  reject: 'rejeitar',
  searchMap: 'pesquisar no mapa',
  send: 'enviar',
  submit: 'criar nova OS',
  successfullyAccepted: 'A ordem de serviço foi aceita com sucesso!',
  successfullyRejectedWithIssues:
    'A ordem de serviço foi aceita com pendências',
  successfullyRejected: 'A ordem de serviço foi recusada com sucesso!',
  uploadText: 'Faça o upload ou download dos seguintes documentos:',
  upload: 'upload',
};

/* eslint-disable react/require-default-props */
import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useEffect, useState } from 'react';

import 'dayjs/locale/pt-br';
import { IconMessageMS, IconCloseMS } from '../../constants/icons';
import useGeneral from '../../hooks/useGeneral';
import { Constants } from './Constants';
import { CustomTextField } from './CustomTextField';
import { IMessage, messagesHistoryData } from './mockData';
import {
  ButtonContainer,
  CloseIcon,
  DialogContainer,
  MessageBox,
  MessagesContainer,
  MessageText,
  OpenButton,
  SenderTitle,
  StyledDate,
  StyledDialog,
} from './styles';

interface MessagesDialogProps {
  osId?: number;
}

export function MessagesDialog({ osId }: MessagesDialogProps): JSX.Element {
  const [message, setMessage] = useState('');
  const [messagesData, setMessagesData] = useState<IMessage[]>();

  useEffect(() => {
    if (osId) {
      setMessagesData(messagesHistoryData);
    }
  }, [osId]);

  const dialog = useGeneral();

  const formatDateAndTime = (date: string): string => {
    dayjs.extend(utc);
    dayjs.extend(tz);
    dayjs.locale('pt-br');

    const dateTime = dayjs(date).tz('America/Sao_Paulo');
    const formattedDateTime = dateTime.format('DD [de] MMMM [às] HH:mm');

    return formattedDateTime;
  };

  return (
    <>
      <ButtonContainer>
        <OpenButton disableTouchRipple onClick={dialog.handleOpen}>
          {IconMessageMS}
          {Constants.messages}
        </OpenButton>
      </ButtonContainer>
      <StyledDialog open={dialog.open} onClose={dialog.handleClose}>
        <DialogContainer>
          <CloseIcon onClick={dialog.handleClose}>{IconCloseMS}</CloseIcon>
          <MessagesContainer>
            {messagesData?.map((message) => (
              <MessageBox key={message.id}>
                <StyledDate>{formatDateAndTime(message.date)}</StyledDate>
                <SenderTitle>{message.sender}</SenderTitle>
                <MessageText>{message.message_text}</MessageText>
              </MessageBox>
            ))}
          </MessagesContainer>
          <CustomTextField
            id="message"
            placeholder={Constants.typeMessage}
            value={message}
            setValue={setMessage}
          />
        </DialogContainer>
      </StyledDialog>
    </>
  );
}

export interface CitiesAccordionData {
  cityId?: number;
  selectedCity: string;
  displacementValue: string;
  microregions: Array<{
    id: number;
    value: string;
    city_id?: number | null;
  }>;
}

export interface CitiesAccordionProps {
  onDataChange: (data: CitiesAccordionData) => void;
  expanded?: boolean;
  onExpandedChange?: (isExpanded: boolean) => void;
}

export interface IDefaulCanceltModalProps {
  isModalOpen: boolean;
  onCloseModal: () => void;
  title: string;
}

export interface CityLocation {
  id: number;
  city_id: number;
  city: string;
  uf: string;
}

export interface UFOption {
  id: number;
  uf: string;
}

export const UF_OPTIONS: UFOption[] = [
  { id: 1, uf: 'AC' },
  { id: 2, uf: 'AL' },
  { id: 3, uf: 'AP' },
  { id: 4, uf: 'AM' },
  { id: 5, uf: 'BA' },
  { id: 6, uf: 'CE' },
  { id: 7, uf: 'DF' },
  { id: 8, uf: 'ES' },
  { id: 9, uf: 'GO' },
  { id: 10, uf: 'MA' },
  { id: 11, uf: 'MT' },
  { id: 12, uf: 'MS' },
  { id: 13, uf: 'MG' },
  { id: 14, uf: 'PA' },
  { id: 15, uf: 'PB' },
  { id: 16, uf: 'PR' },
  { id: 17, uf: 'PE' },
  { id: 18, uf: 'PI' },
  { id: 19, uf: 'RJ' },
  { id: 20, uf: 'RN' },
  { id: 21, uf: 'RS' },
  { id: 22, uf: 'RO' },
  { id: 23, uf: 'RR' },
  { id: 24, uf: 'SC' },
  { id: 25, uf: 'SP' },
  { id: 26, uf: 'SE' },
  { id: 27, uf: 'TO' },
];

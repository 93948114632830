/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable react/no-array-index-key */
import { AccordionDetails, Grid, IconButton, Skeleton } from '@mui/material';
import { useState, useRef, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {
  IconExpandMoreMS,
  IconDeleteMS,
} from '../../../../../../constants/icons';
import { useCoverageOperations } from '../ModalAddingCoverage/useCoverageOperations';
import {
  CoverageContainer,
  CoverageTitle,
  NoContentText,
  CityText,
  StyledAccordionSummary,
  StyledAccordion,
  AccordionTitle,
  DisplacementText,
  DisplacementValue,
  ContentContainer,
  ScrollableGrid,
} from './styles';
import { CoverageAreaProps, CoverageData } from './types';

function transformData(rawData: any): CoverageData {
  if (!rawData) return { mainRegions: [] };
  return {
    mainRegions: rawData.main_regions.map((region: any) => ({
      cityId: region.city_id,
      city: region.city.name,
      id: region.id,
      displacementFee: region.displacement_fee,
      subregions:
        region.subregions?.map((sub: any) => ({
          cityId: sub.city_id,
          subregion: sub.city.name,
        })) || [],
    })),
  };
}

export function CoverageAreaGrid({
  data,
  fetchCoverage,
}: CoverageAreaProps): JSX.Element {
  const [expanded, setExpanded] = useState<number | false>(false);
  const [deletingId, setDeletingId] = useState<number | null>(null);
  const [localData, setLocalData] = useState<CoverageData>(() =>
    transformData(data)
  );
  const scrollableGridRef = useRef<HTMLDivElement>(null);
  const { notice } = useParams();
  const noticeId = parseInt(notice as string, 10);

  const { deleteNoticeCoverage } = useCoverageOperations({
    noticeId,
    onSuccess: () => {
      fetchCoverage();
    },
  });

  useEffect(() => {
    setLocalData(transformData(data));
  }, [data]);

  const handleAccordionChange =
    (panel: number) => (_: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);

      if (
        isExpanded &&
        localData?.mainRegions &&
        panel === localData.mainRegions.length - 1
      ) {
        setTimeout(() => {
          scrollableGridRef.current?.scrollTo({
            top: scrollableGridRef.current.scrollHeight,
            behavior: 'smooth',
          });
        }, 300);
      }
    };

  const handleDelete = useCallback(
    async (id: number, event: React.MouseEvent): Promise<void> => {
      event.stopPropagation();
      setDeletingId(id);

      try {
        const success = await deleteNoticeCoverage(id);
        if (success) {
          setLocalData((prevData) => ({
            ...prevData,
            mainRegions: prevData.mainRegions.filter(
              (region) => region.id !== id
            ),
          }));
        }
      } finally {
        setDeletingId(null);
      }
    },
    [deleteNoticeCoverage]
  );

  if (!localData?.mainRegions || localData.mainRegions.length === 0) {
    return (
      <CoverageContainer item container xs={12} mt={2}>
        <Grid item container>
          <CoverageTitle>Área de cobertura</CoverageTitle>
        </Grid>
        <ScrollableGrid item container mt={2}>
          <NoContentText>Nenhuma área de cobertura adicionada</NoContentText>
        </ScrollableGrid>
      </CoverageContainer>
    );
  }

  return (
    <CoverageContainer item container xs={12} mt={2}>
      <Grid item container>
        <CoverageTitle>Área de cobertura</CoverageTitle>
      </Grid>
      <ScrollableGrid item container mt={2} ref={scrollableGridRef}>
        {localData.mainRegions.map((region, index) => {
          const isDeleting = deletingId === region.id;

          if (isDeleting) {
            return (
              <Skeleton
                key={`skeleton-${region.id}`}
                style={{
                  backgroundColor: '#fafcff',
                  boxShadow: '0px 3px 6px #00000029',
                }}
                variant="rectangular"
                width="98%"
                height={45}
                sx={{ mb: 2, borderRadius: 1 }}
              />
            );
          }

          return (
            <StyledAccordion
              key={`${region.cityId}-${index}`}
              expanded={expanded === index}
              onChange={handleAccordionChange(index)}
            >
              <StyledAccordionSummary
                aria-controls={`coverage-content-${index}`}
                id={`coverage-header-${index}`}
                sx={{
                  '& .MuiAccordionSummary-content': {
                    margin: 0,
                    flex: 1,
                  },
                }}
              >
                <Grid
                  container
                  alignItems="center"
                  sx={{
                    width: '100%',
                    position: 'relative',
                  }}
                >
                  <Grid
                    item
                    container
                    alignItems="center"
                    xs
                    sx={{
                      '& .expandIcon': {
                        color: '#00B39B',
                        marginLeft: '12px',
                      },
                    }}
                  >
                    <AccordionTitle>{region.city}</AccordionTitle>
                    <span className="expandIcon">{IconExpandMoreMS}</span>
                  </Grid>
                  <Grid item>
                    <IconButton
                      onClick={(e) => handleDelete(region.id, e)}
                      disabled={isDeleting}
                      sx={{
                        color: '#707070',
                        marginRight: '32px',
                        '&:hover': {
                          color: '#00B39B',
                        },
                      }}
                    >
                      {IconDeleteMS}
                    </IconButton>
                  </Grid>
                </Grid>
              </StyledAccordionSummary>
              <AccordionDetails>
                <ContentContainer>
                  <DisplacementText>
                    Valor do deslocamento:{' '}
                    <DisplacementValue>
                      R$ {region.displacementFee.toFixed(2)}
                    </DisplacementValue>
                  </DisplacementText>
                  <Grid item container direction="column">
                    {region.subregions.map((subregion, subIndex) => (
                      <Grid
                        item
                        key={`${subregion.cityId}-${subIndex}`}
                        mt={0.6}
                      >
                        <CityText>{subregion.subregion}</CityText>
                      </Grid>
                    ))}
                  </Grid>
                </ContentContainer>
              </AccordionDetails>
            </StyledAccordion>
          );
        })}
      </ScrollableGrid>
    </CoverageContainer>
  );
}

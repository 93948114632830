import { KeyboardEvent, useEffect, useRef, useState } from 'react';

import { IconCloseMS } from '../../../constants/icons';
import {
  CloseButton,
  Messages,
  MessagesContainer,
  StyledContainer,
  StyledDateMessage,
  StyledHeaderModal,
  StyledLineHeader,
  StyledMessage,
  StyledModal,
  StyledTextField,
  StyledUserNameMessage,
} from './styles';

interface NotificationsModalProps {
  open: boolean;
  onClose: () => void;
}

interface MessageProps {
  date: string;
  hour: string;
  name: string;
  message: string;
}

export function NotificationsModal({
  open,
  onClose,
}: NotificationsModalProps): JSX.Element {
  const [messages, setMessages] = useState<MessageProps[]>([
    { date: '10 jan', hour: '16:40', name: 'Nome', message: 'mensagem...' },
    { date: '10 jan', hour: '16:40', name: 'Nome', message: 'mensagem...' },
    { date: '10 jan', hour: '16:40', name: 'Nome', message: 'mensagem...' },
    { date: '10 jan', hour: '16:40', name: 'Nome', message: 'mensagem...' },
  ]);

  const [newMessage, setNewMessage] = useState('');
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const sendMessage = (): void => {
    if (newMessage.trim()) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { date: '10 jan', hour: '16:40', name: 'Nome', message: newMessage },
      ]);
      setNewMessage('');
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      e.preventDefault();
      sendMessage();
    }
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return (
    <StyledModal open={open} onClose={onClose}>
      <StyledContainer>
        <CloseButton onClick={onClose} disableTouchRipple>
          {IconCloseMS}
        </CloseButton>
        <StyledHeaderModal>
          <StyledLineHeader>
            <strong>número OS: 01234567</strong>
          </StyledLineHeader>
          <StyledLineHeader>
            <span>
              data da entrada <strong>01/08/2023</strong>
            </span>
            <span>
              cliente <strong>Gama Company</strong>
            </span>
            <span>
              tipo <strong>bancos</strong>
            </span>
          </StyledLineHeader>
          <StyledLineHeader>
            <span>
              objetivo <strong>aquisição</strong>
            </span>
            <span>
              finalidade <strong>valor de mercado de compra e venda</strong>
            </span>
          </StyledLineHeader>
        </StyledHeaderModal>
        <MessagesContainer>
          <Messages>
            {messages.map((message) => (
              <div style={{ marginBottom: 8 }}>
                <StyledDateMessage>
                  {message.date} {message.hour}
                </StyledDateMessage>
                <StyledUserNameMessage>{message.name}</StyledUserNameMessage>
                <StyledMessage>{message.message}</StyledMessage>
              </div>
            ))}
            <div ref={messagesEndRef} />
          </Messages>
          <StyledTextField
            placeholder="Escreva aqui uma mensagem"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </MessagesContainer>
      </StyledContainer>
    </StyledModal>
  );
}

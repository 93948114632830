import { TableHead, Table, TableBody } from '@mui/material';
import { useState } from 'react';

import { convertProfileUsers } from '../../../api/enumerations';
import { UserData } from '../../../api/users/types';
import { Constants } from '../../../constants/users';
import EditUser from '../EditUserDialog';
import { Cell, StyledTableCell, Row, StyledTableContainer } from './styles';

interface TableProps {
  tableData: UserData[];
  userRole: number;
}

export default function TableUser({
  tableData,
  userRole,
}: TableProps): JSX.Element {
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserData>();

  const handleClickOpen = (user: UserData): void => {
    setOpen(true);
    setSelectedUser(user);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <StyledTableContainer>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <Row>
            <Cell align="center">{Constants.status}</Cell>
            <Cell align="center">{Constants.name}</Cell>
            <Cell align="center">{Constants.role}</Cell>
            <Cell align="center">{Constants.email}</Cell>
          </Row>
        </TableHead>
        <TableBody>
          {tableData?.map((user) => (
            <Row key={user.id} onClick={() => handleClickOpen(user)}>
              <StyledTableCell
                align="center"
                sx={{ color: user.is_active ? '#00B39B' : '#8B8C8E' }}
              >
                {user.is_active ? 'ativo' : 'inativo'}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{ color: user.is_active ? '#595959' : '#8b8c8e99' }}
              >
                {user.name}
              </StyledTableCell>
              <StyledTableCell
                align="center"
                sx={{ color: user.is_active ? '#595959' : '#8b8c8e99' }}
              >
                {convertProfileUsers(user.role)}
              </StyledTableCell>
              <StyledTableCell
                align="center"
                sx={{ color: user.is_active ? '#595959' : '#8b8c8e99' }}
              >
                {user.email}
              </StyledTableCell>
            </Row>
          ))}
        </TableBody>
      </Table>
      {open && selectedUser && (
        <EditUser
          open={open}
          userData={selectedUser}
          onClose={handleClose}
          userRole={userRole}
        />
      )}
    </StyledTableContainer>
  );
}

/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/no-array-index-key */
import { Grid, IconButton, MenuItem, TextField, Grow } from '@mui/material';
import MUIModal from '@mui/material/Modal';
import { useState, useCallback, useMemo, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { IconDeleteMS } from '../../../../../../constants/icons';
import { GlobalContext } from '../../../../../../context/global';
import {
  propertyOptions,
  propertyTypeMapping,
  rangeMapping,
  rangeOptions,
} from './constants';
import {
  AddNewTypologyButton,
  CancelButton,
  SaveTypologyButton,
  ScrollableGrid,
  StyledbBoxContainer,
  StyledSelect,
  StyledTextField,
  StyledTextFieldGroup,
  TitleMoldal,
} from './styles';
import { IDefaulCanceltModalProps, PropertyType } from './types';
import { useTypologyOperations } from './typologyOperations';

export function ModalAddTypology({
  isModalOpen,
  onCloseModal,
  title,
  fetchTypologyGroups,
  typologyGroups,
}: IDefaulCanceltModalProps): JSX.Element {
  const [displacementValue, setDisplacementValue] = useState('');
  const [groupName, setGroupName] = useState<string>('');
  const [propertyTypes, setPropertyTypes] = useState<PropertyType[]>([
    { type: '', range: '', area: '', visible: true },
  ]);
  const { notice } = useParams();
  const noticeId = parseInt(notice as string, 10);
  const [isSaving, setIsSaving] = useState(false);
  const { setOpenSnackbar, setSnackbarMessage } = useContext(GlobalContext);
  const { createNewTypologyGroup } = useTypologyOperations({
    noticeId,
    onSuccess: () => {
      fetchTypologyGroups();
      onCloseModal?.();
    },
  });

  useEffect(() => {
    if (isModalOpen) {
      setSnackbarMessage(
        'Todos os campos devem ser preenchidos para criar uma tipologia'
      );
      setOpenSnackbar(true);
    }
  }, [isModalOpen, setSnackbarMessage, setOpenSnackbar]);

  const isFormValid = useMemo(() => {
    if (!groupName.trim() || !displacementValue) return false;

    return propertyTypes.some((property) => {
      const baseFieldsValid = property.type && property.range && property.area;

      if (property.range === 'entre') {
        return baseFieldsValid && !!property.area2;
      }

      return baseFieldsValid;
    });
  }, [groupName, displacementValue, propertyTypes]);

  const handleDelete = (index: number): void => {
    const newTypes = [...propertyTypes];
    newTypes[index].visible = false;
    setPropertyTypes(newTypes);
    setTimeout(() => {
      setPropertyTypes((prev) => prev.filter((_, i) => i !== index));
    }, 300);
  };

  const handleAddNewRow = (): void => {
    setPropertyTypes([
      ...propertyTypes,
      { type: '', range: '', area: '', visible: true },
    ]);
  };

  const formatCurrency = useCallback((value: string): string => {
    const numericValue = value.replace(/\D/g, '');
    const amount = Number(numericValue) / 100;
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(amount);
  }, []);

  const handleDisplacementChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const rawValue = event.target.value.replace(/\D/g, '');
      const formattedValue = formatCurrency(rawValue);
      setDisplacementValue(formattedValue);
    },
    [formatCurrency]
  );

  const handleNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const newValue = event.target.value;

    setGroupName(newValue);
  };

  const formatPriceToNumber = (price: string): number => {
    return Number(price.replace(/[^\d]/g, '')) / 100;
  };

  const handleSave = async (): Promise<void> => {
    if (!isFormValid || !noticeId) {
      return;
    }
    setIsSaving(true);
    const typologies = propertyTypes
      .filter((property) => property.visible)
      .map((property) => ({
        real_estate_type: propertyTypeMapping[property.type.toLowerCase()],
        rule_keyword: rangeMapping[property.range],
        rule_first_value: Number(property.area),
        ...(property.range === 'entre' && {
          rule_second_value: Number(property.area2),
        }),
      }));
    setTimeout(() => {
      setIsSaving(false);
    }, 1000);
    const groupData = {
      name: groupName,
      price: formatPriceToNumber(displacementValue),
      typologies,
    };

    await createNewTypologyGroup(groupData);
  };

  return (
    <MUIModal open={isModalOpen} onClose={onCloseModal}>
      <StyledbBoxContainer>
        <Grid item container justifyContent="center" xs={3.4}>
          <TitleMoldal>{title}</TitleMoldal>
        </Grid>
        <Grid item container mt={2} mb={2} justifyContent="center" xs={8.7}>
          <Grid>
            <StyledTextFieldGroup
              label="Nome"
              placeholder="Escreva um nome para o grupo*"
              variant="outlined"
              value={groupName}
              onChange={handleNameChange}
              inputProps={{
                maxLength: 50,
              }}
            />
          </Grid>
          <Grid ml={2.3}>
            <TextField
              label="Valor"
              variant="outlined"
              value={displacementValue}
              onChange={handleDisplacementChange}
              inputProps={{
                inputMode: 'numeric',
              }}
              sx={{
                width: '302px',
                '& .MuiOutlinedInput-root': {
                  height: '48px',
                  borderRadius: '16px',
                  display: 'flex',
                  alignItems: 'center',
                  padding: '0 14px',
                  '& fieldset': {
                    borderColor: 'rgba(0, 0, 0, 0.23)',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(0, 0, 0, 0.23)',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#00B39B',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: '#707070',
                  '&.Mui-focused': {
                    color: '#00B39B',
                  },
                  top: '-4px',
                },
              }}
            />
          </Grid>
        </Grid>
        <ScrollableGrid item container alignContent="start">
          {propertyTypes.map((property, index) => (
            <Grow
              key={index}
              in={property.visible}
              timeout={300}
              unmountOnExit
              style={{ transformOrigin: '0 0 0' }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  marginTop: '8px',
                }}
              >
                <Grid container spacing={2} alignItems="center" sx={{ px: 2 }}>
                  <Grid item>
                    <StyledSelect
                      value={property.type}
                      sx={{ width: '260px' }}
                      onChange={(e) => {
                        const newTypes = [...propertyTypes];
                        newTypes[index].type = e.target.value as string;
                        setPropertyTypes(newTypes);
                      }}
                      displayEmpty
                      variant="outlined"
                    >
                      <MenuItem value="" disabled>
                        Selecione uma opção
                      </MenuItem>
                      {propertyOptions.map((option) => (
                        <MenuItem key={option} value={option.toLowerCase()}>
                          {option}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                  </Grid>

                  <Grid item>
                    <StyledSelect
                      value={property.range}
                      sx={{ width: '200px' }}
                      onChange={(e) => {
                        const newTypes = [...propertyTypes];
                        newTypes[index].range = e.target.value as string;
                        if (e.target.value !== 'entre') {
                          delete newTypes[index].area2;
                        } else {
                          newTypes[index].area2 = '';
                        }
                        setPropertyTypes(newTypes);
                      }}
                      displayEmpty
                      variant="outlined"
                    >
                      <MenuItem value="" disabled>
                        Selecione uma opção
                      </MenuItem>
                      {rangeOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                  </Grid>

                  <Grid item>
                    <StyledTextField
                      label="(m²)"
                      variant="outlined"
                      value={property.area}
                      onChange={(e) => {
                        const newValue = e.target.value.replace(/\D/g, '');
                        const newTypes = [...propertyTypes];
                        newTypes[index].area = newValue;
                        setPropertyTypes(newTypes);
                      }}
                      inputProps={{
                        inputMode: 'numeric',
                      }}
                    />
                  </Grid>

                  {property.range === 'entre' && (
                    <>
                      <Grid item>
                        <span>e</span>
                      </Grid>
                      <Grid item>
                        <StyledTextField
                          label="(m²)"
                          variant="outlined"
                          value={property.area2 || ''}
                          onChange={(e) => {
                            const newValue = e.target.value.replace(/\D/g, '');
                            const newTypes = [...propertyTypes];
                            newTypes[index].area2 = newValue;
                            setPropertyTypes(newTypes);
                          }}
                          inputProps={{
                            inputMode: 'numeric',
                          }}
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item>
                    <IconButton
                      onClick={() => handleDelete(index)}
                      sx={{
                        color: '#707070',
                        '&:hover': {
                          color: '#00B39B',
                        },
                      }}
                    >
                      {IconDeleteMS}
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grow>
          ))}
        </ScrollableGrid>
        <Grid item container mt={2} justifyContent="end" xs={11.5}>
          <AddNewTypologyButton onClick={handleAddNewRow}>
            nova tipologia
          </AddNewTypologyButton>
        </Grid>
        <Grid item container justifyContent="center" mt={2}>
          <Grid item mr={2}>
            <CancelButton onClick={onCloseModal}>Cancelar</CancelButton>
          </Grid>
          <Grid item ml={2}>
            <SaveTypologyButton
              onClick={handleSave}
              disabled={!isFormValid || isSaving}
              sx={{
                opacity: !isFormValid ? 0.5 : 1,
                cursor: !isFormValid ? 'not-allowed' : 'pointer',
              }}
            >
              Salvar
            </SaveTypologyButton>
          </Grid>
        </Grid>
      </StyledbBoxContainer>
    </MUIModal>
  );
}

import { Box, CircularProgress } from '@mui/material';

export function LoadingSpinner(): JSX.Element {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="60vh"
    >
      <CircularProgress sx={{ color: '#004D43' }} size={60} />
    </Box>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  LinearProgress,
  List,
  MenuItem,
} from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';

import { RegistrationUf } from '../../../../../api/enumerations';
import { getCities } from '../../../../../api/theHive/cities';
import { CitiesData } from '../../../../../api/theHive/cities/types';
import { listLicensedCompanies } from '../../../../../api/theHive/licensedCompanies';
import { LicensedCompanyData } from '../../../../../api/theHive/licensedCompanies/types';
import { IconSearchMS } from '../../../../../constants/icons';
import { selectRegistrationUf } from '../../../../../constants/selectOptions';
import { GlobalContext } from '../../../../../context/global';
import useErrorMessage from '../../../../../hooks/useErrorMessage';
import { SelectedCompany } from './SelectedCompany';
import {
  ListCompanies,
  ListItemStyled,
  SearchTextField,
  StyledInputBase,
} from './styles';

export function Location(): JSX.Element {
  const [loading, setLoading] = useState(true);
  const [selectedCompany, setSelectedCompany] = useState<
    LicensedCompanyData | undefined
  >();
  const [uf, setUf] = useState(0);
  const [loadingCities, setLoadingCities] = useState(false);
  const [cities, setCities] = useState<CitiesData[]>([]);
  const [selectedCity, setSelectedCity] = useState('');
  const [searchCompany, setSearchCompany] = useState('');
  const [licensedCompanies, setLicensedCompanies] = useState<
    LicensedCompanyData[]
  >([]);

  const { getErrorMessage } = useErrorMessage();

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const getDataCallback = useCallback(async () => {
    try {
      const response = await listLicensedCompanies(1, 100, searchCompany);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setLicensedCompanies(response.data);
      if (response.data.length > 0) setSelectedCompany(response.data[0]);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  }, [searchCompany]);

  useEffect(() => {
    if (searchCompany.length > 0) {
      const delayDebounceFn = setTimeout(() => {
        getDataCallback();
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
    getDataCallback();
    return undefined;
  }, [searchCompany]);

  const handleUfSearch = useCallback(async () => {
    setLoadingCities(true);
    try {
      const response = await getCities(uf, 1, 100);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setCities(response.data);
      if (response.data.length > 0) setSelectedCity(response.data[0].name);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    } finally {
      setLoadingCities(false);
    }
  }, [uf]);

  useEffect(() => {
    setCities([]);
    if (uf !== RegistrationUf.SELECT) {
      handleUfSearch();
    }
  }, [uf, handleUfSearch]);

  return (
    <Grid container spacing={2} mt={2}>
      {loading ? (
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      ) : (
        <>
          <Grid item xs={5}>
            <ListCompanies>
              <StyledInputBase
                placeholder="buscar por documento"
                inputProps={{ 'aria-label': 'pesquisar', maxLength: 18 }}
                type="search"
                startAdornment={
                  <InputAdornment position="start" sx={{ color: '#004D43' }}>
                    {IconSearchMS}
                  </InputAdornment>
                }
                onChange={(e) => {
                  if (e.target.value.length > 0) {
                    setSearchCompany(e.target.value);
                  }
                  if (e.target.value.length === 0) {
                    setSearchCompany('');
                  }
                }}
                onFocus={(e) => {
                  e.target.value = '';
                  setSearchCompany('');
                }}
              />
              <Box display="flex" alignItems="center" gap={4}>
                <SearchTextField
                  id="uf"
                  label="UF"
                  value={uf}
                  select
                  color="secondary"
                  onChange={(e) => {
                    setUf(Number(e.target.value));
                  }}
                >
                  {selectRegistrationUf().map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </SearchTextField>
                {cities.length > 0 && (
                  <SearchTextField
                    id="city"
                    label="cidade"
                    value={selectedCity}
                    select
                    color="secondary"
                    onChange={(e) => {
                      setSelectedCity(e.target.value);
                    }}
                    sx={{ width: '100%' }}
                  >
                    {cities.map((option) => (
                      <MenuItem key={option.id} value={option.name}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </SearchTextField>
                )}
                {loadingCities && <CircularProgress size={22} />}
              </Box>
              <List>
                {licensedCompanies.length > 0
                  ? licensedCompanies.map((data) => (
                      <ListItemStyled
                        key={data.id}
                        className={
                          data.company.name === selectedCompany?.company.name
                            ? 'active'
                            : ''
                        }
                        onClick={() => setSelectedCompany(data)}
                      >
                        <img
                          src={data.company.logo_url}
                          alt={data.company.name}
                        />
                        {data.company.name}
                      </ListItemStyled>
                    ))
                  : 'nenhuma empresa encontrada'}
              </List>
            </ListCompanies>
          </Grid>
          {selectedCompany && (
            <Grid item xs={7}>
              <SelectedCompany selectedCompany={selectedCompany} />
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}

import {
  BaseUrl,
  myHeadersWithToken,
  myHeadersWithTokenContentType,
} from '../../global';
import {
  DetailResponse,
  EditLicensedCompanyProps,
  GetAllAttachedFilesResponse,
  GetCompanyAbsencesResponse,
  GetCompanyNoticesResponse,
  GetCompanyResponse,
  GetLicensedCompanyResponse,
  GetObservationsResponse,
  LicenseCompanyProps,
  LicensedCompanyResponse,
  ListCompaniesResponse,
  ObservationProps,
} from './types';

export const getCompany = (doc: string): Promise<GetCompanyResponse> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithToken(),
  };

  return fetch(`${BaseUrl}/api/V1/companies/document/${doc}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const licenseCompany = (
  data: LicenseCompanyProps
): Promise<LicensedCompanyResponse> => {
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };

  return fetch(`${BaseUrl}/api/V1/licensed-companies`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const listLicensedCompanies = (
  page: number,
  size: number,
  search: string,
  isActive?: boolean
): Promise<ListCompaniesResponse> => {
  const url = new URL(
    `${BaseUrl}/api/V1/licensed-companies?page=${page}&size=${size}&search=${search}`
  );
  if (isActive !== undefined)
    url.searchParams.append('is_active', isActive.toString());

  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };

  return fetch(url, requestOptions)
    .then((response) => response.json())
    .catch((error) => new Error(error));
};

export const getLicensedCompany = (
  id: number
): Promise<GetLicensedCompanyResponse> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };

  return fetch(`${BaseUrl}/api/V1/licensed-companies/${id}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => new Error(error));
};

export const editLicensedCompany = (
  id: number,
  data: EditLicensedCompanyProps
): Promise<DetailResponse> => {
  const requestOptions = {
    method: 'PATCH',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };

  return fetch(`${BaseUrl}/api/V1/licensed-companies/${id}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => new Error(error));
};

export const addAttachment = (
  company: number,
  data: FormData
): Promise<DetailResponse> => {
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithToken(),
    body: data,
  };
  return fetch(
    `${BaseUrl}/api/V1/licensed-companies/${company}/attachments`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const deleteAttachment = (att: number): Promise<DetailResponse> => {
  const requestOptions = {
    method: 'DELETE',
    headers: myHeadersWithToken(),
  };
  return fetch(
    `${BaseUrl}/api/V1/licensed-company-attachments/${att}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const getAllAttachedFiles = (
  company: number,
  page: number,
  size: number
): Promise<GetAllAttachedFilesResponse> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  return fetch(
    `${BaseUrl}/api/V1/licensed-companies/${company}/attachments?page=${page}&size=${size}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const addObservation = (
  company: number,
  data: ObservationProps
): Promise<DetailResponse> => {
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };

  return fetch(
    `${BaseUrl}/api/V1/licensed-companies/${company}/observations`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const getObservations = (
  company: number,
  page: number,
  size: number
): Promise<GetObservationsResponse> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  return fetch(
    `${BaseUrl}/api/V1/licensed-companies/${company}/observations?page=${page}&size=${size}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const getCompanyNotices = (
  company: number,
  page: number,
  size: number
): Promise<GetCompanyNoticesResponse> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };

  return fetch(
    `${BaseUrl}/api/V1/licensed-companies/${company}/public-notices?page=${page}&size=${size}`,
    requestOptions
  )
    .then((response) => response.json())
    .catch((error) => new Error(error));
};

export const getCompanyAbsences = (
  company: number,
  page: number,
  size: number
): Promise<GetCompanyAbsencesResponse> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };

  return fetch(
    `${BaseUrl}/api/V1/licensed-companies/${company}/absences?page=${page}&size=${size}`,
    requestOptions
  )
    .then((response) => response.json())
    .catch((error) => new Error(error));
};

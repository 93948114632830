import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box } from '@mui/material';
import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import Snackbar from '../../components/Snackbar';
import { TitleBox } from '../../components/UI/Box';
import { GridMain } from '../../components/UI/Grid';
import { TitleTypography } from '../../components/UI/Typography';
import { IconHubMS } from '../../constants/icons';
import { GlobalContext } from '../../context/global';
import { BoxContainer, TabStyled } from './styles';

interface ITabValues {
  value: 'requests' | 'licensed' | 'notices';
}

export function Hive(): JSX.Element {
  const [value, setValue] = useState<ITabValues['value']>();

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { openSnackbar } = useContext(GlobalContext);

  useEffect(() => {
    if (pathname.includes('licensed')) {
      setValue('licensed');
    } else if (pathname.includes('notices')) {
      setValue('notices');
    } else {
      setValue('requests');
    }
  }, [pathname]);

  const handleChangeTab = (
    event: SyntheticEvent,
    newValue: ITabValues['value']
  ): void => {
    setValue(newValue);
    navigate(`./${newValue}`);
  };

  return (
    <GridMain>
      <TitleBox>
        <TitleTypography>{IconHubMS} The Hive</TitleTypography>
      </TitleBox>
      <BoxContainer>
        {value && (
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: '#707070' }}>
              <TabList onChange={handleChangeTab}>
                <TabStyled label="Pedidos" value="requests" />
                <TabStyled label="Credenciados" value="licensed" />
                <TabStyled label="Editais" value="notices" />
              </TabList>
            </Box>
            <TabPanel value={value}>
              <Outlet />
            </TabPanel>
          </TabContext>
        )}
      </BoxContainer>
      {openSnackbar && <Snackbar />}
    </GridMain>
  );
}

import { BaseUrl, myHeadersWithTokenContentType } from '../../global';
import {
  CityLocationResponse,
  CoverageDetail,
  NoticeCoverageData,
  SingleCoverageData,
} from './types';

export const createNoticeCoverage = (
  id: number,
  data: NoticeCoverageData
): Promise<CoverageDetail> => {
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };

  return fetch(
    `${BaseUrl}/api/V1/public-notices/${id}/notice-coverages`,
    requestOptions
  )
    .then((response) => response.json())
    .catch((error) => new Error(error));
};

export const getNoticeCoverage = (id: number): Promise<CoverageDetail> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };

  return fetch(
    `${BaseUrl}/api/V1/public-notices/${id}/notice-coverages`,
    requestOptions
  )
    .then((response) => response.json())
    .catch((error) => new Error(error));
};

export const createSingleCoverage = (
  id: number,
  data: SingleCoverageData
): Promise<CoverageDetail> => {
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };

  return fetch(
    `${BaseUrl}/api/V1/public-notices/${id}/notice-coverages/single`,
    requestOptions
  )
    .then((response) => response.json())
    .catch((error) => new Error(error));
};

export const deleteCoverage = (id: number): Promise<CoverageDetail> => {
  const requestOptions = {
    method: 'DELETE',
    headers: myHeadersWithTokenContentType(),
  };

  return fetch(`${BaseUrl}/api/V1/notice-coverages/${id}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => new Error(error));
};

export const getCityCoverage = async (
  page: number,
  limit: number,
  uf: number
): Promise<CityLocationResponse> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };

  return fetch(
    `${BaseUrl}/api/V1/cities?uf=${uf}&page=${page}&limit=${limit}`,
    requestOptions
  )
    .then((response) => response.json())
    .catch((error) => new Error(error));
};

import { Box, styled } from '@mui/material';

const ScrollStyle = {
  '&::-webkit-scrollbar': {
    width: '10px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#B9BABB',
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: '#B9BABBCC',
    },
  },
  '&::-webkit-scrollbar-track': {
    border: '1px solid #D7D8DB',
    borderRadius: '20px',
  },
};

export const CommentBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '28px',
  marginTop: '16px',
}));

export const Comment = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
}));

export const StyledName = styled(Box)(() => ({
  fontWeight: '600',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}));

export const ObservationBox = styled(Box)(() => ({
  ...ScrollStyle,
  marginTop: '20px',
  minHeight: '120px',
  maxHeight: '220px',
  overflowY: 'auto',
}));

import { BaseUrl, myHeadersWithTokenContentType } from '../../global';
import {
  GetHiveWorkOrdersResponse,
  GetWorkOrderEligibilityResponse,
} from './types';

export const getHiveWorkOrders = (
  search: string,
  page: number,
  size: number
): Promise<GetHiveWorkOrdersResponse> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  return fetch(
    `${BaseUrl}/api/V1/work-orders/hive?search=${search}&page=${page}&size=${size}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const getWorkOrderEligibility = (
  id: number
): Promise<GetWorkOrderEligibilityResponse> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  return fetch(
    `${BaseUrl}/api/V1/work-orders/${id}/hive-eligibility`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

import { Grid } from '@mui/material';
import { useContext, useState } from 'react';

import { StatusCode } from '../../../api/enumerations';
import { editWorkOrder } from '../../../api/workOrders';
import { AvmInferenceData } from '../../../api/workOrders/types';
import { StyledSelect } from '../../../components/CustomInput';
import {
  Constants,
  optionsLiquidity,
  optionsNumberOffers,
  optionsPerformance,
} from '../../../constants/report';
import { GlobalContext } from '../../../context/global';
import useErrorMessage from '../../../hooks/useErrorMessage';
import {
  FlexCenterGrid,
  GridContainer,
  ReportTitle,
  ShortAnswerTextField,
  StyledButton,
} from './styles';

export interface InferenceDescriptionProps {
  tableData: AvmInferenceData;
  osId: number;
}

export function InferenceDescription({
  tableData,
  osId,
}: InferenceDescriptionProps): JSX.Element {
  const { descriptions } = tableData;
  const [performance, setPerformance] = useState(
    descriptions?.dropdown?.performance.chosen || ''
  );
  const [offersNumber, setOffersNumber] = useState(
    descriptions?.dropdown?.number_of_offers.chosen || ''
  );
  const [liquidity, setLiquidity] = useState(
    descriptions?.dropdown?.liquidity.chosen || ''
  );
  const [internal, setInternal] = useState(descriptions.internal_description);
  const [preliminaries, setPreliminaries] = useState(
    descriptions.preliminaries
  );
  const [marketSearch, setMarketSearch] = useState(
    descriptions.market_research
  );
  const [marketDiagnosis, setMarketDiagnosis] = useState(
    descriptions.market_diagnosis
  );
  const [observations, setObservations] = useState(descriptions.observations);
  const [registry, setRegistry] = useState(descriptions.property_registry);
  const [assumptions, setAssumptions] = useState(
    descriptions.assumptions_limiting_factors
  );

  const isBank = process.env.REACT_APP_IS_BANK === 'true';

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const { getErrorMessage } = useErrorMessage();

  const updateDescriptions = async (): Promise<void> => {
    const formData = new FormData();
    if (descriptions && descriptions.dropdown) {
      descriptions.dropdown.performance.chosen = performance;
      descriptions.dropdown.number_of_offers.chosen = offersNumber;
      descriptions.dropdown.liquidity.chosen = liquidity;
      descriptions.internal_description = internal;
      descriptions.preliminaries = preliminaries;
      descriptions.market_research = marketSearch;
      descriptions.market_diagnosis = marketDiagnosis;
      descriptions.observations = observations;
      descriptions.property_registry = registry;
      descriptions.assumptions_limiting_factors = assumptions;

      formData.append('avm_report', JSON.stringify(tableData));
    } else {
      // eslint-disable-next-line no-param-reassign
      tableData.descriptions = {
        assumptions_limiting_factors: assumptions,
        dropdown: {
          performance: {
            options: optionsPerformance,
            chosen: performance,
          },
          number_of_offers: {
            options: optionsNumberOffers,
            chosen: offersNumber,
          },
          liquidity: {
            options: optionsLiquidity,
            chosen: liquidity,
          },
        },
        internal_description: internal,
        market_diagnosis: marketDiagnosis,
        market_research: marketSearch,
        observations,
        preliminaries,
        property_registry: registry,
      };
      formData.append('avm_report', JSON.stringify(tableData));
    }

    try {
      const response = await editWorkOrder(osId, formData);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Dados alterados com sucesso!');
      setErrorMessage(false);
      setOpenSnackbar(true);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  return (
    <GridContainer container columnSpacing={2}>
      <Grid item xs={12}>
        <ReportTitle>{Constants.sumaryDiagnosis}</ReportTitle>
      </Grid>
      <Grid item xs={4}>
        <StyledSelect
          disabled={isBank}
          id="performance"
          label={Constants.performance}
          value={performance}
          setValue={setPerformance}
          selectOptions={
            descriptions?.dropdown?.performance.options || optionsPerformance
          }
        />
      </Grid>
      <Grid item xs={4}>
        <StyledSelect
          disabled={isBank}
          id="offers-number"
          label={Constants.offersNum}
          value={offersNumber}
          setValue={setOffersNumber}
          selectOptions={
            descriptions?.dropdown?.number_of_offers.options ||
            optionsNumberOffers
          }
        />
      </Grid>
      <Grid item xs={4}>
        <StyledSelect
          disabled={isBank}
          id="liquidity"
          label={Constants.liquidity}
          value={liquidity}
          setValue={setLiquidity}
          selectOptions={
            descriptions?.dropdown?.liquidity.options || optionsLiquidity
          }
        />
      </Grid>
      <Grid item xs={12}>
        <ReportTitle>
          {Constants.internal}
          <span style={{ textTransform: 'none' }}>{Constants.register}</span>
        </ReportTitle>
        <ShortAnswerTextField
          disabled={isBank}
          id="internal"
          multiline
          rows={3}
          placeholder={Constants.writeHere}
          color="secondary"
          value={internal}
          onChange={(e) => setInternal(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <ReportTitle>{Constants.preliminaries}</ReportTitle>
        <ShortAnswerTextField
          disabled={isBank}
          id="preliminaries"
          multiline
          rows={6}
          placeholder={Constants.writeHere}
          color="secondary"
          value={preliminaries}
          onChange={(e) => setPreliminaries(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <ReportTitle>{Constants.marketSearch}</ReportTitle>
        <ShortAnswerTextField
          disabled={isBank}
          id="marketsearch"
          multiline
          rows={6}
          placeholder={Constants.writeHere}
          color="secondary"
          value={marketSearch}
          onChange={(e) => setMarketSearch(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <ReportTitle>{Constants.diagnosis}</ReportTitle>
        <ShortAnswerTextField
          disabled={isBank}
          id="diagnosis"
          multiline
          rows={6}
          placeholder={Constants.writeHere}
          color="secondary"
          value={marketDiagnosis}
          onChange={(e) => setMarketDiagnosis(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <ReportTitle>{Constants.observations}</ReportTitle>
        <ShortAnswerTextField
          disabled={isBank}
          id="observations"
          multiline
          rows={3}
          placeholder={Constants.writeHere}
          color="secondary"
          value={observations}
          onChange={(e) => setObservations(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <ReportTitle>{Constants.docs}</ReportTitle>
        <ShortAnswerTextField
          disabled={isBank}
          id="docs"
          multiline
          rows={3}
          placeholder={Constants.writeHere}
          color="secondary"
          value={registry}
          onChange={(e) => setRegistry(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <ReportTitle>{Constants.assumptions}</ReportTitle>
        <ShortAnswerTextField
          disabled={isBank}
          id="assumptions"
          multiline
          rows={12}
          placeholder={Constants.writeHere}
          color="secondary"
          value={assumptions}
          onChange={(e) => setAssumptions(e.target.value)}
        />
      </Grid>
      {!isBank && (
        <FlexCenterGrid item xs={12}>
          <StyledButton onClick={updateDescriptions}>
            {Constants.save}
          </StyledButton>
        </FlexCenterGrid>
      )}
    </GridContainer>
  );
}

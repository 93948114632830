/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Box, Button, CircularProgress, InputAdornment } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { listPublicNotices } from '../../../../api/theHive/notices';
import { NoticeData } from '../../../../api/theHive/notices/types';
import { LoadingBox } from '../../../../components/UI/Box';
import { IconSearchMS } from '../../../../constants/icons';
import { GlobalContext } from '../../../../context/global';
import useErrorMessage from '../../../../hooks/useErrorMessage';
import { GridContainer, SearchBox, StyledInputBase } from './styles';
import { NoticesTable } from './Table';

export function Notices(): JSX.Element {
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [noticesData, setNoticesData] = useState<NoticeData[]>([]);

  const noticesPerPage = 6;

  const navigate = useNavigate();
  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const { getErrorMessage } = useErrorMessage();

  const handleNavigateNewNotice = (): void => {
    navigate(`./new`);
  };

  const getDataCallback = useCallback(async () => {
    setLoading(true);

    try {
      const noticesResponse = await listPublicNotices(
        page,
        noticesPerPage,
        searchTerm
      );

      if (noticesResponse.detail.description) {
        throw new Error(noticesResponse.detail.description);
      }

      if (!noticesResponse.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (noticesResponse.detail.total_pages) {
        setTotalPages(noticesResponse.detail.total_pages);
      }

      setNoticesData(noticesResponse.data);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  }, [page, searchTerm]);

  useEffect(() => {
    if (searchTerm.length > 0) {
      setPage(1);
    } else if (page !== 1) {
      setPage(1);
    }
  }, [searchTerm]);

  useEffect(() => {
    getDataCallback();
  }, [getDataCallback]);

  return (
    <GridContainer>
      <SearchBox>
        <Button onClick={handleNavigateNewNotice}>inserir novo edital</Button>
        <Box display="flex" alignItems="center">
          <StyledInputBase
            placeholder="buscar nesta página"
            inputProps={{ 'aria-label': 'pesquisar', maxLength: 14 }}
            type="search"
            startAdornment={
              <InputAdornment position="start" sx={{ color: '#004D43' }}>
                {IconSearchMS}
              </InputAdornment>
            }
            onChange={(e) => {
              if (e.target.value.length > 0) {
                setSearchTerm(e.target.value);
              }
              if (e.target.value.length === 0) {
                setSearchTerm('');
              }
            }}
            onFocus={(e) => {
              e.target.value = '';
              setSearchTerm('');
            }}
          />
        </Box>
      </SearchBox>
      {loading ? (
        <LoadingBox>
          <CircularProgress />
        </LoadingBox>
      ) : noticesData.length > 0 ? (
        <NoticesTable
          tableData={noticesData}
          page={page}
          setPage={setPage}
          totalPages={totalPages}
        />
      ) : (
        'Não existem editais criados no momento'
      )}
    </GridContainer>
  );
}

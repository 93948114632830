/* eslint-disable no-constant-condition */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Autocomplete, Grid, SelectChangeEvent } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import React, { useState, useCallback, useEffect } from 'react';

import { getCityCoverage } from '../../../../../../api/theHive/noticeCoverage';
import {
  IconAddMS,
  IconExpandMoreMS,
  IconDeleteMS,
} from '../../../../../../constants/icons';
import {
  AddButton,
  CustomAccordion,
  DeleteMicroregionButton,
  StyledSelectUf,
} from '../ModalAddingCoverage/styles';
import { CitiesAccordionProps, CityLocation, UF_OPTIONS } from './types';

const commonInputStyles = {
  '& .MuiOutlinedInput-root': {
    height: 48,
    color: '#707070',
    '& fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#00B39B',
    },
  },
};

export function CitiesAccordion({
  onDataChange,
  expanded = false,
  onExpandedChange,
}: CitiesAccordionProps): JSX.Element {
  const [selectedUF, setSelectedUF] = useState('');
  const [selectedCity, setSelectedCity] = useState<CityLocation | null>(null);
  const [displacementValue, setDisplacementValue] = useState('');
  const [microregions, setMicroregions] = useState<
    Array<{
      id: number;
      value: CityLocation | null;
      index: number;
    }>
  >([{ id: 1, value: null, index: 0 }]);
  const [cities, setCities] = useState<CityLocation[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const updateParentData = useCallback(() => {
    if (selectedCity) {
      onDataChange?.({
        selectedCity: selectedCity.city,
        cityId: selectedCity.city_id,
        displacementValue,
        microregions: microregions
          .filter((region) => region.value)
          .map((region) => ({
            id: region.value?.id || 0,
            value: region.value?.city || '',
          })),
      });
    }
  }, [selectedCity, displacementValue, microregions]);

  const fetchCitiesForUF = useCallback(async (ufId: number) => {
    setLoading(true);
    setError(null);
    const limit = 10;
    let allCities: any[] = [];
    let currentPage = 1;

    try {
      // Fazer a primeira requisição para iniciar o carregamento e obter a primeira página
      const firstResponse = await getCityCoverage(currentPage, limit, ufId);
      const firstPageCities = firstResponse.data.map((city: any) => ({
        id: city.id,
        city_id: city.id,
        city: city.name,
        uf: city.uf,
      }));
      allCities = [...allCities, ...firstPageCities];

      const pagePromises: Promise<any>[] = [];
      if (firstPageCities.length === limit) {
        currentPage++;
        // Monta um array de Promises para cada página, começando da segunda
        while (true) {
          const pagePromise = getCityCoverage(currentPage, limit, ufId);
          pagePromises.push(pagePromise);
          currentPage++;

          // Supondo que temos um limite de 50 páginas por exemplo para evitar loop infinito
          if (pagePromises.length >= 65) break;
        }

        // Executa todas as promessas de uma vez e aguarda os resultados
        const responses = await Promise.all(pagePromises);

        // Processa os dados de todas as páginas carregadas
        for (const response of responses) {
          const cities = response.data.map((city: any) => ({
            id: city.id,
            city_id: city.id,
            city: city.name,
            uf: city.uf,
          }));
          allCities = [...allCities, ...cities];
          // Interrompe se uma página tem menos resultados que o limite, indicando que é a última
          if (cities.length < limit) break;
        }
      }

      setCities(allCities);
    } catch (err) {
      setError('Erro ao carregar as cidades');
      setCities([]);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    updateParentData();
  }, [updateParentData]);

  const handleUFChange = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      event.stopPropagation();
      const ufId = event.target.value as string;
      setSelectedUF(ufId);
      setSelectedCity(null);
      setMicroregions([{ id: 1, value: null, index: 0 }]);
      if (ufId) {
        fetchCitiesForUF(Number(ufId));
      }
    },
    [fetchCitiesForUF]
  );

  const formatCurrency = useCallback((value: string): string => {
    const numericValue = value.replace(/\D/g, '');
    const amount = Number(numericValue) / 100;
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(amount);
  }, []);

  const handleAccordionChange = (
    event: React.SyntheticEvent,
    isExpanded: boolean
  ) => {
    const target = event.target as HTMLElement;
    if (
      !target.closest('input') &&
      !target.closest('button') &&
      !target.closest('.MuiAutocomplete-root') &&
      !target.closest('.MuiSelect-root')
    ) {
      onExpandedChange?.(isExpanded);
    }
  };

  const handleDisplacementChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.stopPropagation();
      const rawValue = event.target.value.replace(/\D/g, '');
      const formattedValue = formatCurrency(rawValue);
      setDisplacementValue(formattedValue);
    },
    [formatCurrency]
  );

  const handleMicroregionChange = useCallback(
    (id: number, value: CityLocation | null) => {
      setMicroregions((prevMicroregions) =>
        prevMicroregions.map((region) =>
          region.id === id ? { ...region, value } : region
        )
      );
    },
    []
  );

  const addMicroregion = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setMicroregions((prevMicroregions) => [
      ...prevMicroregions,
      {
        id: Date.now(),
        value: null,
        index: prevMicroregions.length,
      },
    ]);
  }, []);

  const removeMicroregion = useCallback(
    (id: number, event: React.MouseEvent) => {
      event.stopPropagation();
      setMicroregions((prevMicroregions) =>
        prevMicroregions.filter((region) => region.id !== id)
      );
    },
    []
  );

  const handleCityChange = (
    event: React.SyntheticEvent,
    newValue: CityLocation | null
  ) => {
    event.stopPropagation();
    setSelectedCity(newValue);
  };

  const isMicroregionSelectDisabled = !selectedUF || !selectedCity;

  const autocompleteStyles = {
    width: '252px',
    textAlign: 'start' as const,
    fontSize: '14px',
    backgroundColor: 'transparent',
    ...commonInputStyles,
    '& .MuiOutlinedInput-root': {
      ...commonInputStyles['& .MuiOutlinedInput-root'],
      borderRadius: '16px',
    },
    '& .MuiOutlinedInput-input': {
      fontSize: '14px',
      backgroundColor: 'transparent',
      '&::placeholder': {
        color: '#4A4A4A',
        opacity: 1,
      },
    },
    '& .MuiInputLabel-root': {
      fontSize: '14px',
      color: '#4A4A4A',
    },
    '& .MuiAutocomplete-endAdornment': {
      right: '8px',
    },
    '& .MuiAutocomplete-popper .MuiPaper-root': {
      backgroundColor: '#ffffff',
    },
    '& .MuiAutocomplete-option': {
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'rgba(0, 179, 155, 0.08)',
      },
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      '&[aria-selected="true"]:hover': {
        backgroundColor: 'rgba(0, 179, 155, 0.08)',
      },
    },
    '& .MuiInputBase-input::placeholder': {
      fontSize: '14px',
      color: '#4A4A4A',
      opacity: 1,
    },
  };

  return (
    <CustomAccordion expanded={expanded} onChange={handleAccordionChange}>
      <AccordionSummary
        expandIcon={IconExpandMoreMS}
        aria-controls="panel-content"
        id="panel-header"
      >
        <Grid mt={1} container spacing={1.3}>
          <Grid item xs={4}>
            <StyledSelectUf
              value={selectedUF}
              onChange={handleUFChange}
              onClick={(e) => e.stopPropagation()}
              displayEmpty
              sx={commonInputStyles}
            >
              <MenuItem value="" disabled>
                UF*
              </MenuItem>
              {UF_OPTIONS.map((uf) => (
                <MenuItem key={uf.id} value={uf.id.toString()}>
                  {uf.uf}
                </MenuItem>
              ))}
            </StyledSelectUf>
          </Grid>
          <Grid item xs={5}>
            <Autocomplete
              value={selectedCity}
              onChange={handleCityChange}
              isOptionEqualToValue={(option, value) => option.id === value?.id}
              options={cities}
              getOptionLabel={(option) => option.city}
              loading={loading}
              disabled={!selectedUF}
              onClick={(e) => e.stopPropagation()}
              onMouseDown={(e) => e.stopPropagation()}
              disablePortal={false}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Novo Polo*"
                  error={!!error}
                  helperText={error}
                  onClick={(e) => e.stopPropagation()}
                  onMouseDown={(e) => e.stopPropagation()}
                />
              )}
              componentsProps={{
                popper: {
                  onClick: (e) => e.stopPropagation(),
                  style: { width: '220px', height: '300px' },
                },
                paper: {
                  onClick: (e) => e.stopPropagation(),
                },
              }}
              sx={autocompleteStyles}
              ListboxProps={{
                style: { backgroundColor: 'white' },
              }}
            />
          </Grid>
        </Grid>
      </AccordionSummary>

      <AccordionDetails
        style={{
          width: '414px',
        }}
      >
        <Grid container spacing={2} mt={0.2}>
          <Grid item container alignItems="center" spacing={1.9}>
            <Grid
              item
              style={{
                marginLeft: '22px',
              }}
            >
              <TextField
                label="Valor do Deslocamento"
                variant="outlined"
                value={displacementValue}
                onChange={handleDisplacementChange}
                disabled={!selectedCity}
                InputLabelProps={{
                  style: {
                    fontSize: '14px',
                  },
                }}
                inputProps={{
                  inputMode: 'numeric',
                  style: {
                    fontSize: '14px',
                  },
                }}
                sx={{
                  width: '252px',
                  ...commonInputStyles,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '16px',
                    marginTop: '3px',
                    ...commonInputStyles['& .MuiOutlinedInput-root'],
                  },
                  '& .MuiInputBase-input': {
                    fontSize: '14px',
                  },
                }}
              />
            </Grid>
            <Grid item ml={2}>
              <AddButton
                onClick={addMicroregion}
                disabled={isMicroregionSelectDisabled}
              >
                {IconAddMS}
              </AddButton>
            </Grid>
          </Grid>
          {microregions.map((region) => (
            <Grow in key={region.id} timeout={300}>
              <Grid item container xs={12} spacing={1} alignItems="center">
                <Grid
                  item
                  xs={9.4}
                  style={{
                    paddingLeft: '30px',
                  }}
                >
                  <Autocomplete
                    componentsProps={{
                      popper: {
                        onClick: (e) => e.stopPropagation(),
                        style: { width: '220px', height: '300px' },
                      },
                      paper: {
                        onClick: (e) => e.stopPropagation(),
                      },
                    }}
                    disablePortal={false}
                    value={region.value}
                    onChange={(_, newValue) =>
                      handleMicroregionChange(region.id, newValue)
                    }
                    options={cities}
                    getOptionLabel={(option) => option.city}
                    loading={loading}
                    disabled={isMicroregionSelectDisabled}
                    onClick={(e) => e.stopPropagation()}
                    onMouseDown={(e) => e.stopPropagation()}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Microregião"
                        error={!!error}
                        helperText={error}
                        onClick={(e) => e.stopPropagation()}
                        onMouseDown={(e) => e.stopPropagation()}
                      />
                    )}
                    sx={autocompleteStyles}
                    ListboxProps={{
                      style: { backgroundColor: 'white' },
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <DeleteMicroregionButton
                    onClick={(e) => removeMicroregion(region.id, e)}
                    disabled={isMicroregionSelectDisabled}
                  >
                    {IconDeleteMS}
                  </DeleteMicroregionButton>
                </Grid>
              </Grid>
            </Grow>
          ))}
        </Grid>
      </AccordionDetails>
    </CustomAccordion>
  );
}

/* eslint-disable react/require-default-props */
import { DatePicker } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';

import { IconCalendarMonthMS } from '../../../../../constants/icons';
import { CustomDatePickerContainer } from './styles';

interface CustomDatePickerProps {
  label?: string;
  value: Dayjs | null;
  onChange: (value: Dayjs | null) => void;
  placeholder?: string;
}

export function CustomDatePicker({
  label,
  value,
  onChange,
  placeholder,
}: CustomDatePickerProps): JSX.Element {
  return (
    <CustomDatePickerContainer>
      <DatePicker
        label={label}
        value={value}
        onChange={onChange}
        format="DD/MM/YYYY"
        components={{
          OpenPickerIcon: () => IconCalendarMonthMS,
        }}
        slotProps={{
          textField: {
            placeholder,
            sx: { width: '226px' },
          },
          openPickerButton: {
            edge: 'end',
          },
        }}
      />
    </CustomDatePickerContainer>
  );
}

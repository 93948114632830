export interface IMessage {
  id: number;
  date: string;
  sender: string;
  message_text: string;
}

export const messagesHistoryData = (): IMessage[] => [
  {
    id: 1,
    date: '2024-10-31T10:00:00',
    sender: 'Banco',
    message_text: 'observação feita pelo banco',
  },
  {
    id: 2,
    date: '2024-10-31T10:01:00',
    sender: 'Banco',
    message_text: 'observação feita pelo banco',
  },
  {
    id: 3,
    date: '2024-10-31T10:02:00',
    sender: 'Banco',
    message_text: 'observação feita pelo banco',
  },
  {
    id: 4,
    date: '2024-10-31T10:02:00',
    sender: 'Banco',
    message_text: 'observação feita pelo banco',
  },
  {
    id: 5,
    date: '2024-10-31T10:02:00',
    sender: 'Banco',
    message_text: 'observação feita pelo banco',
  },
  {
    id: 6,
    date: '2024-10-31T10:02:00',
    sender: 'Banco',
    message_text: 'observação feita pelo banco',
  },
];

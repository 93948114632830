/* eslint-disable react-hooks/exhaustive-deps */
import { Box, InputAdornment, LinearProgress, Pagination } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';

import { convertProfileUsers } from '../../api/enumerations';
import { getAllUsers } from '../../api/users';
import { UserData } from '../../api/users/types';
import Snackbar from '../../components/Snackbar';
import { TitleBox } from '../../components/UI/Box';
import { GridMain } from '../../components/UI/Grid';
import { TitleTypography } from '../../components/UI/Typography';
import { IconPersonMS, IconSearchMS } from '../../constants/icons';
import { Constants } from '../../constants/users';
import { GlobalContext } from '../../context/global';
import { useStoragedJwt } from '../../hooks/useDecodedJwt';
import useErrorMessage from '../../hooks/useErrorMessage';
import NewUserDialog from './NewUserDialog';
import {
  TableContainer,
  SearchButton,
  SearchBox,
  StyledInputBase,
  PagesContainer,
  LoadingBox,
} from './styles';
import Table from './Table';

export function Users(): JSX.Element {
  const [users, setUsers] = useState<UserData[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<UserData[]>([]);
  const [userRole, setUserRole] = useState(0);

  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);

  const usersPerPage = 10;

  const { openSnackbar, setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const { getErrorMessage } = useErrorMessage();
  const decoded = useStoragedJwt();

  const getDataCallback = useCallback(async () => {
    try {
      const response = await getAllUsers(page, usersPerPage);
      if (response.detail.description) {
        throw new Error(response.detail.description);
      }
      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }
      if (response.detail.total_pages) {
        setTotalPages(response.detail.total_pages);
      }
      setUsers(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  }, [loading]);

  const updateUsers = (): void => {
    setPage(1);
    setLoading(true);
    getDataCallback();
  };

  useEffect(() => {
    updateUsers();
  }, []);

  useEffect(() => {
    if (loading) {
      getDataCallback();
    }
  }, [loading]);

  const handleNextPage = (pageNumber: number): void => {
    setPage(pageNumber);
    setLoading(true);
  };

  useEffect(() => {
    if (decoded !== null) {
      const location = window.location.href;
      const subdomainFilter =
        location.includes('realprice-st') || location.includes('localhost')
          ? 'realprice-st'
          : 'realprice';
      const filteredCompanies = decoded.user.companies.filter(
        (e) => e.subdomain === subdomainFilter
      );
      if (filteredCompanies) {
        setUserRole(filteredCompanies[0].role);
      }
    }
  }, []);

  const handleSearch = (): void => {
    if (searchTerm.length > 0) {
      const modifiedUsers = users.map((user) => ({
        ...user,
        role: convertProfileUsers(user.role),
      }));

      const filteredResults = modifiedUsers.filter((user) =>
        Object.values(user).some((property) => {
          if (property) {
            return property
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase());
          }
          return false;
        })
      );

      const result = users.filter((user) =>
        filteredResults.some(
          (filteredUser) => user.email === filteredUser.email
        )
      );

      setSearchResults(result);
    } else {
      setSearchResults([]);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);

    if (newSearchTerm === '') {
      setSearchResults([]);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSearch();
    }
  };

  return (
    <GridMain>
      <TitleBox>
        <TitleTypography>
          {IconPersonMS}
          {Constants.panel}
        </TitleTypography>
        <NewUserDialog updateUsers={updateUsers} />
      </TitleBox>
      <Box sx={{ display: 'flex' }}>
        <TableContainer>
          <SearchBox>
            <StyledInputBase
              placeholder={Constants.search}
              inputProps={{ 'aria-label': 'pesquisar', maxLength: 20 }}
              startAdornment={
                <InputAdornment position="start" sx={{ color: '#004D43' }}>
                  {IconSearchMS}
                </InputAdornment>
              }
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              value={searchTerm}
            />
            <SearchButton variant="text" onClick={handleSearch}>
              {Constants.buttonSearch}
            </SearchButton>
          </SearchBox>
          {loading ? (
            <LoadingBox>
              <LinearProgress />
            </LoadingBox>
          ) : (
            <>
              <Table
                tableData={searchResults.length !== 0 ? searchResults : users}
                userRole={userRole}
              />
              <PagesContainer>
                <Pagination
                  page={page}
                  count={
                    searchResults.length !== 0
                      ? Math.ceil(Number(searchResults.length) / 10)
                      : totalPages
                  }
                  onChange={(_, page) => handleNextPage(page)}
                />
              </PagesContainer>
            </>
          )}
        </TableContainer>
      </Box>
      {openSnackbar && <Snackbar />}
    </GridMain>
  );
}

import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const BackButton = styled(IconButton)(() => ({
  '& span': { fontSize: '40px', color: '#00B39B' },
}));

export const SectionBox = styled(Box)(() => ({
  margin: '40px 0',
}));

export const SectionTitle = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  fontSize: '21px',
  fontWeight: '600',
  margin: '20px 0',
  '& span': { color: '#00B39B' },
}));

export const InputBox = styled(Box)(() => ({
  margin: '30px 0',
  display: 'flex',
  justifyContent: 'space-between',
  gap: '36px',
}));

export const ButtonContainer = styled(Box)(() => ({
  margin: '20px 0',
  display: 'flex',
  justifyContent: 'space-around',
  gap: '40px',
}));

export const ButtonBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: '20px',
}));

export const ButtonTitle = styled(Typography)(() => ({
  fontSize: '18px',
  fontWeight: '600',
  padding: '10px',
}));

type UploadButtonProps = {
  component: string;
};

export const UploadButton = styled(Button)<UploadButtonProps>(
  ({ component }) => ({
    display: 'flex',
    gap: '10px',
    component,
  })
);

export const DownloadButton = styled(Button)(() => ({
  display: 'flex',
  gap: '10px',
}));

export const OutlineButton = styled(Button)(() => ({
  borderRadius: '10px',
  width: '100%',
  border: '2px solid #00B39B',
  color: '#00B39B',
  backgroundColor: '#FAFCFF',
  fontWeight: '600',
  '&:hover': {
    backgroundColor: '#00B39B',
  },
  '&:disabled': {
    color: '#00B39B',
  },
}));

export const CoordGrid = styled(Grid)(() => ({
  marginTop: '-12px',
  display: 'flex',
  flexWrap: 'wrap',
}));

export const BoldTypography = styled(Typography)(() => ({
  fontWeight: '600',
}));

export const SubmitBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row-reverse',
  margin: '80px 0',
}));

export const EditSubmitBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '60px 0',
}));

export const SubmitButton = styled(Button)(() => ({
  minWidth: '500px',
  backgroundColor: '#004D43',
  '&:hover': {
    filter: 'brightness(85%)',
  },
}));

export const EditButton = styled(Button)(({ theme }) => ({
  minWidth: '250px',
  border: '1px solid #004D43',
  color: '#004D43',
  backgroundColor: '#FAFCFF',
  '&:hover': {
    backgroundColor: '#004D43',
    filter: 'brightness(85%)',
  },
  '&:disabled': {
    color: '#004D43',
  },
  [theme.breakpoints.down('lg')]: {
    minWidth: '180px',
    maxWidth: '200px',
  },
}));

export const StyledTextField = styled(TextField)(() => ({
  '& .MuiInputBase-root': {
    borderRadius: '16px',
  },
}));

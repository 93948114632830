import {
  Box,
  Button,
  Divider,
  Grid,
  styled,
  TextField,
  Typography,
} from '@mui/material';

export const NewTitleNotice = styled(Typography)(() => ({
  fontSize: '21px',
  color: '#707070',
  fontFamily: 'Montserrat',
  fontWeight: 600,
}));

export const TabContainerNotice = styled(Box)(() => ({
  padding: '18px 10px',
}));

export const InputContainer = styled(Box)(() => ({
  gap: '16px',
  alignItems: 'center',
  marginTop: '20px',
}));

export const StyledTextFieldObservation = styled(TextField)(() => ({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    borderRadius: 16,
    color: '#707070',
    '& fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#00B39B',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#707070',
    '&.Mui-focused': {
      color: '#00B39B',
    },
  },
}));

export const StyledTextFieldDefault = styled(TextField)(() => ({
  width: '256px',
  '& .MuiOutlinedInput-root': {
    height: 56,
    borderRadius: 16,
    color: '#707070',
    '& fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#00B39B',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#707070',
    '&.Mui-focused': {
      color: '#00B39B',
    },
  },
}));

export const CustomDatePickerContainer = styled(Box)(() => ({
  width: '216px',
  '& .MuiOutlinedInput-root': {
    height: 56,
    borderRadius: 16,
    color: '#707070',
    '& fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#00B39B',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#707070',
    '&.Mui-focused': {
      color: '#00B39B',
    },
  },
}));

export const StyledCalendarIcon = styled(Button)(() => ({
  backgroundColor: '#00B39B',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  width: '20px',
}));

export const GridButton = styled(Grid)(() => ({
  display: 'flex',
  gap: '20px',
  flexDirection: 'column',
  justifyContent: 'center',
}));

export const CreateNoticeButton = styled(Button)(() => ({
  display: 'flex',
  fontFamily: 'Montserrat',
  gap: '10px',
  border: '1px solid #004D43',
  borderRadius: '30px',
  color: '#FAFCFF',
  fontWeight: '600',
  height: '56px',
  backgroundColor: '#004D43',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    backgroundColor: '#0B2623',
    border: '1px solid #0B2623',
    color: '#FAFCFF',
  },
}));

export const AddTypologies = styled(Button)(() => ({
  width: '300px',
  display: 'flex',
  gap: '10px',
  border: '1px solid #00B39B',
  borderRadius: '30px',
  color: '#00B39B',
  fontWeight: '600',
  height: '56px',
  backgroundColor: '#FAFCFF',
  textTransform: 'lowercase',
  '&:hover': {
    backgroundColor: '#00B39B',
  },
  '&:disabled': {
    color: '#00B39B',
  },
}));

export const AddAnnouncement = styled(Button)(() => ({
  width: '220px',
  display: 'flex',
  gap: '10px',
  border: '1px solid #00B39B',
  borderRadius: '30px',
  color: '#FAFCFF',
  fontWeight: '600',
  height: '56px',
  backgroundColor: '#00B39B',
  textTransform: 'lowercase',
  fontFamily: 'Montserrat',

  '&:hover': {
    backgroundColor: '#FAFCFF',
    color: '#00B39B',
  },
  '&:disabled': {
    color: '#00B39B',
  },
}));

export const StyledDivider = styled(Divider)(() => ({
  backgroundColor: '#D7D8DB',
  height: '1px',
  width: '100%',
  margin: '24px 0',
}));

export const CancelButton = styled(Button)(() => ({
  display: 'flex',
  gap: '10px',
  border: '1px solid #004D43',
  borderRadius: '30px',
  color: '#004D43',
  fontFamily: 'Montserrat',
  fontWeight: '600',
  height: '56px',
  backgroundColor: '#FAFCFF',
  textTransform: 'lowercase',
  '&:hover': {
    backgroundColor: '#EDEFF2',
    color: '#004D43',
  },
}));

import {
  Box,
  InputBase,
  ListItem,
  TextField,
  alpha,
  styled,
} from '@mui/material';

const ScrollStyle = {
  '&::-webkit-scrollbar': {
    width: '10px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#B9BABB',
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: '#B9BABBCC',
    },
  },
  '&::-webkit-scrollbar-track': {
    border: '1px solid #D7D8DB',
    borderRadius: '20px',
  },
};

export const SearchTextField = styled(TextField)(() => ({
  width: '115px',
  '& .MuiInputBase-root': {
    borderRadius: '16px',
    fontSize: '14px',
    '& .MuiSelect-select': {
      padding: '12px 24px 12px 12px',
    },
  },
}));

export const ListCompanies = styled(Box)(() => ({
  ...ScrollStyle,
  flex: 1,
  padding: '40px 30px',
  overflowY: 'auto',
  background: '#FFFFFE',
  height: 600,
  boxShadow: '0px 3px 6px #00000029',
  borderRadius: 4,
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  borderBottom: '1px solid',
  borderImage:
    'linear-gradient(to right, transparent 11%, #004D43 11%, #004D43 75%) 5',
  padding: '0 8px',
  marginBottom: '22px',
  width: '96%',
  [theme.breakpoints.up('xl')]: {
    marginBottom: '32px',
    borderImage:
      'linear-gradient(to right, transparent 9%, #004D43 9%, #004D43 75%) 5',
  },
}));

export const ListItemStyled = styled(ListItem)(() => ({
  font: '400 16px Montserrat',
  gap: 16,
  borderBottom: '1px solid #DCDDE0',
  cursor: 'pointer',
  '& img': {
    width: 32,
  },
  '&.active': {
    background: '#ECF0F6',
    margin: 2,
    borderRadius: 4,
  },
  '&:hover': {
    background: alpha('#ECF0F6', 0.5),
  },
}));

import { Button, Grid, TextField } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import { ChangeEvent, useState } from 'react';
import { PatternFormat } from 'react-number-format';

import { CustomTextField } from '../../../../../components/CustomInput';
import { IconUploadMS } from '../../../../../constants/icons';
import { formatPhone } from '../../../../../helpers';
import useSearchCep from '../../../../../hooks/useSearchCep';
import {
  GridSection,
  OutlinedButton,
  textFiledDefaultStyle,
  PatternFormatStyle,
  StyledTextField,
  GridItemX,
} from './styles';

interface NewCompanyProps {
  licenseDocument: string;
  setLicenseDocument: (value: string) => void;
  setAddNewCompany: (value: boolean) => void;
}

export function NewCompany({
  licenseDocument,
  setLicenseDocument,
  setAddNewCompany,
}: NewCompanyProps): JSX.Element {
  const [companyName, setCompanyName] = useState('');
  const [registrationDate, setRegistrationDate] = useState<Dayjs | null>(null);
  const [responsible, setResponsible] = useState('');
  const [document, setDocument] = useState('');
  const [contact, setContact] = useState('');
  const [email, setEmail] = useState('');
  const [cep, setCep] = useState('');
  const [cepError, setCepError] = useState(false);
  const [address, setAddress] = useState('');
  const [addressNumber, setAddressNumber] = useState('');
  const [addressComplement, setAddressComplement] = useState('');
  const [district, setDistrict] = useState('');
  const [city, setCity] = useState('');
  const [uf, setUf] = useState('');

  const { handleSearchCep } = useSearchCep();

  const verifyCep = async (cep: string): Promise<void> => {
    const addressData = await handleSearchCep(cep);
    if (addressData) {
      setCep(addressData.cep);
      setUf(addressData.uf);
      setCity(addressData.city);
      setAddress(addressData.address);
      setCepError(false);
    } else {
      setCepError(true);
    }
  };

  return (
    <>
      <Grid item xs={6} display="flex" alignItems="center">
        Deseja cadastrar nova empresa?
      </Grid>
      <Grid item xs={12}>
        <GridSection container m="20px 0">
          <GridItemX item xs={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="data do credenciamento"
                value={registrationDate}
                format="DD-MM-YYYY"
                slotProps={{
                  textField: {
                    sx: textFiledDefaultStyle,
                  },
                }}
                onChange={(e) => setRegistrationDate(e)}
              />
            </LocalizationProvider>
          </GridItemX>
          <GridItemX item xs={4}>
            <CustomTextField
              id="name"
              label="nome da empresa"
              value={companyName}
              setValue={setCompanyName}
            />
          </GridItemX>
          <GridItemX item xs={4}>
            <CustomTextField
              id="cnpj/cpf"
              label="CPNJ/CPF"
              value={licenseDocument}
              setValue={setLicenseDocument}
            />
          </GridItemX>
          <GridItemX item xs={4}>
            <CustomTextField
              id="responsible"
              label="responsável"
              value={responsible}
              setValue={setResponsible}
            />
          </GridItemX>
          <GridItemX item xs={4}>
            <CustomTextField
              id="document"
              label="CREA/CAU"
              value={document}
              setValue={setDocument}
            />
          </GridItemX>
          <GridItemX item xs={4}>
            <CustomTextField
              id="email"
              label="email"
              value={email}
              setValue={setEmail}
            />
          </GridItemX>
          <GridItemX item xs={4}>
            <TextField
              id="contact"
              label="contato"
              color="secondary"
              inputProps={{ maxLength: 15 }}
              value={contact}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setContact(formatPhone(e.target.value))
              }
              sx={PatternFormatStyle}
            />
          </GridItemX>
          <GridItemX item xs={4}>
            <PatternFormat
              format="#####-###"
              mask="_"
              customInput={StyledTextField}
              id="cep"
              label="CEP"
              color="secondary"
              value={cep}
              onInvalid={(e: ChangeEvent<HTMLInputElement>) =>
                e.target.setCustomValidity('Preencha este campo')
              }
              onInput={(e: ChangeEvent<HTMLInputElement>) =>
                e.target.setCustomValidity('')
              }
              error={cepError}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setCep(e.target.value);
                verifyCep(e.target.value);
              }}
            />
          </GridItemX>
          <GridItemX item xs={4}>
            <CustomTextField
              id="address"
              label="logradouro"
              value={address}
              setValue={setAddress}
            />
          </GridItemX>
          <GridItemX item xs={4}>
            <CustomTextField
              id="address-number"
              label="número"
              value={addressNumber}
              setValue={setAddressNumber}
            />
          </GridItemX>
          <GridItemX item xs={4}>
            <CustomTextField
              id="address-complement"
              label="complemento"
              value={addressComplement}
              setValue={setAddressComplement}
            />
          </GridItemX>
          <GridItemX item xs={4}>
            <CustomTextField
              id="district"
              label="bairro"
              value={district}
              setValue={setDistrict}
            />
          </GridItemX>
          <GridItemX item xs={4}>
            <CustomTextField
              id="city"
              label="cidade"
              value={city}
              setValue={setCity}
            />
          </GridItemX>
          <GridItemX item xs={4}>
            <CustomTextField
              id="uf"
              label="estado"
              value={uf}
              setValue={setUf}
            />
          </GridItemX>
          <GridItemX item xs={4}>
            <OutlinedButton sx={{ borderRadius: '10px' }}>
              {IconUploadMS} anexos
            </OutlinedButton>
          </GridItemX>
          <GridItemX item xs={12} mb="40px" />
          <GridItemX item xs={4} />
          <GridItemX item xs={4} mb="10px">
            <OutlinedButton onClick={() => setAddNewCompany(false)}>
              cancelar
            </OutlinedButton>
          </GridItemX>
          <GridItemX item xs={4}>
            <Button sx={{ width: '100%' }}>salvar</Button>
          </GridItemX>
        </GridSection>
      </Grid>
    </>
  );
}

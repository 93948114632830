/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, CircularProgress } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MUIModal from '@mui/material/Modal';
import React, { useState, useCallback, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';

import {
  createAnnouncement,
  deleteAnnouncement,
} from '../../../../../../api/theHive/announcement';
import {
  IconDeleteMS,
  IconCloseMS,
  IconVisibilityMS,
} from '../../../../../../constants/icons';
import { GlobalContext } from '../../../../../../context/global';
import useErrorMessage from '../../../../../../hooks/useErrorMessage';
import { AnnouncementData } from '../AnnouncementTable/types';
import { ModalViewAnnouncements } from '../ModalViewAnnouncements';
import {
  CloseButton,
  SaveCoverageButton,
  ScrollableGrid,
  StyledbBoxContainer,
  StyledTextFieldGroup,
  StyledTextFieldMultiline,
  TableDivider,
  TitleMoldal,
} from './styles';
import { IDefaulCanceltModalProps } from './types';

interface IAnnouncement {
  id: number;
  subject: string;
  contents: string;
  is_visible: boolean;
}

export function NewAnnouncementModal({
  isModalOpen,
  onCloseModal,
  title,
  onAnnouncementUpdated,
  announcements,
  setAnnouncements,
}: IDefaulCanceltModalProps): JSX.Element {
  const { notice: noticeIdParam } = useParams();
  const noticeId = parseInt(noticeIdParam as string, 10);
  const [showViewAnnouncementModal, setShowViewAnnouncementModal] =
    useState(false);

  const [selectedAnnouncement, setSelectedAnnouncement] =
    useState<IAnnouncement | null>(null);
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const { getErrorMessage } = useErrorMessage();

  const validateForm = useCallback(() => {
    const isSubjectValid = subject.trim() !== '';
    const isMessageValid = message.trim() !== '';
    setIsFormValid(isSubjectValid && isMessageValid);
  }, [subject, message]);

  const calculateModalHeight = () => {
    const baseHeight = 0;
    const additionalHeight = Math.min(announcements.length * 50, 218);
    return `${baseHeight + additionalHeight}px`;
  };

  useEffect(() => {
    validateForm();
  }, [subject, message, validateForm]);

  const handleDelete = useCallback(
    async (id: number) => {
      try {
        await deleteAnnouncement(id);
        setAnnouncements((prev: any) =>
          prev.filter((item: any) => item.id !== id)
        );
        if (onAnnouncementUpdated) {
          await onAnnouncementUpdated();
        }
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setErrorMessage(true);
        setOpenSnackbar(true);
      }
    },
    [onAnnouncementUpdated]
  );

  const handleSubjectChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setSubject(e.target.value);
  };

  const handleMessageChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setMessage(e.target.value);
  };

  const handleShowViewAnnouncementModal = (
    announcement: AnnouncementData
  ): void => {
    setSelectedAnnouncement(announcement);
    setShowViewAnnouncementModal(true);
  };

  const handleCloseViewAnnouncementModal = useCallback(() => {
    setSelectedAnnouncement(null);
  }, []);

  const handleSubmit = async (): Promise<void> => {
    if (!isFormValid || !noticeId) return;

    setIsLoading(true); // Ativa o loading circular
    try {
      const newAnnouncementData = {
        subject,
        contents: message,
      };

      await createAnnouncement(noticeId, newAnnouncementData);
      setSubject('');
      setMessage('');
      if (onAnnouncementUpdated) {
        await onAnnouncementUpdated();
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <MUIModal open={isModalOpen} onClose={onCloseModal}>
        <StyledbBoxContainer>
          <Grid container direction="column" style={{ position: 'relative' }}>
            <Grid
              container
              item
              xs={12}
              alignItems="center"
              justifyContent="space-between"
              mb={2}
              style={{ opacity: isLoading ? 0.5 : 1 }}
            >
              <Grid item xs={3.5}>
                <TitleMoldal>{title}</TitleMoldal>
              </Grid>
              <Grid item mr={4}>
                <CloseButton onClick={onCloseModal}>{IconCloseMS}</CloseButton>
              </Grid>
            </Grid>

            <ScrollableGrid
              style={{
                height: calculateModalHeight(),
                opacity: isLoading ? 0.5 : 1,
              }}
            >
              {announcements.map((announcement: any) => (
                <React.Fragment key={announcement.id}>
                  <TableDivider />
                  <Grid container alignItems="center">
                    <Grid item xs sx={{ textAlign: 'left', padding: '8px' }}>
                      <span
                        style={{
                          color: '#707070',
                          fontSize: '14px',
                          lineHeight: '32px',
                        }}
                      >
                        {announcement.subject.length > 20
                          ? `${announcement.subject.substring(0, 20)}...`
                          : announcement.subject}
                      </span>
                    </Grid>
                    <Grid
                      item
                      sx={{
                        display: 'flex',
                        gap: '12px',
                        paddingRight: '12px',
                      }}
                    >
                      <IconButton
                        onClick={() => handleDelete(announcement.id)}
                        sx={{
                          color: '#06DFC2',
                          height: '32px',
                          width: '32px',
                        }}
                      >
                        {IconDeleteMS}
                      </IconButton>
                      <IconButton
                        onClick={() =>
                          handleShowViewAnnouncementModal(announcement)
                        }
                        sx={{
                          color: '#06DFC2',
                          height: '32px',
                          width: '32px',
                        }}
                      >
                        {IconVisibilityMS}
                      </IconButton>
                    </Grid>
                  </Grid>
                </React.Fragment>
              ))}
            </ScrollableGrid>

            <Grid
              item
              mt={2}
              xs={11.5}
              ml={1.2}
              style={{ opacity: isLoading ? 0.5 : 1 }}
            >
              <Grid item xs={12} container justifyContent="start">
                <StyledTextFieldGroup
                  label="Assunto"
                  value={subject}
                  onChange={handleSubjectChange}
                  inputProps={{ maxLength: 20 }}
                />
              </Grid>
              <Grid item xs={12} mt={2}>
                <StyledTextFieldMultiline
                  label="Comunicado"
                  multiline
                  rows={4}
                  value={message}
                  onChange={handleMessageChange}
                />
              </Grid>
            </Grid>

            <Grid
              item
              container
              justifyContent="end"
              mt={2}
              xs={11.5}
              style={{ opacity: isLoading ? 0.5 : 1 }}
            >
              <SaveCoverageButton
                disabled={!isFormValid || isLoading}
                onClick={handleSubmit}
              >
                Enviar
              </SaveCoverageButton>
            </Grid>

            {isLoading && (
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(255, 255, 255, 0.7)',
                  zIndex: 1,
                }}
              >
                <CircularProgress style={{ color: '#06DFC2' }} />
              </Grid>
            )}
          </Grid>
        </StyledbBoxContainer>
      </MUIModal>

      {selectedAnnouncement && (
        <ModalViewAnnouncements
          title={selectedAnnouncement.subject}
          announcement={{
            id: selectedAnnouncement.id,
            subject: selectedAnnouncement.subject,
            contents: selectedAnnouncement.contents,
            is_visible: selectedAnnouncement.is_visible,
          }}
          onCloseModal={handleCloseViewAnnouncementModal}
          isModalOpen={showViewAnnouncementModal}
          onAnnouncementUpdated={onAnnouncementUpdated}
        />
      )}
    </>
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useCallback, useContext, useState } from 'react';

import { getTypologyGroups } from '../../../../../../../api/theHive/typology';
import { GlobalContext } from '../../../../../../../context/global';
import useErrorMessage from '../../../../../../../hooks/useErrorMessage';
import {
  TypologyData,
  UseTypologyGroupsReturn,
} from '../../TypologyGroupGrid/types';

export function useTypologyGroups(noticeId?: number): UseTypologyGroupsReturn {
  const [typologyGroups, setTypologyGroups] = useState<TypologyData>({});
  const [isLoading, setIsLoading] = useState(false);
  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const { getErrorMessage } = useErrorMessage();

  const fetchTypologyGroups = useCallback(async () => {
    if (!noticeId) return;

    setIsLoading(true);
    try {
      const response = await getTypologyGroups(noticeId);

      const formattedData: TypologyData = response?.data?.reduce(
        (acc: TypologyData, group: any) => {
          acc[group.id] = {
            title: group.name,
            value: group.price,
            properties: group.typologies.map((typology: any) => ({
              type: typology.real_estate_type,
              id: typology.typology_group_id,
              area:
                typology.rule_keyword === 'between'
                  ? [typology.rule_first_value, typology.rule_second_value!]
                  : typology.rule_first_value,
            })),
          };
          return acc;
        },
        {}
      );

      setErrorMessage(false);

      setTypologyGroups(formattedData);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    } finally {
      setIsLoading(false);
    }
  }, [noticeId]);

  return {
    typologyGroups,
    isLoading,
    fetchTypologyGroups,
  };
}

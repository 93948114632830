/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';

import { StatusCode } from '../../api/enumerations';
import { getAllWorkOrders, getTopWorkOrders } from '../../api/workOrders';
import {
  GetAllWorkOrderDataProps,
  GetTopWorkOrdersData,
} from '../../api/workOrders/types';
import { Search } from '../../components/Search';
import Snackbar from '../../components/Snackbar';
import { Constants, presentColumnData } from '../../constants/home';
import {
  IconAddMS,
  IconAssignmentMS,
  IconCheckCircleMS,
  IconCloseMS,
  IconDesktopWindowsMS,
} from '../../constants/icons';
import { GlobalContext } from '../../context/global';
import { useStoragedJwt } from '../../hooks/useDecodedJwt';
import useErrorMessage from '../../hooks/useErrorMessage';
import { BankSearch } from './BankSearch';
import { Column } from './Column';
import {
  GridContainer,
  OSButton,
  PipelineContainer,
  ScrollTypography,
  StyledToast,
  TitleBox,
  TitleTypography,
  ToastTypography,
  TVMode,
} from './styles';

export function Home(): JSX.Element {
  const [defaultData, setDefaultData] = useState<
    GetAllWorkOrderDataProps | GetTopWorkOrdersData
  >();
  const [pipelineData, setPipelineData] = useState<
    GetAllWorkOrderDataProps | GetTopWorkOrdersData
  >();
  const [searchField, setSearchField] = useState('');
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const isBank = process.env.REACT_APP_IS_BANK === 'true';

  const { openSnackbar, setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const { getErrorMessage } = useErrorMessage();
  const navigate = useNavigate();
  const decoded = useStoragedJwt();
  const { lastMessage } = useWebSocket(
    `${process.env.REACT_APP_API_WEBSOCKET_URL}/ws/${decoded?.user.id}`
  );

  const getTopWorkOrdersCallback = useCallback(async () => {
    setLoading(true);
    try {
      const topWorkOrders = await getTopWorkOrders();

      if (topWorkOrders.detail.description) {
        throw new Error(topWorkOrders.detail.description);
      }

      if (topWorkOrders.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (topWorkOrders.data) {
        setDefaultData(topWorkOrders.data);
        setPipelineData(topWorkOrders.data);
        setLoading(false);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const messageData = lastMessage ? JSON.parse(lastMessage.data) : null;
    if (messageData) {
      setToastMessage(
        `OS ${messageData.payload.reference_number} finalizou o cálculo.`
      );
      setOpenToast(true);
    }
    getTopWorkOrdersCallback();
  }, [getTopWorkOrdersCallback, lastMessage]);

  const getWorkOrdersBySearchField = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getAllWorkOrders(searchField);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.data) {
        setPipelineData(response.data);
        setLoading(false);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      setLoading(false);
    }
  }, [searchField]);

  useEffect(() => {
    if (searchField.length > 0) {
      const delayDebounceFn = setTimeout(() => {
        getWorkOrdersBySearchField();
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }
    return setPipelineData(defaultData);
  }, [searchField]);

  const handleNewOs = (): void => {
    navigate('/home/property/new');
  };

  const switchToPresentationMode = (): void => {
    navigate('/home/presentation-mode');
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ): void => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      {IconCloseMS}
    </IconButton>
  );

  return (
    <GridContainer>
      <TitleBox>
        <TitleTypography>
          {IconAssignmentMS}
          {isBank ? Constants.bankTitle : Constants.title}
        </TitleTypography>
        <OSButton onClick={handleNewOs}>
          {IconAddMS}
          {Constants.buttonAdd}
        </OSButton>
        {!isBank && (
          <TVMode onClick={switchToPresentationMode}>
            {IconDesktopWindowsMS}
            {Constants.TVMode}
          </TVMode>
        )}
        {isBank ? (
          <BankSearch setSearchField={setSearchField} />
        ) : (
          <Search setSearchField={setSearchField} />
        )}
      </TitleBox>
      <PipelineContainer>
        {presentColumnData(pipelineData).map((column) => (
          <Column
            key={column.name}
            columnData={column}
            pipelineLoading={loading}
            isSearching={searchField.length > 0}
          />
        ))}
      </PipelineContainer>
      <ScrollTypography>-</ScrollTypography>
      {openSnackbar && <Snackbar />}
      <StyledToast
        open={openToast}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={handleClose}
        action={action}
        message={
          <ToastTypography>
            {IconCheckCircleMS}
            {toastMessage}
          </ToastTypography>
        }
      />
    </GridContainer>
  );
}

import { styled, TableCell, TableRow, Pagination } from '@mui/material';

export const TableCellHeader = styled(TableCell)(({ theme }) => ({
  color: '#004D43',
  font: '600 21px Montserrat',
  backgroundColor: '#F1F5FA',
  verticalAlign: 'middle',
  padding: '20px 16px',
  '& span': {
    verticalAlign: 'bottom',
    paddingLeft: '4px',
  },
  [theme.breakpoints.down('xl')]: {
    fontSize: '16px',
    '& span': {
      fontSize: '18px',
    },
  },
}));

export const StyledCell = styled(TableCell)(({ theme }) => ({
  color: '#707070',
  fontSize: '15px',
  [theme.breakpoints.up('xl')]: {
    padding: '20px',
    fontSize: '16px',
  },
}));

export const StyledCellIcon = styled(TableCell)(({ theme }) => ({
  color: '#00B39B',
  fontSize: '12px',
  textAlign: 'end',
  cursor: 'pointer',
  paddingRight: '32px',
  [theme.breakpoints.up('xl')]: {
    padding: '20px 32px 20px 20px',
    fontSize: '16px',
  },
}));

export const StyledRow = styled(TableRow)(() => ({
  '&:nth-of-type(even)': {
    backgroundColor: '#F1F5FA66',
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const StyledPagination = styled(Pagination)(() => ({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'flex-end',
  margin: '8px',
  width: '100%',
}));

/* eslint-disable react/no-array-index-key */
import { Grid, IconButton } from '@mui/material';
import MUIModal from '@mui/material/Modal';
import { useState, useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { IconDeleteMS } from '../../../../../../constants/icons';
import { CitiesAccordion } from '../CitiesAccordion';
import { CitiesAccordionData } from '../CitiesAccordion/types';
import {
  AddNewPoleButton,
  CancelButton,
  SaveCoverageButton,
  ScrollableGrid,
  StyledbBoxContainer,
  TitleMoldal,
} from './styles';
import { ModalAddingCoverageProps } from './types';
import { useCoverageOperations } from './useCoverageOperations';

export function ModalAddingCoverage({
  isModalOpen,
  onCloseModal,
  title,
  fetchCoverage,
}: ModalAddingCoverageProps): JSX.Element {
  const [citiesAccordions, setCitiesAccordions] = useState<
    CitiesAccordionData[]
  >([]);
  const [isSaving, setIsSaving] = useState(false);
  const [expandedAccordions, setExpandedAccordions] = useState<boolean[]>([]);
  const { notice } = useParams();
  const noticeId = parseInt(notice as string, 10);

  useEffect(() => {
    if (isModalOpen) {
      setCitiesAccordions([
        { selectedCity: '', displacementValue: '', microregions: [] },
      ]);
      setExpandedAccordions([true]);
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (!isModalOpen) {
      setCitiesAccordions([]);
      setExpandedAccordions([]);
    }
  }, [isModalOpen]);

  const { createCoverage } = useCoverageOperations({
    noticeId,
    onSuccess: () => {
      onCloseModal?.();
      fetchCoverage();
    },
  });

  const handleCitiesDataChange = useCallback(
    (index: number, data: CitiesAccordionData) => {
      setCitiesAccordions((prev) => {
        const updated = [...prev];
        updated[index] = data;
        return updated;
      });
    },
    []
  );

  const handleAddNewPole = useCallback(() => {
    setCitiesAccordions((prev) => [
      ...prev,
      { selectedCity: '', displacementValue: '', microregions: [] },
    ]);
    setExpandedAccordions((prev) => [...prev, false]);
  }, []);

  const isSaveDisabled = useMemo(() => {
    if (isSaving) return true;

    const hasValidPole = citiesAccordions.some(
      (accordion) => accordion.selectedCity && accordion.displacementValue
    );
    return !hasValidPole;
  }, [citiesAccordions, isSaving]);

  const handleSave = useCallback(async () => {
    try {
      setIsSaving(true);
      const transformedData: CitiesAccordionData[] = citiesAccordions.map(
        (region) => ({
          cityId: region.cityId,
          selectedCity: region.selectedCity,
          displacementValue: region.displacementValue,
          microregions: region.microregions.map((subregion) => ({
            id: subregion.id,
            value: subregion.value || '',
            city_id: subregion.id || null,
            city: subregion,
          })),
        })
      );
      const success = await createCoverage(transformedData);
      if (success) {
        onCloseModal?.();
      }
    } finally {
      setIsSaving(false);
    }
  }, [citiesAccordions, createCoverage, onCloseModal]);

  const handleAccordionChange = useCallback(
    (index: number, isExpanded: boolean) => {
      setExpandedAccordions((prev) => {
        const updated = [...prev];
        updated[index] = isExpanded;
        return updated;
      });
    },
    []
  );

  const handleRemovePole = useCallback((index: number) => {
    setCitiesAccordions((prev) => prev.filter((_, i) => i !== index));
    setExpandedAccordions((prev) => prev.filter((_, i) => i !== index));
  }, []);

  return (
    <MUIModal open={isModalOpen} onClose={onCloseModal}>
      <StyledbBoxContainer>
        <Grid item container justifyContent="center" xs={3.5}>
          <TitleMoldal>{title}</TitleMoldal>
        </Grid>
        <ScrollableGrid item container xs={12}>
          {citiesAccordions.map((accordionData, index) => (
            <Grid item container xs={12} key={index} alignItems="baseline">
              <CitiesAccordion
                onDataChange={(data) => handleCitiesDataChange(index, data)}
                expanded={expandedAccordions[index]}
                onExpandedChange={(isExpanded: boolean) =>
                  handleAccordionChange(index, isExpanded)
                }
              />
              {index > 0 && (
                <Grid item>
                  <IconButton
                    onClick={() => handleRemovePole(index)}
                    sx={{
                      color: '#06DFC2',
                      width: '42px',
                      border: '1px solid #06DFC2',
                      borderRadius: '12px',
                      transform: 'scale(1.2)',
                      marginLeft: '290px',
                      '&:hover': {
                        backgroundColor: 'rgba(6, 223, 194, 0.1)',
                      },
                    }}
                  >
                    {IconDeleteMS}
                  </IconButton>
                </Grid>
              )}
            </Grid>
          ))}
        </ScrollableGrid>
        <Grid item container justifyContent="end" mt={2} xs={11.5}>
          <AddNewPoleButton onClick={handleAddNewPole} disabled={isSaving}>
            Novo polo
          </AddNewPoleButton>
        </Grid>
        <Grid item container justifyContent="center" mt={2}>
          <Grid item mr={2}>
            <CancelButton onClick={onCloseModal} disabled={isSaving}>
              Cancelar
            </CancelButton>
          </Grid>
          <Grid item ml={2}>
            <SaveCoverageButton onClick={handleSave} disabled={isSaveDisabled}>
              {isSaving ? 'Salvando...' : 'Salvar'}
            </SaveCoverageButton>
          </Grid>
        </Grid>
      </StyledbBoxContainer>
    </MUIModal>
  );
}

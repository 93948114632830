/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback, useContext } from 'react';

import {
  createNoticeCoverage,
  createSingleCoverage,
  deleteCoverage,
  getNoticeCoverage,
} from '../../../../../../api/theHive/noticeCoverage';
import {
  NoticeCoverageData,
  SingleCoverageData,
} from '../../../../../../api/theHive/noticeCoverage/types';
import { GlobalContext } from '../../../../../../context/global';
import useErrorMessage from '../../../../../../hooks/useErrorMessage';
import { CitiesAccordionData } from '../CitiesAccordion/types';

interface UseCoverageOperationsProps {
  noticeId: number;
  onSuccess?: () => void;
}

interface CoverageOperationsReturn {
  isLoading: boolean;
  createCoverage: (accordionsData: CitiesAccordionData[]) => Promise<boolean>;
  getCoverage: () => Promise<boolean>;
  createSingleCoverageData: (data: SingleCoverageData) => Promise<boolean>;
  deleteNoticeCoverage: (id: number) => Promise<boolean>;
}

export const useCoverageOperations = ({
  noticeId,
  onSuccess,
}: UseCoverageOperationsProps): CoverageOperationsReturn => {
  const [isLoading, setIsLoading] = useState(false);
  const { getErrorMessage } = useErrorMessage();
  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const handleApiOperation = async (
    operation: () => Promise<any>,
    successMessage: string
  ): Promise<boolean> => {
    try {
      const response = await operation();
      if (response.detail?.status_code === 0) {
        setSnackbarMessage(successMessage);
        setOpenSnackbar(true);
        onSuccess?.();
        return true;
      }
      throw new Error(response.detail?.description || 'Operation failed');
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      return false;
    }
  };

  const formatCoverageData = (
    accordions: CitiesAccordionData[]
  ): NoticeCoverageData => {
    const processRegion = (accordion: CitiesAccordionData) => {
      if (!accordion.cityId || Number.isNaN(accordion.cityId)) {
        return null;
      }

      let displacementFee: number | undefined;
      if (accordion.displacementValue) {
        const parsed = parseFloat(
          accordion.displacementValue.replace(/[^\d,]/g, '').replace(',', '.')
        );
        if (!Number.isNaN(parsed)) {
          displacementFee = parsed;
        }
      }

      const validSubregions = accordion.microregions
        .filter((region) => {
          return region.city_id !== undefined && region.city_id !== null;
        })
        .map((region) => ({
          city_id: region.city_id!,
        }));

      return {
        city_id: accordion.cityId,
        ...(displacementFee !== undefined && {
          displacement_fee: displacementFee,
        }),
        subregions: validSubregions,
      };
    };

    const formattedRegions = accordions
      .map(processRegion)
      .filter(
        (region): region is NonNullable<typeof region> => region !== null
      );

    return {
      main_regions: formattedRegions,
    };
  };

  const createCoverage = useCallback(
    async (accordionsData: CitiesAccordionData[]): Promise<boolean> => {
      setIsLoading(true);
      const result = await handleApiOperation(
        () =>
          createNoticeCoverage(noticeId, formatCoverageData(accordionsData)),
        'Cobertura criada com sucesso'
      );
      setIsLoading(false);
      return result;
    },
    [noticeId]
  );

  const getCoverage = useCallback(async (): Promise<boolean> => {
    setIsLoading(true);
    const result = await handleApiOperation(
      () => getNoticeCoverage(noticeId),
      'Cobertura obtida com sucesso'
    );
    setIsLoading(false);
    return result;
  }, [noticeId]);

  const deleteNoticeCoverage = useCallback(
    async (coverageId: number): Promise<boolean> => {
      // Não setamos isLoading aqui pois será controlado pelo componente
      const result = await handleApiOperation(
        () => deleteCoverage(coverageId),
        'Cobertura deletada com sucesso'
      );
      return result;
    },
    []
  );

  const createSingleCoverageData = useCallback(
    async (data: SingleCoverageData): Promise<boolean> => {
      setIsLoading(true);
      const result = await handleApiOperation(
        () => createSingleCoverage(noticeId, data),
        'Cobertura única criada com sucesso'
      );
      setIsLoading(false);
      return result;
    },
    [noticeId]
  );

  return {
    isLoading,
    createCoverage,
    getCoverage,
    deleteNoticeCoverage,
    createSingleCoverageData,
  };
};

/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Divider, IconButton } from '@mui/material';

import { IconCloseMS } from '../../../../../../constants/icons';
import logoRealPrice from '../../../icons/realprice.svg';
import {
  HeaderDescription,
  ModalStyled,
  PaperStyled,
  CitiesServerd,
  CompanyTextDescription,
  DescriptionContainer,
} from './styles';

interface InfoCompanyModalProps {
  open: boolean;
  onClose: () => void;
}

export function InfoCompanyModal({
  open,
  onClose,
}: InfoCompanyModalProps): JSX.Element {
  const handleClose = (event: any) => {
    if (event.type === 'click') return;
    onClose();
  };
  return (
    <ModalStyled open={open} disableEscapeKeyDown onClose={handleClose}>
      <PaperStyled>
        <header>
          <IconButton onClick={onClose}>{IconCloseMS}</IconButton>
        </header>

        {/* <CompanyDescription> */}
        <HeaderDescription>
          <img src={logoRealPrice} alt="" style={{ height: 100 }} />
          <h1>Real Price</h1>
        </HeaderDescription>
        <Divider sx={{ width: '90%' }} />
        <DescriptionContainer>
          <CitiesServerd>
            <p>Cidades atendidas:</p>
            <p>São Paulo - SP</p>
            <p>Boituva - SP</p>
            <p>Brotas - SP</p>
            <p>Botucatu - SP</p>
            <p>Barra - SP</p>
          </CitiesServerd>
          <CompanyTextDescription>
            <p>
              CNPJ: <strong>11.222.333/0001-00</strong>
            </p>
            <p>
              Email: <strong>realprice@contato.com.br</strong>
            </p>
            <p>
              Telefone: <strong>(11) 9 9999-6666</strong>
            </p>
            <p>
              CEP: <strong>05003-020</strong>
            </p>
            <p>
              Logradouro: <strong>Av. Antártica, 675 - Sala 1502 </strong>
            </p>
            <p>
              Cidade: <strong>São Paulo - SP</strong>
            </p>
          </CompanyTextDescription>
        </DescriptionContainer>
      </PaperStyled>
    </ModalStyled>
  );
}

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useState, useCallback, useContext } from 'react';

import {
  createTypology,
  createTypologyGroup,
  deleteTypology,
  deleteTypologyGroup,
  updateTypology,
  updateTypologyGroup,
} from '../../../../../../api/theHive/typology';
import { GlobalContext } from '../../../../../../context/global';
import useErrorMessage from '../../../../../../hooks/useErrorMessage';
import { TypologyGroupData, TypologyData } from './types';

interface UseTypologyOperationsProps {
  noticeId: number;
  onSuccess?: () => void;
}

export const useTypologyOperations = ({
  noticeId,
  onSuccess,
}: UseTypologyOperationsProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { getErrorMessage } = useErrorMessage();
  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const handleApiOperation = async <T>(
    operation: () => Promise<any>,
    successMessage: string
  ): Promise<boolean> => {
    setIsLoading(true);
    try {
      const response = await operation();
      if (response.detail?.status_code === 0) {
        setSnackbarMessage(successMessage);
        setOpenSnackbar(true);
        onSuccess?.();
        return true;
      }
      setSnackbarMessage(
        response.detail?.description ||
          'Todos os campos devem ser preenchidos para criar uma tipologia'
      );
      setErrorMessage(true);
      setOpenSnackbar(true);
      return false;
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const createNewTypologyGroup = useCallback(
    async (
      data: Omit<TypologyGroupData['typology_groups'][0], 'notice_id'>
    ) => {
      const requestData: TypologyGroupData = {
        typology_groups: [
          {
            ...data,
            notice_id: noticeId,
          },
        ],
      };

      return handleApiOperation(
        () => createTypologyGroup(requestData),
        'Grupo de tipologia criado com sucesso'
      );
    },
    [noticeId]
  );

  const removeTypologyGroup = useCallback(async (groupId: number) => {
    return handleApiOperation(
      () => deleteTypologyGroup(groupId),
      'Grupo de tipologia removido com sucesso'
    );
  }, []);

  const modifyTypologyGroup = useCallback(
    async (
      groupId: number,
      data: Partial<TypologyGroupData['typology_groups'][0]>
    ) => {
      return handleApiOperation(
        () => updateTypologyGroup(groupId, data),
        'Grupo de tipologia atualizado com sucesso'
      );
    },
    []
  );

  const createNewTypology = useCallback(
    async (groupId: number, data: TypologyData) => {
      return handleApiOperation(
        () => createTypology(groupId, data),
        'Tipologia criada com sucesso'
      );
    },
    []
  );

  const removeTypology = useCallback(async (typologyId: number) => {
    return handleApiOperation(
      () => deleteTypology(typologyId),
      'Tipologia removida com sucesso'
    );
  }, []);

  const modifyTypology = useCallback(
    async (typologyId: number, data: Partial<TypologyData>) => {
      return handleApiOperation(
        () => updateTypology(typologyId, data),
        'Tipologia atualizada com sucesso'
      );
    },
    []
  );

  return {
    isLoading,
    createNewTypologyGroup,
    removeTypologyGroup,
    modifyTypologyGroup,
    createNewTypology,
    removeTypology,
    modifyTypology,
  };
};

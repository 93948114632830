import { Box, LinearProgress } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { StatusCode } from '../../../api/enumerations';
import { getWorkOrdersInStatus } from '../../../api/workOrders';
import { WorkOrderData } from '../../../api/workOrders/types';
import { ColumnValues } from '../../../constants/home';
import { GlobalContext } from '../../../context/global';
import useErrorMessage from '../../../hooks/useErrorMessage';
import { Card } from './Card';
import {
  PipelineBox,
  PipelinePaper,
  PipelineTitle,
  StyledSkeleton,
} from './styles';

interface ColumnProps {
  columnData: ColumnValues;
  pipelineLoading: boolean;
  isSearching: boolean;
}

export function Column({
  columnData,
  pipelineLoading,
  isSearching,
}: ColumnProps): JSX.Element {
  const [workOrders, setWorkOrders] = useState<WorkOrderData[]>([]);
  const [page, setPage] = useState(2);
  const [lastCalledPage, setLastCalledPage] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);

  const observer = useRef<IntersectionObserver | null>(null);

  const currentStatus = columnData.status;

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const { getErrorMessage } = useErrorMessage();

  const getWorkOrderInStatus = async (): Promise<void> => {
    if (page === lastCalledPage) {
      return;
    }

    setLoadingMore(true);
    try {
      const workOrdersPerPage = 10;
      const response = await getWorkOrdersInStatus(
        currentStatus,
        page,
        workOrdersPerPage
      );

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.data) {
        const updatedData = [...workOrders, ...response.data];
        setWorkOrders(updatedData);
        setLastCalledPage(page);

        if (response.detail.total_pages && response.detail.total_pages > page) {
          setPage(page + 1);
        }

        setLoadingMore(false);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      setLoadingMore(false);
    }
  };

  // Function to handle infinite scrolling using Intersection Observer API.
  const lastElementRef = (node: HTMLAnchorElement): void => {
    if (loadingMore) return;

    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (
        entries[0].isIntersecting &&
        columnData.workOrders?.length === 10 &&
        page > lastCalledPage
      ) {
        getWorkOrderInStatus();
      }
    });

    if (node) observer.current.observe(node);
  };

  useEffect(() => {
    if (columnData.workOrders) {
      if (columnData.workOrders.length > 0) {
        setWorkOrders(columnData.workOrders);
      }
    } else {
      setWorkOrders([]);
    }
    setPage(2);
    setLastCalledPage(0);
  }, [columnData, isSearching]);

  return (
    <PipelineBox>
      <PipelineTitle>{columnData.name}</PipelineTitle>
      <PipelinePaper>
        {pipelineLoading
          ? [1, 2, 3, 4, 5].map((item) => (
              <StyledSkeleton
                key={item}
                variant="rectangular"
                animation="pulse"
              />
            ))
          : workOrders.map((data: WorkOrderData, index) => {
              const isLastElement = workOrders.length === index + 1;
              return (
                <Link
                  to={`/home/property/${data?.id}/${columnData.link}`}
                  style={{ textDecoration: 'none' }}
                  key={data.id}
                  ref={
                    isLastElement && !isSearching ? lastElementRef : undefined
                  }
                >
                  <Card cardData={data} />
                </Link>
              );
            })}
        {loadingMore && (
          <Box sx={{ margin: '12px' }}>
            <LinearProgress />
          </Box>
        )}
      </PipelinePaper>
    </PipelineBox>
  );
}

/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, CircularProgress, Typography } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';

import { EvaluationType, Status } from '../../api/enumerations';
import { getSample } from '../../api/samples';
import { ElementData } from '../../api/samples/types';
import { GetWorkOrder } from '../../api/workOrders';
import { AvmStatusData, WorkOrderData } from '../../api/workOrders/types';
import { AllAttachments } from '../../components/AllAttachments';
import ConfirmationDeleteDialog from '../../components/ConfirmationDeleteDialog';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import { MessagesDialog } from '../../components/Messages';
import { PeptDialog } from '../../components/PeptDialog';
import { AccordionTitle } from '../../components/Sections/AccordionTitle';
import { ClientData } from '../../components/Sections/ClientData';
import { FormResponse } from '../../components/Sections/FormResponse';
import { PropertyAddressWithMaps } from '../../components/Sections/PropertyAddressWithMaps';
import { PropertyData } from '../../components/Sections/PropertyData';
import { PropertyInfo } from '../../components/Sections/PropertyInfo';
import { Rooms } from '../../components/Sections/Rooms';
import { SampleSection } from '../../components/Sections/Sample';
import { ScheduleInfo } from '../../components/Sections/ScheduleInfo';
import { Title } from '../../components/Sections/Title';
import { ToogleAccordion } from '../../components/Sections/ToogleAccordion';
import Snackbar from '../../components/Snackbar';
import {
  BoxContainer,
  CancelOsBox,
  FlexBox,
  FlexSpaceBetweenBox,
  LoadingBox,
} from '../../components/UI/Box';
import { GridContainer } from '../../components/UI/Grid';
import { Constants } from '../../constants/calculation';
import {
  IconApartmentMS,
  IconArrowCircleLeftMS,
  IconCalendarTodayMS,
  IconFactCheckMS,
  IconListAltMS,
  IconLocationCityMS,
  IconLocationOnMS,
  IconPhotoLibraryMS,
} from '../../constants/icons';
import { GlobalContext } from '../../context/global';
import { useAccordion } from '../../hooks/useAccordion';
import { useCancelWorkOrder } from '../../hooks/useCancelWorkOrder';
import { useChangeStatus } from '../../hooks/useChangeStatus';
import { useStoragedJwt } from '../../hooks/useDecodedJwt';
import useErrorMessage from '../../hooks/useErrorMessage';
import useGeneral from '../../hooks/useGeneral';
import { useRooms } from '../../hooks/useRooms';
import { ForceFactors } from './ForceFactors';
import {
  AvmStatusBox,
  BackButton,
  QueuePosition,
  SectionBox,
  SubmitBox,
} from './styles';

interface IAvmStatus {
  color: string;
  text: string;
}

export function Calculation(): JSX.Element {
  const [propertyData, setPropertyData] = useState<WorkOrderData>();
  const [sampleData, setSampleData] = useState<ElementData[]>();
  const [forceFactors, setForceFactors] = useState<string[]>([]);
  const [avmStatus, setAvmStatus] = useState<IAvmStatus>();
  const [queuePosition, setQueuePosition] = useState<string>();
  const [loadingPage, setLoadingPage] = useState(true);

  const isBank = process.env.REACT_APP_IS_BANK === 'true';

  const { openSnackbar, setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const { handleCancelWorkOrder } = useCancelWorkOrder();
  const { getErrorMessage } = useErrorMessage();
  const { handlePreviousStatus, loadingPreviousStatus } = useChangeStatus();
  const { osId, navigateHome } = useGeneral();
  const { handleRooms, rooms } = useRooms();
  const {
    expandOne,
    setExpandOne,
    expandTwo,
    setExpandTwo,
    expandThree,
    setExpandThree,
    expandFour,
    setExpandFour,
    expandFive,
    setExpandFive,
    expandSix,
    setExpandSix,
    expandSeven,
    setExpandSeven,
    expandAll,
    setExpandAll,
    toggleAccordions,
  } = useAccordion();
  const navigate = useNavigate();
  const decoded = useStoragedJwt();
  const { lastMessage } = useWebSocket(
    `${process.env.REACT_APP_API_WEBSOCKET_URL}/ws/${decoded?.user.id}`
  );

  useEffect(() => {
    if (lastMessage !== null) {
      const messageData = JSON.parse(lastMessage.data);
      setSnackbarMessage(
        `OS ${messageData.payload.reference_number} finalizou o cálculo.`
      );
      setErrorMessage(false);
      setOpenSnackbar(true);
      navigate(`/home/property/${osId}/report`);
    }
  }, [lastMessage]);

  useEffect(() => {
    if (
      expandOne &&
      expandTwo &&
      expandThree &&
      expandFour &&
      expandFive &&
      expandSix &&
      expandSeven
    ) {
      setExpandAll(true);
    } else {
      setExpandAll(false);
    }
  }, [
    expandOne,
    expandTwo,
    expandThree,
    expandFour,
    expandFive,
    expandSix,
    expandSeven,
  ]);

  const handleAvmStatus = (avmStatusData: AvmStatusData | null): void => {
    switch (avmStatusData?.status) {
      case 'failed':
        setAvmStatus({
          color: '#E80B25',
          text:
            avmStatusData?.failure_reason ||
            // eslint-disable-next-line max-len
            `Problema no envio para o cálculo, volte a OS e tente novamente. Se o erro persistir, entre em contato com o suporte e informe o ID: ${osId}.`,
        });
        break;
      case 'executing':
        setAvmStatus({
          color: '#28C40A',
          text: 'Calculando. Em média esse processo leva 40 minutos.',
        });
        break;
      default:
        if (
          avmStatusData?.status &&
          !Number.isNaN(Number(avmStatusData.status))
        ) {
          setQueuePosition(avmStatusData?.status);
        }
    }
  };

  const getDataCallback = useCallback(async () => {
    let getSamples = false;

    try {
      const response = await GetWorkOrder(osId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data || response.data.status !== Status.CALCULATION) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.data.samples) {
        getSamples = true;
      }

      if (response.data.force_factors) {
        setForceFactors(response.data.force_factors);
      }

      if (response.data.avm_status) {
        handleAvmStatus(response.data.avm_status);
      }

      if (response.data.inspection) {
        handleRooms(response.data.inspection.id);
      }

      setPropertyData(response.data);
      setLoadingPage(false);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error, true));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }

    if (getSamples) {
      try {
        const response = await getSample(osId, 1, 105);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (!response.data) {
          throw new Error('A amostra não pode ser carregada.');
        }

        setSampleData(response.data.items);
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setOpenSnackbar(true);
        setErrorMessage(true);
      }
    }
  }, []);

  useEffect(() => {
    getDataCallback();
  }, [getDataCallback]);

  const isFactors =
    propertyData?.evaluation_type === EvaluationType.SIMPFACTORS ||
    propertyData?.evaluation_type === EvaluationType.AUTOFACTORS;

  return (
    <GridContainer>
      <BackButton onClick={navigateHome}>{IconArrowCircleLeftMS}</BackButton>
      <BoxContainer>
        <Title
          osNumber={propertyData?.reference_number || 0}
          title={Constants.calculation}
          createdAt={propertyData?.created_at}
        />
        {loadingPage ? (
          <LoadingBox>
            <CircularProgress />
          </LoadingBox>
        ) : (
          <>
            <FlexSpaceBetweenBox>
              <CancelOsBox>
                <ConfirmationDeleteDialog
                  iconCancel
                  title={Constants.cancelOs}
                  text={Constants.cancelOsText}
                  modalCallback={handleCancelWorkOrder}
                />
              </CancelOsBox>
              <FlexBox>
                {queuePosition && (
                  <QueuePosition>
                    {Constants.queuePosition}
                    {queuePosition}
                  </QueuePosition>
                )}
                <Box display="flex" gap={1.2}>
                  <MessagesDialog osId={osId} />
                  <AllAttachments propertyData={propertyData} osId={osId} />
                </Box>{' '}
              </FlexBox>
            </FlexSpaceBetweenBox>
            {avmStatus && (
              <AvmStatusBox color={avmStatus.color}>
                {avmStatus.text}
                {propertyData?.avm_status?.failure_reason &&
                  Constants.avmFailedHint}
              </AvmStatusBox>
            )}
            <ClientData propertyData={propertyData} />
            <Box>
              <ToogleAccordion expand={expandAll} toogle={toggleAccordions} />
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyData}
                  icon={IconApartmentMS}
                  openAccordion={expandOne}
                  setOpenAccordion={setExpandOne}
                />
                {expandOne && <PropertyData propertyData={propertyData} />}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyAddress}
                  icon={IconLocationOnMS}
                  openAccordion={expandTwo}
                  setOpenAccordion={setExpandTwo}
                />
                {expandTwo && (
                  <PropertyAddressWithMaps
                    checkLocation
                    propertyData={propertyData}
                  />
                )}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyDetails}
                  icon={IconLocationCityMS}
                  openAccordion={expandThree}
                  setOpenAccordion={setExpandThree}
                />
                {expandThree && <PropertyInfo propertyData={propertyData} />}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.scheduleInfo}
                  icon={IconCalendarTodayMS}
                  openAccordion={expandFour}
                  setOpenAccordion={setExpandFour}
                />
                {expandFour && (
                  <Box>
                    {propertyData?.inspection ? (
                      <ScheduleInfo
                        inspectionData={propertyData.inspection}
                        rooms={rooms}
                      />
                    ) : (
                      <Typography>{Constants.noInspection}</Typography>
                    )}
                  </Box>
                )}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyPhotos}
                  icon={IconPhotoLibraryMS}
                  openAccordion={expandFive}
                  setOpenAccordion={setExpandFive}
                />
                {expandFive && (
                  <Box>
                    {propertyData?.inspection && rooms.length > 0 ? (
                      <Rooms
                        navigationPath={`${osId}/inspection/${propertyData.inspection.id}/rooms`}
                        rooms={rooms}
                        osId={osId}
                        inspectionId={propertyData.inspection.id}
                      />
                    ) : (
                      <Typography>{Constants.noPhotos}</Typography>
                    )}
                  </Box>
                )}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.form}
                  icon={IconListAltMS}
                  openAccordion={expandSix}
                  setOpenAccordion={setExpandSix}
                />
                {expandSix && (
                  <Box>
                    {propertyData?.inspection?.form_response ? (
                      <FormResponse
                        questionForm={propertyData.inspection.form_response}
                      />
                    ) : (
                      <Typography>{Constants.noForm}</Typography>
                    )}
                  </Box>
                )}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.sample}
                  icon={IconFactCheckMS}
                  openAccordion={expandSeven}
                  setOpenAccordion={setExpandSeven}
                />
                {expandSeven && (
                  <Box>
                    {sampleData && sampleData?.length > 0 ? (
                      <SampleSection
                        sampleData={sampleData}
                        propertyData={propertyData}
                      />
                    ) : (
                      <Typography>{Constants.noSample}</Typography>
                    )}
                  </Box>
                )}
              </SectionBox>
              {!isBank && (
                <SubmitBox>
                  <PeptDialog
                    osId={osId}
                    referenceNumber={propertyData?.reference_number}
                  />
                  <FlexBox>
                    <ConfirmationDialog
                      title={Constants.previousStatus}
                      text={Constants.previousStatusText.replace(
                        '**',
                        `${propertyData?.reference_number}`
                      )}
                      modalCallback={() =>
                        handlePreviousStatus(
                          osId,
                          propertyData?.reference_number,
                          Constants.previousStatusName
                        )
                      }
                      previous
                      loading={loadingPreviousStatus}
                    />
                    {isFactors && (
                      <ForceFactors
                        refNumber={propertyData?.reference_number}
                        factors={forceFactors}
                      />
                    )}
                  </FlexBox>
                </SubmitBox>
              )}
            </Box>
          </>
        )}
      </BoxContainer>
      {openSnackbar && <Snackbar />}
    </GridContainer>
  );
}

import {
  Accordion,
  Box,
  Button,
  Dialog,
  FormControlLabel,
  IconButton,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 30,
    backgroundColor: theme.palette.common.white,
    minHeight: 'auto',
    [theme.breakpoints.down('xl')]: {
      minWidth: 900,
    },
    [theme.breakpoints.up('xl')]: {
      minWidth: 1100,
    },
  },
  '& .MuiDialog-paperScrollPaper': {
    '&::-webkit-scrollbar': {
      width: 10,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#004D43',
      borderRadius: 10,
      '&:hover': {
        backgroundColor: '#004D43CC',
      },
    },
    '&::-webkit-scrollbar-track': {
      margin: '60px 0',
    },
  },
}));

export const DialogTitle = styled(Typography)(() => ({
  font: '700 21px Montserrat',
  textTransform: 'capitalize',
  color: '#595959',
  marginBottom: 24,
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  '& span': { color: '#00B39B' },
}));

export const TopBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const CloseButton = styled(IconButton)(() => ({
  margin: '-28px -28px 0 0',
  height: 'fit-content',
  '& span': {
    fontSize: '24px',
    fontWeight: '600',
    color: '#00B39B',
    '&:hover': {
      color: '#004D43',
    },
  },
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

export const BorderBox = styled(Box)(() => ({
  display: 'flex',
  margin: '40px 0',
  padding: '20px',
  borderRadius: '4px',
  border: '0.5px solid #8B8C8E',
}));

export const CustomAccordion = styled(Accordion)(() => ({
  backgroundColor: '#FAFCFF',
  boxShadow: 'none',
  minWidth: '100% !important',
  '&.Mui-expanded': {
    margin: '8px 0',
  },
  '&:before': {
    display: 'none',
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: '#06DFC2',
    marginLeft: '60px',
  },
  '& .MuiAccordionSummary-content': {
    zIndex: '100',
    margin: '0 !important',
    backgroundColor: 'transparent',
  },
  '& .MuiAccordionSummary-root': {
    minHeight: '40px',
    backgroundColor: 'transparent',
    '&.Mui-expanded': {
      backgroundColor: 'transparent',
    },
  },
  '& .MuiAccordionDetails-root': {
    padding: '8px 50px 0',
  },
}));

export const CheckboxFormControl = styled(FormControlLabel)(() => ({
  padding: '8px 0 0',
  position: 'relative',
  zIndex: '4000',
  marginLeft: '0',
  '& .MuiButtonBase-root': {
    padding: '4px',
  },
  '&:hover': {
    '& .MuiButtonBase-root': {
      color: '#06DFC2',
    },
    '& .MuiTypography-root': {
      fontWeight: '600',
    },
  },
  '& .MuiTypography-root': {
    fontWeight: '500',
    fontSize: '18px',
  },
}));

export const ButtonBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
}));

export const OutlinedButton = styled(Button)(() => ({
  width: '400px',
  marginTop: '20px',
  border: '2px solid #00B39B',
  borderRadius: '16px',
  color: '#00B39B',
  fontWeight: '600',
  backgroundColor: '#FAFCFF',
  lineHeight: 1.2,
  '&:hover': {
    backgroundColor: '#00B39B',
  },
  '&:active': {
    backgroundColor: '#00B39B',
  },
}));

/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Grid,
  LinearProgress,
  Rating,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { PatternFormat } from 'react-number-format';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { StatusCode } from '../../../../api/enumerations';
import {
  editLicensedCompany,
  getCompanyAbsences,
  getCompanyNotices,
  getLicensedCompany,
} from '../../../../api/theHive/licensedCompanies';
import {
  CompanyAbsences,
  CompanyNotices,
  LicensedCompanyData,
} from '../../../../api/theHive/licensedCompanies/types';
import { CustomTextField } from '../../../../components/CustomInput';
import { IconArrowCircleLeftMS } from '../../../../constants/icons';
import { GlobalContext } from '../../../../context/global';
import {
  formatCep,
  formatCnpjCpf,
  formatOnlyDate,
  formatPhone,
  formatPhoneNumber,
} from '../../../../helpers';
import useErrorMessage from '../../../../hooks/useErrorMessage';
import useSearchCep from '../../../../hooks/useSearchCep';
import ConfirmEditDialog from './ConfirmEditDialog';
import { EditNotice } from './EditNotice';
import { Observations } from './Observations';
import {
  BackButton,
  InputTitle,
  GridSection,
  SectionTitle,
  StyledInput,
  EditButton,
  Section,
  GridItem,
  Comment,
  CommentBox,
  StyledTextField,
  SubmitButton,
  SubmitBox,
  textFiledDefaultStyle,
  PatternFormatStyle,
  StyledName,
} from './styles';
import { TableInfo } from './Table';
import {
  presentAbsenceTableValues,
  presentHistoryTableHead,
  presentNoticeTableHead,
  presentNoticeTableValues,
} from './Table/presenter';

export interface IRatingsData {
  id: number;
  client_name: string;
  comment: string;
  date: string;
}

export function CompanyInfo(): JSX.Element {
  const [enableEdition, setEnableEdition] = useState(false);
  const [openEditNotice, setOpenEditNotice] = useState(false);
  const [noticeData, setNoticeData] = useState<CompanyNotices | undefined>();
  const [resetTable, setResetTable] = useState<boolean>(false);

  const [companyData, setCompanyData] = useState<LicensedCompanyData>();
  const [companyName, setCompanyName] = useState('');
  const [registrationDate, setRegistrationDate] = useState<Dayjs | null>(null);
  const [licenseDocument, setLicenseDocument] = useState('');
  const [responsible, setResponsible] = useState('');
  const [document, setDocument] = useState('');
  const [contact, setContact] = useState('');
  const [email, setEmail] = useState('');
  const [cep, setCep] = useState('');
  const [cepError, setCepError] = useState(false);
  const [address, setAddress] = useState('');
  const [addressNumber, setAddressNumber] = useState('');
  const [addressComplement, setAddressComplement] = useState('');
  const [district, setDistrict] = useState('');
  const [city, setCity] = useState('');
  const [uf, setUf] = useState('');

  const ratingsData: IRatingsData[] = [];

  const navigate = useNavigate();
  const location = useLocation();
  const basePath = location.pathname.split('/company-details')[0];
  const { company } = useParams();
  const companyId = parseInt(company as string, 10);

  const { getErrorMessage } = useErrorMessage();
  const { handleSearchCep } = useSearchCep();

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const handleOpenEdit = (data?: CompanyNotices): void => {
    setOpenEditNotice(true);
    setNoticeData(data);
  };
  const handleCloseEdit = (): void => setOpenEditNotice(false);

  const navigateBack = (): void => {
    navigate(basePath);
  };

  const editToggle = (): void => setEnableEdition(!enableEdition);

  const getDataCallback = useCallback(async () => {
    try {
      const response = await getLicensedCompany(companyId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setCompanyData(response.data);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      navigateBack();
    }
  }, []);

  useEffect(() => {
    getDataCallback();
  }, [getDataCallback]);

  const verifyCep = async (cep: string): Promise<void> => {
    const addressData = await handleSearchCep(cep);
    if (addressData) {
      setCep(addressData.cep);
      setUf(addressData.uf);
      setCity(addressData.city);
      setAddress(addressData.address);
      setCepError(false);
    } else {
      setCepError(true);
    }
  };

  const handleEditCompany = async (): Promise<void> => {
    const data = {
      is_active: !companyData?.is_active,
    };

    try {
      const response = await editLicensedCompany(companyId, data);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage(
        `A empresa foi ${companyData?.is_active ? 'inativada' : 'ativada'}!`
      );
      setErrorMessage(false);
      setOpenSnackbar(true);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  return (
    <Box>
      {companyData ? (
        <>
          <BackButton onClick={navigateBack}>
            {IconArrowCircleLeftMS}
          </BackButton>
          <GridSection container>
            <Grid item display="flex" gap="8px" xs={4}>
              <Typography fontSize={21}>Situação:</Typography>
              <SectionTitle>
                {companyData.is_active ? 'Ativa' : 'Inativa'}
              </SectionTitle>
            </Grid>
            <Grid item xs={5} />
            <Grid item xs={3}>
              <ConfirmEditDialog
                title={`${companyData.is_active ? 'Inativar' : 'Ativar'}`}
                text={`${
                  companyData.is_active ? 'inativar' : 'ativar'
                } a empresa`}
                modalCallback={handleEditCompany}
              />
            </Grid>
          </GridSection>
          <GridSection container>
            {enableEdition ? (
              <Grid item xs={3} pr="10px">
                <CustomTextField
                  id="company-name"
                  label="nome da empresa"
                  value={companyName}
                  setValue={setCompanyName}
                />
              </Grid>
            ) : (
              <Grid item xs={3}>
                <SectionTitle>{companyData.company.name}</SectionTitle>
              </Grid>
            )}
            <Grid item xs={6} />
            {enableEdition ? (
              <Grid item xs={3}>
                <EditButton onClick={editToggle}>
                  {enableEdition ? 'Cancelar edição' : 'Editar'}
                </EditButton>
              </Grid>
            ) : (
              <Grid item xs={3} />
            )}
            {enableEdition ? (
              <>
                <Grid container columnSpacing={2}>
                  <GridItem item xs={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="data do credenciamento"
                        value={registrationDate}
                        format="DD-MM-YYYY"
                        slotProps={{
                          textField: {
                            sx: textFiledDefaultStyle,
                          },
                        }}
                        onChange={(e) => setRegistrationDate(e)}
                      />
                    </LocalizationProvider>
                  </GridItem>
                  <Grid item xs={9} />
                  <GridItem item xs={3}>
                    <CustomTextField
                      id="cnpj/cpf"
                      label="CPNJ/CPF"
                      value={licenseDocument}
                      setValue={setLicenseDocument}
                    />
                  </GridItem>
                  <GridItem item xs={3}>
                    <CustomTextField
                      id="responsible"
                      label="responsável"
                      value={responsible}
                      setValue={setResponsible}
                    />
                  </GridItem>
                  <GridItem item xs={3}>
                    <CustomTextField
                      id="document"
                      label="CREA/CAU"
                      value={document}
                      setValue={setDocument}
                    />
                  </GridItem>
                  <GridItem item xs={3}>
                    <TextField
                      id="contact"
                      label="contato"
                      color="secondary"
                      inputProps={{ maxLength: 15 }}
                      value={contact}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setContact(formatPhone(e.target.value))
                      }
                      sx={PatternFormatStyle}
                    />
                  </GridItem>
                  <GridItem item xs={3}>
                    <CustomTextField
                      id="email"
                      label="email"
                      value={email}
                      setValue={setEmail}
                    />
                  </GridItem>
                  <GridItem item xs={3}>
                    <PatternFormat
                      format="#####-###"
                      mask="_"
                      customInput={StyledTextField}
                      id="cep"
                      label="CEP"
                      color="secondary"
                      value={cep}
                      onInvalid={(e: ChangeEvent<HTMLInputElement>) =>
                        e.target.setCustomValidity('Preencha este campo')
                      }
                      onInput={(e: ChangeEvent<HTMLInputElement>) =>
                        e.target.setCustomValidity('')
                      }
                      error={cepError}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setCep(e.target.value);
                        verifyCep(e.target.value);
                      }}
                    />
                  </GridItem>
                  <GridItem item xs={3}>
                    <CustomTextField
                      id="address"
                      label="logradouro"
                      value={address}
                      setValue={setAddress}
                    />
                  </GridItem>
                  <GridItem item xs={3}>
                    <CustomTextField
                      id="address-number"
                      label="número"
                      value={addressNumber}
                      setValue={setAddressNumber}
                    />
                  </GridItem>
                  <GridItem item xs={3}>
                    <CustomTextField
                      id="address-complement"
                      label="complemento"
                      value={addressComplement}
                      setValue={setAddressComplement}
                    />
                  </GridItem>
                  <GridItem item xs={3}>
                    <CustomTextField
                      id="district"
                      label="bairro"
                      value={district}
                      setValue={setDistrict}
                    />
                  </GridItem>
                  <GridItem item xs={3}>
                    <CustomTextField
                      id="city"
                      label="cidade"
                      value={city}
                      setValue={setCity}
                    />
                  </GridItem>
                  <GridItem item xs={3}>
                    <CustomTextField
                      id="uf"
                      label="estado"
                      value={uf}
                      setValue={setUf}
                    />
                  </GridItem>
                </Grid>
                <SubmitBox>
                  <SubmitButton>salvar</SubmitButton>
                </SubmitBox>
              </>
            ) : (
              <>
                <GridItem item xs={12}>
                  <InputTitle>Data do credenciamento:</InputTitle>
                  <StyledInput>
                    {formatOnlyDate(companyData.licensed_date)}
                  </StyledInput>
                </GridItem>
                <GridItem item xs={4} xl={2.1}>
                  <InputTitle>
                    {companyData.company.document_type?.toUpperCase() || 'CPF'}:
                  </InputTitle>
                  <StyledInput>
                    {formatCnpjCpf(companyData.company.document)}
                  </StyledInput>
                </GridItem>
                <GridItem item xs={5} xl={3}>
                  <Tooltip
                    title={
                      companyData?.company?.responsible_user?.name !== null &&
                      companyData?.company?.responsible_user?.name.length > 20
                        ? companyData?.company?.responsible_user?.name
                        : ''
                    }
                    placement="top"
                    arrow
                    enterDelay={500}
                    leaveDelay={200}
                  >
                    <Grid item container>
                      <InputTitle style={{ marginRight: 7 }}>
                        Responsável:
                      </InputTitle>
                      <StyledInput>
                        {companyData?.company?.responsible_user?.name !==
                          null &&
                        companyData?.company?.responsible_user?.name.length > 20
                          ? `${companyData?.company?.responsible_user?.name.slice(
                              0,
                              20
                            )}...`
                          : companyData?.company?.responsible_user?.name || '-'}
                      </StyledInput>
                    </Grid>
                  </Tooltip>
                </GridItem>
                <GridItem item xs={3} xl={2}>
                  <InputTitle>CREA/CAU:</InputTitle>
                  <StyledInput>
                    {companyData.company.license_document}
                  </StyledInput>
                </GridItem>
                <GridItem item xs={4} xl={2.3}>
                  <InputTitle>Telefone:</InputTitle>
                  <StyledInput>
                    {formatPhoneNumber(companyData.company.phone_number)}
                  </StyledInput>
                </GridItem>
                <GridItem item xs={8} xl={2.5}>
                  <InputTitle>Email:</InputTitle>
                  <StyledInput>{companyData.company.email}</StyledInput>
                </GridItem>
                <GridItem item xs={4} xl={2.1}>
                  <InputTitle>CEP:</InputTitle>
                  <StyledInput>
                    {formatCep(companyData.company.zipcode)}
                  </StyledInput>
                </GridItem>
                <GridItem item xs={5} xl={7.3}>
                  <Tooltip
                    title={
                      `${companyData.company.address}, ${companyData.company.address_number}`
                        .length > (window.innerWidth > 1600 ? 35 : 20)
                        ? `${companyData.company.address}, ${companyData.company.address_number}`
                        : ''
                    }
                    placement="top"
                    arrow
                    enterDelay={500}
                    leaveDelay={200}
                  >
                    <Grid item container>
                      <InputTitle
                        style={{
                          marginRight: 7,
                        }}
                      >
                        Logradouro:
                      </InputTitle>
                      <StyledInput>
                        {`${companyData.company.address}, ${companyData.company.address_number}`
                          .length > (window.innerWidth > 1600 ? 35 : 20)
                          ? `${companyData.company.address.slice(
                              0,
                              window.innerWidth > 1600 ? 35 : 20
                            )}...`
                          : `${companyData.company.address}, ${companyData.company.address_number}`}
                      </StyledInput>
                    </Grid>
                  </Tooltip>
                </GridItem>
                <GridItem item xs={3} xl={2.4}>
                  <InputTitle>Cidade:</InputTitle>
                  <StyledInput>
                    {`${companyData.company.city} - ${companyData.company.uf}`}
                  </StyledInput>
                </GridItem>
              </>
            )}
          </GridSection>
          <Section>
            <SectionTitle>Editais</SectionTitle>
            <TableInfo<CompanyNotices>
              presentTableHead={presentNoticeTableHead}
              presentTableValues={presentNoticeTableValues}
              dataCallback={getCompanyNotices}
              companyId={companyId}
              type="edital"
              action={handleOpenEdit}
              resetTable={resetTable}
            />
          </Section>
          <Section>
            <SectionTitle>Histórico de afastamentos</SectionTitle>
            <TableInfo<CompanyAbsences>
              presentTableHead={presentHistoryTableHead}
              presentTableValues={presentAbsenceTableValues}
              dataCallback={getCompanyAbsences}
              companyId={companyId}
              type="afastamento"
              downloadIcon
            />
          </Section>
          <Section>
            <SectionTitle>Avaliações</SectionTitle>
            <Rating
              value={companyData.rating}
              readOnly
              sx={{ marginTop: '16px' }}
              size="large"
            />
            <CommentBox minHeight="70px">
              {ratingsData.length > 0 ? (
                ratingsData.map((rating) => (
                  <Comment key={rating.id}>
                    <Typography fontSize={12}>{rating.date}</Typography>
                    <StyledName>
                      {rating.client_name}
                      <Rating value={2} readOnly size="small" />
                    </StyledName>
                    <Typography>{rating.comment}</Typography>
                  </Comment>
                ))
              ) : (
                <Typography>sem avaliações</Typography>
              )}
            </CommentBox>
          </Section>
          <Section>
            <SectionTitle>Observações</SectionTitle>
            <Observations />
          </Section>
        </>
      ) : (
        <LinearProgress />
      )}
      {openEditNotice && noticeData && (
        <EditNotice
          companyId={companyId}
          noticeData={noticeData}
          open={openEditNotice}
          handleClose={handleCloseEdit}
          resetTable={resetTable}
          setResetTable={setResetTable}
        />
      )}
    </Box>
  );
}

/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
} from '@mui/material';
import { ChangeEvent, Fragment, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { StatusCode } from '../../../../../api/enumerations';
import {
  getCompany,
  licenseCompany,
} from '../../../../../api/theHive/licensedCompanies';
import { LicensedCompany } from '../../../../../api/theHive/licensedCompanies/types';
import { getNoticeCoverage } from '../../../../../api/theHive/noticeCoverage';
import { CoverageData } from '../../../../../api/theHive/noticeCoverage/types';
import { addLicense } from '../../../../../api/theHive/notices';
import { getTypologyGroups } from '../../../../../api/theHive/typology';
import { ListTypologyData } from '../../../../../api/theHive/typology/types';
import StandardMap from '../../../../../components/Maps/StandardMap';
import {
  IconAddMS,
  IconArrowCircleLeftMS,
} from '../../../../../constants/icons';
import { GlobalContext } from '../../../../../context/global';
import { cleanValue, formatCnpjCpf } from '../../../../../helpers';
import useErrorMessage from '../../../../../hooks/useErrorMessage';
import { CompanyData } from './CompanyData';
import { NewCompany } from './NewCompany';
import {
  BackButton,
  GridSection,
  SectionTitle,
  GridItem,
  OutlinedButton,
  AddButton,
  StyledTextField,
  StyledSelect,
} from './styles';

type LatLngLiteral = google.maps.LatLngLiteral;

export function AddCompany(): JSX.Element {
  const [licenseDocument, setLicenseDocument] = useState('');
  const [addNewCompany, setAddNewCompany] = useState(false);
  const [loading, setLoading] = useState(false);

  const [companyData, setCompanyData] = useState<LicensedCompany | undefined>();
  const [searchMap, setSearchMap] = useState(true);
  const [pinPlace, setPinPlace] = useState<LatLngLiteral>({
    lat: -23.56162,
    lng: -46.65591,
  });

  const [allowCoverages, setAllowCoverages] = useState(false);
  const [licensedCompanyId, setLicensedCompanyId] = useState<
    number | undefined
  >();
  const [mainRegions, setMainRegions] = useState<CoverageData[]>([]);
  const [selectedRegions, setSelectedRegions] = useState<number[]>([]);
  const [totalRegions, setTotalRegions] = useState<number>(0);
  const [totalSelectedRegions, setTotalSelectedRegions] = useState<number[]>([
    0,
  ]);
  const [typologies, setTypologies] = useState<ListTypologyData[]>([]);
  const [selectedTypologies, setSelectedTypologies] = useState<number[]>([]);
  const [totalTypologies, setTotalTypologies] = useState<number>(0);
  const [totalSelectedTypologies, setTotalSelectedTypologies] = useState<
    number[]
  >([0]);

  const { getErrorMessage } = useErrorMessage();
  const navigate = useNavigate();
  const location = useLocation();
  const basePath = location.pathname.split('/add-company')[0];
  const { notice } = useParams();
  const noticeId = parseInt(notice as string, 10);

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const handleLicenseSearch = async (): Promise<void> => {
    const document = cleanValue(licenseDocument);

    if (document.length < 11) {
      setSnackbarMessage('Preencha um CNPJ ou CPF.');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }

    setAddNewCompany(false);
    setCompanyData(undefined);
    setLoading(true);

    try {
      const response = await getCompany(document);

      if (response.detail.status_code === 4) {
        setAddNewCompany(false);
        throw new Error('Empresa não encontrada.');
      }

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      setCompanyData(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const handleLicenseCompany = async (): Promise<void> => {
    if (!companyData) {
      setSnackbarMessage('Algo deu errado, tente novamente.');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }

    const companyId = {
      worker_company_id: companyData.id,
    };

    try {
      const response = await licenseCompany(companyId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.data) {
        setAllowCoverages(true);
        setLicensedCompanyId(response.data.licensed_company_id);
      }

      setSnackbarMessage('Empresa credenciada com sucesso!');
      setErrorMessage(false);
      setOpenSnackbar(true);
    } catch (error) {
      setLoading(false);
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const handleNoticeCoverage = async (): Promise<void> => {
    try {
      const response = await getNoticeCoverage(noticeId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setMainRegions(response.data.main_regions);
      setTotalRegions(response.data.main_regions.length);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const handleTypologyGroups = async (): Promise<void> => {
    try {
      const response = await getTypologyGroups(noticeId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setTypologies(response.data);
      setTotalTypologies(response.data.length);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  useEffect(() => {
    if (licensedCompanyId) {
      handleNoticeCoverage();
      handleTypologyGroups();
    }
  }, [licensedCompanyId]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (companyData) {
        setSearchMap(!searchMap);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [companyData]);

  const handleCompanyNotice = async (): Promise<void> => {
    if (!licensedCompanyId) return;
    const data = {
      licensed_company_id: licensedCompanyId,
      typology_groups_ids: selectedTypologies,
      notice_coverages_ids: selectedRegions,
    };

    try {
      const response = await addLicense(noticeId, data);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage(`Alterações realizadas com sucesso!`);
      setErrorMessage(false);
      setOpenSnackbar(true);
      navigate(basePath);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <BackButton onClick={() => navigate(basePath)}>
          {IconArrowCircleLeftMS}
        </BackButton>
      </Grid>
      <Grid item xs={12}>
        <SectionTitle mb="12px">Adicionar empresa</SectionTitle>
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          color="secondary"
          id="cnpj/cpf"
          label="CPNJ/CPF"
          inputProps={{ maxLength: '18' }}
          value={licenseDocument}
          onInvalid={(e: ChangeEvent<HTMLInputElement>) =>
            e.target.setCustomValidity('Preencha este campo')
          }
          onInput={(e: ChangeEvent<HTMLInputElement>) =>
            e.target.setCustomValidity('')
          }
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setLicenseDocument(formatCnpjCpf(e.target.value));
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <OutlinedButton onClick={handleLicenseSearch}>
          verificar documento
        </OutlinedButton>
      </Grid>
      {loading && (
        <Grid item xs={12} mt={10}>
          <LinearProgress />
        </Grid>
      )}
      {companyData && (
        <>
          <Grid item xs={7}>
            <CompanyData
              company={companyData}
              handleCompany={handleLicenseCompany}
            />
          </Grid>
          <Grid item xs={5}>
            <GridSection container m="20px 0">
              <Grid item xs={12}>
                <StandardMap
                  searchMap={searchMap}
                  address={`${companyData.address} ${companyData.address_number} ${companyData.address_district} ${companyData.city}`}
                  pinPlace={pinPlace}
                  setPinPlace={setPinPlace}
                  draggable={false}
                />
              </Grid>
              {totalSelectedRegions.map((option, ind) => (
                <Fragment key={option}>
                  <GridItem item xs={8} xl={6}>
                    <FormControl fullWidth>
                      <InputLabel id="coverage-label">polos</InputLabel>
                      <StyledSelect
                        labelId="coverage-label"
                        id="coverage"
                        label="polos"
                        value={selectedRegions[ind] || ''}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => {
                          const newSelectedRegions = [...selectedRegions];
                          newSelectedRegions[ind] = Number(e.target.value);
                          setSelectedRegions(newSelectedRegions);
                        }}
                        displayEmpty
                        fullWidth
                        disabled={!allowCoverages}
                      >
                        {mainRegions.length > 0 &&
                          mainRegions.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.city.name}
                            </MenuItem>
                          ))}
                      </StyledSelect>
                    </FormControl>
                  </GridItem>
                  <GridItem item xs={2} xl={4} />
                </Fragment>
              ))}
              <GridItem item xs={2}>
                <AddButton
                  disabled={
                    !allowCoverages ||
                    totalSelectedRegions.length >= totalRegions ||
                    selectedRegions.length < totalSelectedRegions.length
                  }
                  onClick={() =>
                    setTotalSelectedRegions([
                      ...totalSelectedRegions,
                      totalSelectedRegions.length + 1,
                    ])
                  }
                >
                  {IconAddMS}
                </AddButton>
              </GridItem>
              {totalSelectedTypologies.map((option, ind) => (
                <Fragment key={option}>
                  <GridItem item xs={8} xl={6}>
                    <FormControl fullWidth>
                      <InputLabel id="typology-label">tipologias</InputLabel>
                      <StyledSelect
                        labelId="typology-label"
                        id="typology"
                        label="tipologias"
                        value={selectedTypologies[ind] || ''}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => {
                          const newSelectedTypologies = [...selectedTypologies];
                          newSelectedTypologies[ind] = Number(e.target.value);
                          setSelectedTypologies(newSelectedTypologies);
                        }}
                        displayEmpty
                        fullWidth
                        disabled={!allowCoverages}
                      >
                        {typologies.length > 0 &&
                          typologies.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.name}
                            </MenuItem>
                          ))}
                      </StyledSelect>
                    </FormControl>
                  </GridItem>
                  <GridItem item xs={2} xl={4} />
                </Fragment>
              ))}
              <GridItem item xs={2}>
                <AddButton
                  disabled={
                    !allowCoverages ||
                    totalSelectedTypologies.length >= totalTypologies ||
                    selectedTypologies.length < totalSelectedTypologies.length
                  }
                  onClick={() =>
                    setTotalSelectedTypologies([
                      ...totalSelectedTypologies,
                      totalSelectedTypologies.length + 1,
                    ])
                  }
                >
                  {IconAddMS}
                </AddButton>
              </GridItem>
              <GridItem item xs={12}>
                <Button
                  onClick={handleCompanyNotice}
                  disabled={!allowCoverages}
                >
                  adicionar polos e tipologias
                </Button>
              </GridItem>
            </GridSection>
          </Grid>
        </>
      )}
      {addNewCompany && (
        <NewCompany
          licenseDocument={licenseDocument}
          setLicenseDocument={setLicenseDocument}
          setAddNewCompany={setAddNewCompany}
        />
      )}
    </Grid>
  );
}

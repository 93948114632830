import { BaseUrl, myHeadersWithTokenContentType } from '../../global';
import {
  TypologyGroupData,
  TypologyData,
  DetailProps,
  GetTypologyGroupsResponse,
} from './types';

export const createTypologyGroup = (
  data: TypologyGroupData
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };

  return fetch(`${BaseUrl}/api/V1/typology-groups`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const getTypologyGroups = (
  noticeId: number
): Promise<GetTypologyGroupsResponse> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };

  return fetch(
    `${BaseUrl}/api/V1/public-notices/${noticeId}/typology-groups`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const deleteTypologyGroup = (id: number): Promise<DetailProps> => {
  const requestOptions = {
    method: 'DELETE',
    headers: myHeadersWithTokenContentType(),
  };

  return fetch(`${BaseUrl}/api/V1/typology-groups/${id}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const updateTypologyGroup = (
  id: number,
  data: Partial<TypologyGroupData['typology_groups'][0]>
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'PATCH',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };

  return fetch(`${BaseUrl}/api/V1/typology-groups/${id}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const createTypology = (
  groupId: number,
  data: TypologyData
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };

  return fetch(
    `${BaseUrl}/api/V1/typology-groups/${groupId}/typologies`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const deleteTypology = (id: number): Promise<DetailProps> => {
  const requestOptions = {
    method: 'DELETE',
    headers: myHeadersWithTokenContentType(),
  };

  return fetch(`${BaseUrl}/api/V1/typology-groups/${id}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const updateTypology = (
  id: number,
  data: Partial<TypologyData>
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'PATCH',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };

  return fetch(`${BaseUrl}/api/V1/typologies/${id}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

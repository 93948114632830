import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { NoticeData } from '../../../../../api/theHive/notices/types';
import { convertDate } from '../../../../../helpers';
import {
  StyledCell,
  StyledPagination,
  StyledRow,
  TableCellHeader,
} from './styles';

interface NoticesTableProps {
  tableData: NoticeData[];
  page: number;
  setPage: (value: number) => void;
  totalPages: number;
}

export function NoticesTable({
  tableData,
  page,
  setPage,
  totalPages,
}: NoticesTableProps): JSX.Element {
  const navigate = useNavigate();

  const handleNavigate = (companyId: number): void => {
    navigate(`./${companyId}`);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ): void => {
    setPage(value);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCellHeader>nome</TableCellHeader>
              <TableCellHeader>anúncio do edital</TableCellHeader>
              <TableCellHeader>grupo de tipologias</TableCellHeader>
              <TableCellHeader>observação</TableCellHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((data) => (
              <StyledRow key={data.id} onClick={() => handleNavigate(data.id)}>
                <StyledCell>{data.name}</StyledCell>
                <StyledCell>{convertDate(data.announcement_date)}</StyledCell>
                <StyledCell>-</StyledCell>
                <StyledCell>{data.observations}</StyledCell>
              </StyledRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <StyledPagination
        page={page}
        count={totalPages}
        onChange={handlePageChange}
      />
    </>
  );
}

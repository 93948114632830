/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Rating,
  Typography,
} from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { StatusCode } from '../../../../../../api/enumerations';
import {
  addObservation,
  getObservations,
} from '../../../../../../api/theHive/licensedCompanies';
import {
  LicensedCompanyData,
  ObservationData,
} from '../../../../../../api/theHive/licensedCompanies/types';
import { ButtonBox } from '../../../../../../components/UI/Box';
import { GlobalContext } from '../../../../../../context/global';
import {
  formatCep,
  formatCnpjCpf,
  formatDate,
  formatDateAndTime,
  formatPhoneNumber,
} from '../../../../../../helpers';
import useErrorMessage from '../../../../../../hooks/useErrorMessage';
import { LicensedAttachments } from '../Attachments';
import {
  ActiveStatus,
  Bold,
  Comment,
  CommentBox,
  CompanyDescription,
  DescriptionContainer,
  HeaderContainer,
  HeaderDescription,
  MenuButton,
  NoticeContainer,
  ObservationBox,
  OutlinedButton,
  StyledBox,
  StyledImage,
  StyledName,
  StyledTextField,
} from './styles';

export interface IRatingsData {
  id: number;
  client_name: string;
  comment: string;
  date: string;
}

interface SelectedCompanyProps {
  selectedCompany: LicensedCompanyData;
}

export function SelectedCompany({
  selectedCompany,
}: SelectedCompanyProps): JSX.Element {
  const [anchorMenu, setAnchorMenu] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorMenu);
  const { company } = selectedCompany;
  const ratingsData: IRatingsData[] = [];
  const [observation, setObservation] = useState('');
  const [observations, setObservations] = useState<ObservationData[]>([]);
  const [updateObservations, setUpdateObservations] = useState(false);
  const [page, setPage] = useState(1);
  const [lastCalledPage, setLastCalledPage] = useState(0);
  const filesPerPage = 10;

  const { getErrorMessage } = useErrorMessage();
  const navigate = useNavigate();

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const closeMenu = (): void => setAnchorMenu(null);
  const openMenu = (e: React.MouseEvent<HTMLButtonElement>): void =>
    setAnchorMenu(e.currentTarget);

  const getDataCallback = useCallback(async () => {
    if (page === lastCalledPage) return;

    try {
      const response = await getObservations(
        selectedCompany.id,
        page,
        filesPerPage
      );

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setObservations([...observations, ...response.data]);
      setLastCalledPage(page);
      setUpdateObservations(false);

      if (response.detail.total_pages && response.detail.total_pages > page) {
        setPage(page + 1);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  }, [page, lastCalledPage]);

  useEffect(() => {
    getDataCallback();
  }, [getDataCallback]);

  useEffect(() => {
    if (updateObservations) {
      setLastCalledPage(0);
      setPage(1);
      setObservation('');
      setObservations([]);
      getDataCallback();
    }
  }, [updateObservations]);

  const handleObservation = async (): Promise<void> => {
    if (!observation) {
      setSnackbarMessage('Adicione uma observação!');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }

    const data = {
      contents: observation,
    };

    try {
      const response = await addObservation(selectedCompany.id, data);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setUpdateObservations(true);
      setSnackbarMessage('Observação adicionada com sucesso!');
      setErrorMessage(false);
      setOpenSnackbar(true);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  return (
    <CompanyDescription>
      <HeaderContainer>
        <HeaderDescription>
          <StyledImage image={company.logo_url} title={company.name} />
          {company.name}
        </HeaderDescription>
        <NoticeContainer>
          {selectedCompany.is_active ? (
            <ActiveStatus color="#06DFC2">ativo</ActiveStatus>
          ) : (
            <ActiveStatus>inativo</ActiveStatus>
          )}
          <MenuButton
            onClick={openMenu}
            disabled={selectedCompany.public_notices.length < 1}
          >
            editais
          </MenuButton>
          <Menu anchorEl={anchorMenu} open={isMenuOpen} onClose={closeMenu}>
            {selectedCompany.public_notices.map((notice) => (
              <MenuItem
                key={notice.id}
                onClick={() => navigate(`./${notice.id}`)}
              >
                {notice.name}
              </MenuItem>
            ))}
          </Menu>
        </NoticeContainer>
      </HeaderContainer>
      <Divider sx={{ width: '100%' }} />
      <DescriptionContainer mt="16px">
        <Grid container spacing={2}>
          <Grid display="flex" item xs={5}>
            {company.document_type?.toUpperCase() || 'CPF'}:
            <Bold>{formatCnpjCpf(company.document)}</Bold>
          </Grid>
          <Grid display="flex" item xs={7}>
            Data do credenciamento:{' '}
            <Bold>{formatDate(selectedCompany.licensed_date)}</Bold>
          </Grid>
          <Grid display="flex" item xs={5}>
            Telefone: <Bold>{formatPhoneNumber(company.phone_number)}</Bold>
          </Grid>
          <Grid display="flex" item xs={7}>
            Email: <Bold>{company.email}</Bold>
          </Grid>
          <Grid display="flex" item xs={5}>
            CEP: <Bold>{formatCep(company.zipcode)}</Bold>
          </Grid>
          <Grid display="flex" item xs={7}>
            Cidade: <Bold>{`${company.city} - ${company.uf} `}</Bold>
          </Grid>
          <Grid display="flex" item xs={12}>
            Logradouro:{' '}
            <Bold>
              {`${company.address}, ${company.address_number} - ${company.address_district}`}
            </Bold>
          </Grid>
          <Grid display="flex" justifyContent="center" item xs={12}>
            <LicensedAttachments companyId={selectedCompany.id} />
          </Grid>
        </Grid>
      </DescriptionContainer>
      <StyledBox>
        <Rating value={selectedCompany.rating} readOnly size="large" />
        <CommentBox>
          {ratingsData.length > 0 ? (
            ratingsData.map((rating) => (
              <Comment key={rating.id}>
                <Typography fontSize={12}>{rating.date}</Typography>
                <StyledName>
                  {rating.client_name}
                  <Rating value={2} readOnly size="small" />
                </StyledName>
                <Typography>{rating.comment}</Typography>
              </Comment>
            ))
          ) : (
            <Typography>sem avaliações</Typography>
          )}
        </CommentBox>
      </StyledBox>
      <StyledBox>
        <ObservationBox>
          {observations.length > 0 ? (
            observations.map((obs) => (
              <CommentBox key={obs.id} sx={{ margin: '0 0 16px' }}>
                <Comment>
                  <Box display="flex" gap="10px" alignItems="center">
                    <Typography fontSize={12}>
                      {formatDateAndTime(obs.created_at)}
                    </Typography>
                  </Box>
                  <StyledName />
                  <Typography>{obs.contents}</Typography>
                </Comment>
              </CommentBox>
            ))
          ) : (
            <Typography mb="20px">sem observações</Typography>
          )}
        </ObservationBox>
        <StyledTextField
          id="bank-observation"
          label="observações"
          value={observation}
          multiline
          rows={2}
          inputProps={{ maxLength: 280 }}
          color="secondary"
          onChange={(e) => {
            setObservation(e.target.value);
          }}
        />
        <ButtonBox>
          <OutlinedButton onClick={handleObservation}>enviar</OutlinedButton>
        </ButtonBox>
      </StyledBox>
      <OutlinedButton
        sx={{ width: '90%' }}
        onClick={() => navigate(`./company-details/${selectedCompany.id}`)}
      >
        ver mais informações
      </OutlinedButton>
    </CompanyDescription>
  );
}

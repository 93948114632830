/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@mui/material';
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { StatusCode } from '../../../../../../api/enumerations';
import {
  addAttachment,
  deleteAttachment,
  getAllAttachedFiles,
} from '../../../../../../api/theHive/licensedCompanies';
import { AttachmentData } from '../../../../../../api/theHive/licensedCompanies/types';
import { CustomTextField } from '../../../../../../components/CustomInput';
import {
  IconAttachFileMS,
  IconCloseMS,
  IconDeleteMS,
  IconDownloadMS,
  IconFolderOpenMS,
} from '../../../../../../constants/icons';
import { GlobalContext } from '../../../../../../context/global';
import { validateFiles } from '../../../../../../helpers';
import useErrorMessage from '../../../../../../hooks/useErrorMessage';
import useGeneral from '../../../../../../hooks/useGeneral';
import { Constants } from './constants';
import {
  AttachFileButon,
  AttachmentsBox,
  CancelButton,
  CloseButton,
  DateTypography,
  DialogTitle,
  FileContainer,
  FileList,
  FileNameTypography,
  InputBox,
  OutlinedButton,
  StyledDialog,
  StyledIcon,
  SubmitBox,
  SubmitButton,
  TopBox,
  UploadBox,
} from './styles';

interface LicensedAttachments {
  companyId: number;
}

export function LicensedAttachments({
  companyId,
}: LicensedAttachments): JSX.Element {
  const [open, setOpen] = useState(false);
  const [description, setDescription] = useState('');
  const [fileName, setFileName] = useState('');
  const [fileAttached, setFileAttached] = useState<File | string>();
  const [files, setFiles] = useState<AttachmentData[]>([]);
  const [updateFiles, setUpdateFiles] = useState(false);
  const [page, setPage] = useState(1);
  const [lastCalledPage, setLastCalledPage] = useState(0);
  const filesPerPage = 10;

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const { getErrorMessage } = useErrorMessage();
  const { handleDownloadFile } = useGeneral();

  const handleOpen = (): void => setOpen(true);
  const handleClose = (): void => setOpen(false);

  const getDataCallback = useCallback(async () => {
    if (page === lastCalledPage) return;

    try {
      const response = await getAllAttachedFiles(companyId, page, filesPerPage);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setFiles([...files, ...response.data]);
      setLastCalledPage(page);
      setUpdateFiles(false);

      if (response.detail.total_pages && response.detail.total_pages > page) {
        setPage(page + 1);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  }, [page]);

  useEffect(() => {
    if (companyId) getDataCallback();
  }, [getDataCallback, companyId]);

  useEffect(() => {
    if (updateFiles) {
      setLastCalledPage(0);
      setPage(1);
      setDescription('');
      setFileName('');
      setFileAttached(undefined);
      setFiles([]);
      getDataCallback();
    }
  }, [updateFiles]);

  const handleDeleteFile = async (fileId: number): Promise<void> => {
    try {
      const response = await deleteAttachment(fileId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      const filteredFiles = files.filter((e) => e.id !== fileId);
      setFiles(filteredFiles);
      setSnackbarMessage('Arquivo deletado!');
      setErrorMessage(false);
      setOpenSnackbar(true);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const handleFileUpload = async (
    e: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    if (!e.target.files?.item(0) || !validateFiles(e.target.files[0].type)) {
      setSnackbarMessage('Formato incorreto, selecione uma imagem ou pdf.');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    const file = e.target.files[0];
    setFileName(file.name);
    setFileAttached(file);
  };

  const handleSubmitFile = async (): Promise<void> => {
    if (!description) {
      setSnackbarMessage('Adicione uma descrição para o arquivo!');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }

    if (!fileAttached) {
      setSnackbarMessage('Adicione um arquivo!');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }

    const formData = new FormData();
    formData.append('file', fileAttached);
    formData.append('description', description);

    try {
      const response = await addAttachment(companyId, formData);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setUpdateFiles(true);
      setSnackbarMessage('Arquivo adicionado com sucesso!');
      setErrorMessage(false);
      setOpenSnackbar(true);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  return (
    <>
      <OutlinedButton disableTouchRipple onClick={handleOpen}>
        {IconAttachFileMS}
        {Constants.attachments}
      </OutlinedButton>
      <StyledDialog open={open} onClose={handleClose}>
        <>
          <TopBox>
            <DialogTitle>
              {IconAttachFileMS}
              {Constants.attachments}
            </DialogTitle>
            <CloseButton onClick={handleClose} disableTouchRipple>
              {IconCloseMS}
            </CloseButton>
          </TopBox>
          <UploadBox>
            <CustomTextField
              id="file-description"
              label="descrição"
              value={description}
              setValue={setDescription}
              maxLength={60}
            />
            <InputBox>
              <CustomTextField
                disabled
                id="file-name"
                label="arquivo"
                value={fileName}
                setValue={setFileName}
              />
              <label htmlFor="uploadAttachFiles">
                <input
                  accept="image/*,application/pdf"
                  id="uploadAttachFiles"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={async (e: ChangeEvent<HTMLInputElement>) =>
                    handleFileUpload(e)
                  }
                />
                <AttachFileButon component="span">
                  {Constants.selectFile}
                </AttachFileButon>
              </label>
            </InputBox>
            <SubmitBox>
              <CancelButton onClick={handleClose}>
                {Constants.cancel}
              </CancelButton>
              <SubmitButton onClick={handleSubmitFile}>
                {Constants.add}
              </SubmitButton>
            </SubmitBox>
          </UploadBox>
          <AttachmentsBox>
            <DialogTitle my={3}>
              {IconFolderOpenMS}
              {Constants.files}
            </DialogTitle>
            <FileList>
              {files.length > 0 &&
                files.map((file) => (
                  <FileContainer container key={file.id}>
                    <Box>
                      <FileNameTypography>
                        {IconAttachFileMS}
                        {file.file}
                      </FileNameTypography>
                      <DateTypography sx={{ paddingLeft: '21px' }}>
                        {file.description}
                      </DateTypography>
                    </Box>
                    <Box>
                      <StyledIcon
                        onClick={() => handleDownloadFile(file.url, file.file)}
                      >
                        {IconDownloadMS}
                      </StyledIcon>
                      <StyledIcon onClick={() => handleDeleteFile(file.id)}>
                        {IconDeleteMS}
                      </StyledIcon>
                    </Box>
                  </FileContainer>
                ))}
            </FileList>
          </AttachmentsBox>
        </>
      </StyledDialog>
    </>
  );
}

import {
  Box,
  Button,
  Grid,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledbBoxContainer = styled(Box)(() => ({
  position: 'absolute',
  textAlign: 'center',
  top: '49vh',
  left: '51%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#FAFCFF',
  padding: '20px',
  width: '1040px',
  borderRadius: '16px',
  height: '632px',
  display: 'block',
  overflow: 'auto',
}));

export const TitleMoldal = styled(Typography)(() => ({
  color: '#707070',
  fontFamily: 'Montserrat',
  fontWeight: 500,
  fontSize: '18px',
  lineHeight: '22px',
  padding: '10px',
}));

export const SaveTypologyButton = styled(Button)(() => ({
  width: '210px',
  display: 'flex',
  gap: '10px',
  border: '1px solid #004D43',
  borderRadius: '30px',
  fontWeight: '600',
  height: '48px',
  color: '#FAFCFF',
  backgroundColor: '#004D43',
  fontFamily: 'Montserrat',
  textTransform: 'lowercase',
  '&:hover': {
    backgroundColor: '#00796B',
    borderColor: '#00796B',
  },
}));

export const StyledTextFieldGroup = styled(TextField)(() => ({
  width: '308px',
  fontSize: '14px',
  '& .MuiOutlinedInput-root': {
    height: 46,
    borderRadius: 16,
    color: '#707070',
    display: 'flex',
    alignItems: 'center',
    padding: '0 14px',
    '& fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#00B39B',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#707070',
    '&.Mui-focused': {
      color: '#00B39B',
    },
    top: '-4px',
  },
}));

export const CancelButton = styled(Button)(() => ({
  width: '210px',
  display: 'flex',
  gap: '10px',
  border: '1px solid #004D43',
  borderRadius: '30px',
  color: '#004D43',
  fontFamily: 'Montserrat',
  fontWeight: '600',
  height: '48px',
  backgroundColor: '#FAFCFF',
  textTransform: 'lowercase',
  '&:hover': {
    backgroundColor: '#EDEFF2',
    color: '#004D43',
  },
}));

export const ScrollableGrid = styled(Grid)(() => ({
  height: '342px',
  width: '96%',
  maxHeight: '342px',
  overflowY: 'auto',
  border: '1px solid #B9BABB',
  borderRadius: '6px',
  margin: '0px auto',
  padding: '4px',

  '& > .MuiGrid-item': {
    width: '100%',
    display: 'block',
  },

  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#8B8C8E',
    borderRadius: '4px',
  },
}));

export const StyledSelect = styled(Select)(() => ({
  width: '202px',
  textAlign: 'start',
  fontSize: '14px',
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: 16,
  },
  '&.MuiOutlinedInput-root': {
    height: 48,
    color: '#707070',
    '& fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#00B39B',
    },
  },
}));

export const StyledTextField = styled(TextField)(() => ({
  width: '142px',
  fontSize: '14px',
  '& .MuiOutlinedInput-root': {
    height: 46,
    borderRadius: 16,
    color: '#707070',
    display: 'flex',
    alignItems: 'center',
    padding: '0 14px',
    '& fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#00B39B',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#707070',
    '&.Mui-focused': {
      color: '#00B39B',
    },
    top: '-4px',
  },
}));

export const AddNewTypologyButton = styled(Button)(() => ({
  width: '184px',
  display: 'flex',
  gap: '10px',
  border: '1px solid #00B39B',
  borderRadius: '30px',
  color: '#00B39B',
  fontWeight: '600',
  height: '48px',
  backgroundColor: '#FAFCFF',
  textTransform: 'lowercase',
  '&:hover': {
    backgroundColor: '#00B39B',
  },
  '&:disabled': {
    color: '#00B39B',
  },
}));

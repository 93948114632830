import { Box, Button, IconButton, Typography, styled } from '@mui/material';

export const StyledbBoxContainer = styled(Box)(() => ({
  position: 'absolute',
  textAlign: 'center',
  top: '49vh',
  left: '51%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#FAFCFF',
  padding: '20px',
  width: '740px',
  borderRadius: '16px',
  height: '508px',
  display: 'block',
  overflow: 'auto',
}));

export const TitleMoldal = styled(Typography)(() => ({
  color: '#707070',
  fontFamily: 'Montserrat',
  fontWeight: 500,
  fontSize: '18px',
  lineHeight: '22px',
  padding: '10px',
}));

export const SaveAnnouncement = styled(Button)(() => ({
  width: '210px',
  display: 'flex',
  gap: '10px',
  border: '1px solid #004D43',
  borderRadius: '30px',
  fontWeight: '600',
  height: '48px',
  color: '#FAFCFF',
  backgroundColor: '#004D43',
  fontFamily: 'Montserrat',
  textTransform: 'lowercase',
  '&:hover': {
    backgroundColor: '#00796B',
    borderColor: '#00796B',
  },
}));

export const CloseButton = styled(IconButton)(() => ({
  color: '#00B39B',
  padding: '8px',
  '&:hover': {
    backgroundColor: 'rgba(0, 179, 155, 0.04)',
  },
}));

export const CancelButton = styled(Button)(() => ({
  width: '210px',
  display: 'flex',
  gap: '10px',
  border: '1px solid #004D43',
  borderRadius: '30px',
  color: '#004D43',
  fontFamily: 'Montserrat',
  fontWeight: '600',
  height: '48px',
  backgroundColor: '#FAFCFF',
  textTransform: 'lowercase',
  '&:hover': {
    backgroundColor: '#004D43',
  },
  '&:disabled': {
    color: '#004D43',
  },
}));

export const StyledTextFieldMultiline = styled('textarea')(() => ({
  width: '100%',
  backgroundColor: '#FFFFFF',
  borderRadius: '8px',
  border: '1px solid #707070',
  padding: '16px',
  height: '300px',
  fontFamily: 'Montserrat',
  fontSize: '16px',
  color: '#333333',
  '&:focus': {
    outline: 'none',
  },
}));

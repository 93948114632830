/* eslint-disable no-nested-ternary */
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { LicensedCompany } from '../../../../../../api/theHive/licensedCompanies/types';
import {
  TableCellHeader,
  StyledCell,
  StyledRow,
  StyledPagination,
  StyledTableBody,
  LoadingRow,
} from './styles';
import { CompaniesTableProps } from './types';

export function CompaniesTable({
  data,
  page,
  totalPages,
  setPage,
  isLoading = false,
}: CompaniesTableProps): JSX.Element {
  const navigate = useNavigate();

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ): void => {
    setPage(value);
  };

  const handleNavigate = (companyId: number): void => {
    navigate(`./company-details/${companyId}`);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCellHeader align="left">Empresas</TableCellHeader>
              <TableCellHeader align="right">Ações</TableCellHeader>
            </TableRow>
          </TableHead>
          <StyledTableBody>
            {isLoading ? (
              <LoadingRow>
                <StyledCell colSpan={2} align="center">
                  <CircularProgress size={24} />
                </StyledCell>
              </LoadingRow>
            ) : data.length === 0 ? (
              <LoadingRow>
                <StyledCell colSpan={2} align="center">
                  Nenhuma empresa encontrada
                </StyledCell>
              </LoadingRow>
            ) : (
              data.map((item: LicensedCompany) => (
                <StyledRow
                  key={`row-${item.id}`}
                  onClick={() => handleNavigate(item.id)}
                >
                  <StyledCell>{item.name}</StyledCell>
                  <StyledCell />
                </StyledRow>
              ))
            )}
          </StyledTableBody>
        </Table>
      </TableContainer>
      <StyledPagination
        page={page}
        count={totalPages}
        onChange={handlePageChange}
      />
    </>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';

import { StatusCode } from '../../../../../api/enumerations';
import { CompanyNotices } from '../../../../../api/theHive/licensedCompanies/types';
import { getNoticeCoverage } from '../../../../../api/theHive/noticeCoverage';
import { CoverageData } from '../../../../../api/theHive/noticeCoverage/types';
import { addLicense } from '../../../../../api/theHive/notices';
import { getTypologyGroups } from '../../../../../api/theHive/typology';
import { ListTypologyData } from '../../../../../api/theHive/typology/types';
import {
  IconCloseMS,
  IconEditMS,
  IconExpandMoreMS,
} from '../../../../../constants/icons';
import { GlobalContext } from '../../../../../context/global';
import { formatOnlyDate } from '../../../../../helpers';
import useErrorMessage from '../../../../../hooks/useErrorMessage';
import { InputTitle, StyledInput } from '../styles';
import {
  BorderBox,
  ButtonBox,
  CheckboxFormControl,
  CloseButton,
  CustomAccordion,
  DialogTitle,
  OutlinedButton,
  StyledDialog,
  TopBox,
} from './styles';

interface EditNoticeProps {
  companyId: number;
  noticeData: CompanyNotices;
  open: boolean;
  handleClose: () => void;
  resetTable: boolean;
  setResetTable: (value: boolean) => void;
}

export function EditNotice({
  companyId,
  noticeData,
  open,
  handleClose,
  resetTable,
  setResetTable,
}: EditNoticeProps): JSX.Element {
  const [loadingMainRegions, setLoadingMainRegions] = useState<boolean>(false);
  const [mainRegions, setMainRegions] = useState<CoverageData[]>([]);
  const [regionsCheckbox, setRegionsCheckbox] = useState<boolean[]>([]);
  const [loadingTypologies, setLoadingTypologies] = useState<boolean>(false);
  const [typologies, setTypologies] = useState<ListTypologyData[]>([]);
  const [typologiesCheckbox, setTypologiesCheckbox] = useState<boolean[]>([]);

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const { getErrorMessage } = useErrorMessage();

  const handleNoticeCoverage = async (): Promise<void> => {
    setLoadingMainRegions(true);
    try {
      const response = await getNoticeCoverage(noticeData.id);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setMainRegions(response.data.main_regions);

      const questionOptions = response.data.main_regions.map((data) => {
        const hasAnswer = noticeData.notice_company.coverages.find(
          (e) => e.id === data.id
        );
        return !!hasAnswer;
      });
      setRegionsCheckbox(questionOptions);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    } finally {
      setLoadingMainRegions(false);
    }
  };

  const handleTypologyGroups = async (): Promise<void> => {
    setLoadingTypologies(true);
    try {
      const response = await getTypologyGroups(noticeData.id);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setTypologies(response.data);

      const questionOptions = response.data.map((data) => {
        const hasAnswer = noticeData.notice_company.typology_groups.find(
          (e) => e.id === data.id
        );
        return !!hasAnswer;
      });
      setTypologiesCheckbox(questionOptions);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    } finally {
      setLoadingTypologies(false);
    }
  };

  useEffect(() => {
    handleNoticeCoverage();
    handleTypologyGroups();
  }, [noticeData]);

  const handleCompanyNotice = async (): Promise<void> => {
    const selectedTypologies = typologiesCheckbox
      .map((data, i) => (data ? typologies[i].id : null))
      .filter(Boolean) as number[];
    const selectedRegions = regionsCheckbox
      .map((data, i) => (data ? mainRegions[i].id : null))
      .filter(Boolean) as number[];

    if (selectedTypologies.length < 1 || selectedRegions.length < 1) {
      setSnackbarMessage('Selecione ao menos um valor.');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }

    const data = {
      licensed_company_id: companyId,
      typology_groups_ids: selectedTypologies,
      notice_coverages_ids: selectedRegions,
    };

    try {
      const response = await addLicense(noticeData.id, data);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage(`Alterações realizadas com sucesso!`);
      setErrorMessage(false);
      setOpenSnackbar(true);
      setResetTable(!resetTable);
      handleClose();
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const formatPropertyType = (type: string): string => {
    const typeMap: { [key: string]: string } = {
      house: 'casa',
      apartment: 'apartamento',
      building: 'prédio',
      parking_space: 'vaga de garagem',
      store: 'loja',
      land: 'terreno',
      commercial_room: 'sala comercial/pavimento corrido',
      mall_store: 'loja em shopping',
      shed: 'galpões',
      commercial_residential_mix_building:
        'prédios comerciais/residenciais misto',
      gleba: 'gleba',
    };
    return typeMap[type] || type;
  };

  const formatKeyword = (type: string): string => {
    const typeMap: { [key: string]: string } = {
      up_to: 'até',
      between: 'entre',
      over: 'acima de',
    };
    return typeMap[type] || type;
  };

  const formatArea = (
    firstValue: number,
    secondValue: number | null
  ): string => {
    if (!secondValue) {
      return `${firstValue} m²`;
    }
    return `${firstValue} m² e ${secondValue} m²`;
  };

  return (
    <StyledDialog open={open} onClose={handleClose}>
      <Box p="48px">
        <TopBox>
          <DialogTitle>
            {IconEditMS}
            Editar edital
          </DialogTitle>
          <CloseButton onClick={handleClose} disableTouchRipple>
            {IconCloseMS}
          </CloseButton>
        </TopBox>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputTitle>Edital n°:</InputTitle>
            <StyledInput>{noticeData.name}</StyledInput>
          </Grid>
          <Grid item xs={6}>
            <InputTitle>Vigência do edital(início):</InputTitle>
            <StyledInput>{formatOnlyDate(noticeData.start_date)}</StyledInput>
          </Grid>
          <Grid item xs={6}>
            <InputTitle>Vigência do edital(final):</InputTitle>
            <StyledInput>{formatOnlyDate(noticeData.end_date)}</StyledInput>
          </Grid>
          <Grid item xs={12}>
            <InputTitle>Observação:</InputTitle>
            <StyledInput>{noticeData.observations}</StyledInput>
          </Grid>
        </Grid>
        <BorderBox>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputTitle>Área de cobertura</InputTitle>
            </Grid>
            {loadingMainRegions && (
              <Grid item xs={12} display="flex" justifyContent="center">
                <LinearProgress sx={{ width: '80%' }} />
              </Grid>
            )}
            <Grid item xs={12}>
              {mainRegions.map((opt, i) => (
                <CustomAccordion key={opt.id}>
                  <AccordionSummary
                    expandIcon={IconExpandMoreMS}
                    aria-controls={opt.city.name}
                    id={opt.city.name}
                  >
                    <CheckboxFormControl
                      label={opt.city.name}
                      onClick={(e) => e.stopPropagation()}
                      control={
                        <Checkbox
                          checked={regionsCheckbox[i] || false}
                          onClick={(e) => e.stopPropagation()}
                          onFocus={(e) => e.stopPropagation()}
                          color="primary"
                          value={opt.id}
                          onChange={(e) => {
                            const tempChecked = [...regionsCheckbox];
                            tempChecked[i] = e.target.checked;
                            setRegionsCheckbox(tempChecked);
                          }}
                        />
                      }
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box display="flex" gap="8px">
                      Valor de deslocamento:
                      <StyledInput>R$ {opt.displacement_fee}</StyledInput>
                    </Box>
                    {opt.subregions.map((data) => (
                      <Typography key={data.id} my="8px">
                        {data.city.name}
                      </Typography>
                    ))}
                  </AccordionDetails>
                </CustomAccordion>
              ))}
            </Grid>
          </Grid>
        </BorderBox>
        <BorderBox>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputTitle>Grupos de tipologias</InputTitle>
            </Grid>
            {loadingTypologies && (
              <Grid item xs={12} display="flex" justifyContent="center">
                <LinearProgress sx={{ width: '80%' }} />
              </Grid>
            )}
            <Grid item xs={12}>
              {typologies.map((opt, i) => (
                <CustomAccordion key={opt.id}>
                  <AccordionSummary
                    expandIcon={IconExpandMoreMS}
                    aria-controls={opt.name}
                    id={opt.name}
                  >
                    <CheckboxFormControl
                      label={opt.name}
                      onClick={(e) => e.stopPropagation()}
                      control={
                        <Checkbox
                          checked={typologiesCheckbox[i] || false}
                          color="primary"
                          value={opt.id}
                          onClick={(e) => e.stopPropagation()}
                          onFocus={(e) => e.stopPropagation()}
                          onChange={(e) => {
                            const tempChecked = [...typologiesCheckbox];
                            tempChecked[i] = e.target.checked;
                            setTypologiesCheckbox(tempChecked);
                          }}
                        />
                      }
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box display="flex" gap="8px">
                      Valor:
                      <StyledInput>R$ {opt.price}</StyledInput>
                    </Box>
                    {opt.typologies.map((data) => (
                      <Typography key={data.id} my="8px">
                        {formatPropertyType(data.real_estate_type)}{' '}
                        {formatKeyword(data.rule_keyword)}{' '}
                        {formatArea(
                          data.rule_first_value,
                          data.rule_second_value
                        )}
                      </Typography>
                    ))}
                  </AccordionDetails>
                </CustomAccordion>
              ))}
            </Grid>
          </Grid>
        </BorderBox>
        <ButtonBox>
          <OutlinedButton onClick={handleCompanyNotice}>
            salvar alterações
          </OutlinedButton>
        </ButtonBox>
      </Box>
    </StyledDialog>
  );
}

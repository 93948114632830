import { Pagination, TableCell, TableRow, styled } from '@mui/material';

export const TableCellHeader = styled(TableCell)(({ theme }) => ({
  color: '#004D43',
  font: '500 21px Montserrat',
  backgroundColor: '#F1F5FA',
  verticalAlign: 'middle',
  '& span': {
    verticalAlign: 'bottom',
    paddingLeft: '4px',
  },
  [theme.breakpoints.down('xl')]: {
    fontSize: '16px',
    '& span': {
      fontSize: '18px',
    },
  },
}));

export const StyledCell = styled(TableCell)(({ theme }) => ({
  color: '#707070',
  [theme.breakpoints.up('xl')]: {
    padding: '20px',
    fontSize: '16px',
  },
}));

export const StyledRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const StyledPagination = styled(Pagination)(() => ({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'flex-end',
  margin: '8px',
  width: '100%',
}));

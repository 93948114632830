import {
  Accordion,
  AccordionSummary,
  Grid,
  Typography,
  styled,
} from '@mui/material';

export const CoverageContainer = styled(Grid)(() => ({
  border: '1px solid #D7D8DB',
  borderRadius: '2px',
  padding: '18px',
}));

export const CoverageTitle = styled(Typography)(() => ({
  fontFamily: 'Montserrat',
  fontSize: '18px',
  color: '#8B8C8E',
  fontWeight: 500,
}));

export const NoContentText = styled(Typography)(() => ({
  fontFamily: 'Montserrat',
  fontSize: '12px',
  color: '#8B8C8E',
  opacity: 0.8,
}));

export const CityInfoContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  marginBottom: '16px',
}));

export const CityText = styled(Typography)(() => ({
  fontFamily: 'Montserrat',
  fontSize: '16px',
  color: '#8B8C8E',
  fontWeight: 500,
}));

export const DisplacementText = styled(Typography)(() => ({
  fontFamily: 'Montserrat',
  fontSize: '16px',
  color: '#8B8C8E',
  fontWeight: 500,
}));

export const DisplacementValue = styled('span')(() => ({
  fontFamily: 'Montserrat',
  fontSize: '16px',
  color: '#707070',
  fontWeight: 600,
}));

export const StyledAccordion = styled(Accordion)(() => ({
  boxShadow: 'none',
  '&:before': {
    display: 'none',
  },
  width: '100%',
  backgroundColor: 'transparent',
  margin: '0 !important',

  // Remove todas as transições do acordeon
  transition: 'none !important',

  // Configura o comportamento do conteúdo
  '& .MuiCollapse-root': {
    transition: 'height 300ms cubic-bezier(0.4, 0, 0.2, 1)',
  },

  // Remove transformações do wrapper
  '& .MuiCollapse-wrapper': {
    transform: 'none !important',
  },

  // Ajusta as margens quando expandido
  '&.Mui-expanded': {
    margin: '0 !important',
  },

  // Remove padding dos detalhes
  '& .MuiAccordionDetails-root': {
    padding: '0',
  },
}));

export const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  padding: 0,
  paddingLeft: '40px',
  justifyContent: 'start',
  minHeight: '45px !important',
  height: '45px',
  margin: '0 !important',

  // Remove todas as transições do summary
  transition: 'none !important',

  '& .MuiAccordionSummary-content': {
    margin: '0 !important',
    transition: 'none',
  },

  // Remove margens quando expandido
  '&.Mui-expanded': {
    minHeight: '45px !important',
    height: '45px',
    margin: '0 !important',

    '& .MuiAccordionSummary-content': {
      margin: '0 !important',
    },
  },

  // Estilo para o ícone de expandir
  '& .expandIcon': {
    color: '#00B39B',
    marginLeft: '12px',
    transition: 'transform 0.3s ease',
    display: 'inline-flex',

    '.Mui-expanded &': {
      transform: 'rotate(180deg)',
    },
  },
}));

export const ContentContainer = styled('div')(() => ({
  paddingLeft: '54px',
  paddingTop: '6px', // Adiciona um espaçamento consistente para o conteúdo
  paddingBottom: '10px',
}));

export const AccordionTitle = styled(Typography)(() => ({
  fontFamily: 'Montserrat',
  fontSize: '16px',
  color: '#8B8C8E',
  fontWeight: 700,
  display: 'flex',
  alignItems: 'center',
}));

export const ScrollableGrid = styled(Grid)(() => ({
  width: '96%',
  maxHeight: '362px',
  overflowY: 'scroll',
  borderRadius: '6px',
  '& > .MuiGrid-item': {
    width: '100%',
    display: 'block',
  },

  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#8B8C8E',
    borderRadius: '4px',
  },
}));

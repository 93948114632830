import {
  Box,
  Button,
  ButtonBase,
  CardMedia,
  styled,
  TextField,
  Typography,
} from '@mui/material';

const ScrollStyle = {
  '&::-webkit-scrollbar': {
    width: '10px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#B9BABB',
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: '#B9BABBCC',
    },
  },
  '&::-webkit-scrollbar-track': {
    border: '1px solid #D7D8DB',
    borderRadius: '20px',
  },
};

export const CompanyDescription = styled(Box)(() => ({
  background: '#FFFFFE',
  boxShadow: '0px 3px 6px #00000029',
  borderRadius: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
}));

export const HeaderContainer = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const HeaderDescription = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  color: '#004E43',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: 32,
  padding: '30px',
  fontSize: '28px',
  fontWeight: '600',
  [theme.breakpoints.up('xl')]: {
    padding: '40px',
  },
}));

export const StyledImage = styled(CardMedia)(({ theme }) => ({
  width: 80,
  height: 80,
  [theme.breakpoints.down('xl')]: {
    width: 60,
    height: 60,
  },
}));

export const MenuButton = styled(ButtonBase)(() => ({
  border: '1px solid #D7D8DB',
  borderRadius: '4px',
  padding: '8px 12px',
  fontSize: '18px',
  fontWeight: '500',
  '&:hover': {
    color: '#333333',
    border: '1px solid #848791',
  },
  '&:disabled': {
    color: '#c2c4c8',
  },
  '& .MuiTouchRipple-root': {
    color: '#00B39B99',
  },
}));

export const NoticeContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '10px',
  flexDirection: 'column',
  alignItems: 'flex-end',
  margin: '12px',
  [theme.breakpoints.up('xl')]: {
    gap: '20px',
  },
}));

export const ActiveStatus = styled(Typography)(() => ({
  fontSize: '21px',
  fontWeight: '600',
  marginTop: '9px',
}));

export const DescriptionContainer = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  padding: '16px',
  justifyContent: 'flex-start',
}));

export const Bold = styled(Typography)(() => ({
  fontWeight: '500',
  marginLeft: '4px',
}));

export const StyledBox = styled(Box)(() => ({
  ...ScrollStyle,
  width: '100%',
  minHeight: '140px',
  maxHeight: '440px',
  margin: '10px 0',
  padding: '20px',
  border: '1px solid #D7D8DB',
  borderRadius: '4px',
  overflowY: 'auto',
}));

export const CommentBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '28px',
  marginTop: '16px',
}));

export const Comment = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
}));

export const StyledName = styled(Box)(() => ({
  fontWeight: '600',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}));

export const ObservationBox = styled(Box)(() => ({
  ...ScrollStyle,
  marginBottom: '20px',
  minHeight: '120px',
  maxHeight: '220px',
  overflowY: 'auto',
}));

export const StyledTextField = styled(TextField)(() => ({
  width: '100%',
  '& .MuiInputBase-root': {
    borderRadius: '16px',
  },
  '& .MuiOutlinedInput-input': {
    ...ScrollStyle,
  },
}));

export const OutlinedButton = styled(Button)(() => ({
  width: '180px',
  marginTop: '20px',
  border: '2px solid #00B39B',
  borderRadius: '16px',
  color: '#00B39B',
  fontWeight: '600',
  backgroundColor: '#FAFCFF',
  lineHeight: 1.2,
  '&:hover': {
    backgroundColor: '#00B39B',
  },
  '&:active': {
    backgroundColor: '#00B39B',
  },
}));

import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  createPublicNotice,
  getNotice,
  updatePublicNotice,
} from '../../../../../../../api/theHive/notices';
import { UpdatePublicNoticeData } from '../../../../../../../api/theHive/notices/types';
import { GlobalContext } from '../../../../../../../context/global';
import useErrorMessage from '../../../../../../../hooks/useErrorMessage';
import { DetailProps } from '../../../NewNotice/types';
import { Notice } from '../../../NoticeDetails/types';

interface UseNoticeActionsProps {
  noticeId?: number;
  isNewNotice: boolean;
}

interface NoticeData {
  name: string;
  observation: string;
  startDate: dayjs.Dayjs | null;
  endDate: dayjs.Dayjs | null;
}

interface FetchNoticeDetailsProps {
  noticeId?: number;
  isNewNotice: boolean;
}

interface FetchNoticeDetailsReturn {
  currentNoticeName: string;
  setCurrentNoticeName: React.Dispatch<React.SetStateAction<string>>;
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  observation: string;
  setObservation: React.Dispatch<React.SetStateAction<string>>;
  startDate: Dayjs | null;
  setStartDate: React.Dispatch<React.SetStateAction<Dayjs | null>>;
  endDate: Dayjs | null;
  setEndDate: React.Dispatch<React.SetStateAction<Dayjs | null>>;
  isLoading: boolean;
}

interface UseNoticeActionsReturn {
  handleSaveNotice: (noticeData: NoticeData) => Promise<void>;
  handleUpdateNotice: (noticeData: NoticeData) => Promise<void>;
  handleFormSubmit: (noticeData: NoticeData) => Promise<void>;
}

export function useFetchNoticeDetails({
  noticeId,
  isNewNotice,
}: FetchNoticeDetailsProps): FetchNoticeDetailsReturn {
  const [currentNoticeName, setCurrentNoticeName] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [observation, setObservation] = useState<string>('');
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const { getErrorMessage } = useErrorMessage();
  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const fetchNoticeDetails = useCallback(async () => {
    if (isNewNotice || !noticeId) return;

    try {
      setIsLoading(true);
      const response = await getNotice(noticeId);
      if (response?.data) {
        const noticeData = response.data as Notice;
        setCurrentNoticeName(noticeData.name);
        setName(noticeData.name);
        setObservation(noticeData.observations || '');

        if (noticeData.start_date) {
          setStartDate(dayjs(noticeData.start_date));
        }
        if (noticeData.end_date) {
          setEndDate(dayjs(noticeData.end_date));
        }
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noticeId, isNewNotice]);

  useEffect(() => {
    if (!isNewNotice) {
      fetchNoticeDetails();
    }
  }, [fetchNoticeDetails, isNewNotice]);

  return {
    currentNoticeName,
    setCurrentNoticeName,
    name,
    setName,
    observation,
    setObservation,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    isLoading,
  };
}

export function useNoticeActions({
  noticeId,
  isNewNotice,
}: UseNoticeActionsProps): UseNoticeActionsReturn {
  const { getErrorMessage } = useErrorMessage();
  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const navigate = useNavigate();

  const validateDates = useCallback(
    (startDate: dayjs.Dayjs | null, endDate: dayjs.Dayjs | null): boolean => {
      if (startDate && endDate && startDate.isAfter(endDate)) {
        setSnackbarMessage(
          'A data de início não pode ser maior que a data final'
        );
        setErrorMessage(true);
        setOpenSnackbar(true);
        return false;
      }
      return true;
    },
    [setSnackbarMessage, setErrorMessage, setOpenSnackbar]
  );

  const handleSaveNotice = useCallback(
    async ({
      name,
      observation,
      startDate,
      endDate,
    }: NoticeData): Promise<void> => {
      if (!validateDates(startDate, endDate)) return;

      const noticeData = {
        name,
        announcement_date: startDate
          ? startDate.toISOString().split('T')[0]
          : '',
        start_date: startDate ? startDate.toISOString().split('T')[0] : '',
        end_date: endDate ? endDate.toISOString().split('T')[0] : '',
        observations: observation,
      };

      try {
        const response: DetailProps = await createPublicNotice(noticeData);
        if (response.detail.status_code === 0) {
          setSnackbarMessage('Edital criado com sucesso');
          setErrorMessage(false);
          setOpenSnackbar(true);

          if (response.data) {
            navigate(`/the-hive/notices/${response.data.id}`);
          } else {
            navigate('/the-hive/notices');
          }
        } else {
          throw new Error(response.detail.description || 'Erro desconhecido');
        }
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setErrorMessage(true);
        setOpenSnackbar(true);
      }
    },
    [
      validateDates,
      setSnackbarMessage,
      setErrorMessage,
      setOpenSnackbar,
      navigate,
      getErrorMessage,
    ]
  );

  const handleUpdateNotice = useCallback(
    async ({
      name,
      observation,
      startDate,
      endDate,
    }: NoticeData): Promise<void> => {
      if (!noticeId) {
        console.error('Notice ID is required for update');
        return;
      }

      if (!validateDates(startDate, endDate)) return;

      const updateData: UpdatePublicNoticeData = {
        name: name.trim(),
        observations: observation.trim(),
        start_date: startDate
          ? startDate.toISOString().split('T')[0]
          : undefined,
        end_date: endDate ? endDate.toISOString().split('T')[0] : undefined,
      };

      try {
        const response: DetailProps = await updatePublicNotice(
          noticeId,
          updateData
        );
        if (response.detail.status_code === 0) {
          setSnackbarMessage('Edital atualizado com sucesso');
          setErrorMessage(false);
          setOpenSnackbar(true);
          navigate(`/the-hive/notices/${noticeId}`);
        }
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setErrorMessage(true);
        setOpenSnackbar(true);
      }
    },
    [
      noticeId,
      validateDates,
      setSnackbarMessage,
      setErrorMessage,
      setOpenSnackbar,
      navigate,
      getErrorMessage,
    ]
  );

  const handleFormSubmit = useCallback(
    async (noticeData: NoticeData): Promise<void> => {
      if (isNewNotice) {
        await handleSaveNotice(noticeData);
      } else {
        await handleUpdateNotice(noticeData);
      }
    },
    [isNewNotice, handleSaveNotice, handleUpdateNotice]
  );

  return {
    handleSaveNotice,
    handleUpdateNotice,
    handleFormSubmit,
  };
}

import { TypologyData } from './types';

export const propertyTypeMapping: Record<
  string,
  TypologyData['real_estate_type']
> = {
  casa: 'house',
  apartamento: 'apartment',
  terreno: 'land',
  'sala comercial/pavimento corrido': 'commercial_room',
  loja: 'store',
  'loja em shopping': 'mall_store',
  'vaga de garagem': 'parking_space',
  galpões: 'shed',
  'prédios comerciais/residenciais misto':
    'commercial_residential_mix_building',
  gleba: 'gleba',
};

export const rangeMapping: Record<string, TypologyData['rule_keyword']> = {
  até: 'up_to',
  entre: 'between',
  'acima de': 'over',
};

export const propertyOptions = [
  'Casa',
  'Apartamento',
  'Terreno',
  'Sala comercial/Pavimento corrido',
  'Loja',
  'Loja em shopping',
  'Vaga de garagem',
  'Galpões',
  'Prédios comerciais/residenciais misto',
  'Gleba',
];

export const rangeOptions = ['até', 'acima de', 'entre'];

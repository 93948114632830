import { Box, ButtonBase, styled } from '@mui/material';

export const BoxContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('xl')]: {
    padding: '40px 60px',
  },
}));

export const RadioButtonContainer = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
}));

export const TopButton = styled(ButtonBase)(() => ({
  flex: 1,
  height: 40,
  color: '#004E43',
  font: '400 16px Montserrat',
  border: '1px solid #004E43',
  borderRadius: '4px 0px 0px 4px',
  '& + button': {
    borderRadius: '0px 4px 4px 0px',
  },
  '&.selected': {
    font: '600 16px Montserrat',
    background: '#004E43',
    color: '#FFFFFE',
  },
}));

export interface INoticeData {
  notice_id: number;
  name: string;
  start_date: string;
  end_date: string;
  observation: string;
}

export const noticesData: INoticeData[] = [
  {
    notice_id: 1,
    name: '0001/2024-0001',
    start_date: '2024-07-30',
    end_date: '2024-10-30',
    observation: 'alguma observação sobre o edital',
  },
  {
    notice_id: 2,
    name: '0001/2024-0001',
    start_date: '2024-07-30',
    end_date: '2024-10-30',
    observation: 'alguma observação sobre o edital',
  },
  {
    notice_id: 3,
    name: '0001/2024-0001',
    start_date: '2024-07-30',
    end_date: '2024-10-30',
    observation: 'alguma observação sobre o edital',
  },
  {
    notice_id: 4,
    name: '0001/2024-0001',
    start_date: '2024-07-30',
    end_date: '2024-10-30',
    observation: 'alguma observação sobre o edital',
  },
];

import { ButtonBase, IconButton } from '@mui/material';
import React from 'react';

import {
  CompanyAbsences,
  CompanyNotices,
} from '../../../../../api/theHive/licensedCompanies/types';
import {
  IconAttachFileMS,
  IconEditMS,
  IconFilePresentMS,
} from '../../../../../constants/icons';
import { formatDate } from '../../../../../helpers';

export interface PresentTableHead {
  id: number;
  label: string | React.ReactElement;
}

export interface PresentTableValues {
  id: number;
  value: string | number | React.ReactElement;
}

export const presentNoticeTableHead = (): PresentTableHead[] => {
  return [
    {
      id: 1,
      label: 'nome',
    },
    {
      id: 2,
      label: 'grupo de tipologias',
    },
    {
      id: 3,
      label: 'polos de atendimento',
    },
    {
      id: 4,
      label: '',
    },
  ];
};

export const formatGroups = (
  firstValue?: string,
  secondValue?: string
): string => {
  if (!firstValue && !secondValue) {
    return '-';
  }
  if (!secondValue) {
    return `${firstValue}`;
  }
  return `${firstValue}, ${secondValue}...`;
};

export const presentNoticeTableValues = (
  data: CompanyNotices,
  handleOpen?: (data?: CompanyNotices) => void
): PresentTableValues[] => {
  return [
    {
      id: 1,
      value: data.name,
    },
    {
      id: 2,
      value: formatGroups(
        data.notice_company.coverages[0]?.city,
        data.notice_company.coverages[1]?.city
      ),
    },
    {
      id: 3,
      value: formatGroups(
        data.notice_company.typology_groups[0]?.name,
        data.notice_company.typology_groups[1]?.name
      ),
    },
    {
      id: 4,
      value: (
        <IconButton
          onClick={() => handleOpen && handleOpen(data)}
          color="primary"
        >
          {IconEditMS}
        </IconButton>
      ),
    },
  ];
};

export const presentHistoryTableHead = (): PresentTableHead[] => {
  return [
    {
      id: 1,
      label: 'motivo',
    },
    {
      id: 2,
      label: 'período',
    },
    {
      id: 3,
      label: 'observações',
    },
    {
      id: 4,
      label: IconAttachFileMS,
    },
  ];
};

export const presentAbsenceTableValues = (
  data: CompanyAbsences
): PresentTableValues[] => {
  return [
    {
      id: 1,
      value: data.reason,
    },
    {
      id: 2,
      value: `${formatDate(data.start_date)} à ${formatDate(data.end_date)}`,
    },
    {
      id: 3,
      value: data.observations,
    },
  ];
};

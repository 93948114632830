/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Rating,
  Typography,
} from '@mui/material';
import { Fragment, useCallback, useContext, useEffect, useRef, useState } from 'react';

import { convertClientKind, StatusCode } from '../../../../api/enumerations';
import { createAutoRequest } from '../../../../api/theHive/requests';
import {
  getHiveWorkOrders,
  getWorkOrderEligibility,
} from '../../../../api/theHive/workOrders';
import { WorkOrderEligibility } from '../../../../api/theHive/workOrders/types';
import { WorkOrderData } from '../../../../api/workOrders/types';
import {
  IconKeyboardDoubleArrowRightMS,
  IconSearchMS,
} from '../../../../constants/icons';
import { GlobalContext } from '../../../../context/global';
import useErrorMessage from '../../../../hooks/useErrorMessage';
import { AssignModal } from './AssignModal';
import {
  CardContainer,
  CardStyled,
  CompanyHeader,
  GridButton,
  GridContainer,
  GridStyled,
  GridTitle,
  OutlinedButton,
  SkeletonStyled,
  StyledImage,
  StyledInputBase,
} from './styles';

export function Requests(): JSX.Element {
  const [loadingOs, setLoadingOs] = useState(true);
  const [loadingCompany, setLoadingCompany] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [lastCalledPage, setLastCalledPage] = useState(0);
  const osPerPage = 10;

  const [workOrders, setWorkOrders] = useState<WorkOrderData[]>([]);
  const [selectedOS, setSelectedOS] = useState<number | undefined>();
  const [selectedOSNumber, setSelectedOSNumber] = useState<string>('');
  const [search, setSearch] = useState('');

  const [notices, setNotices] = useState<WorkOrderEligibility[]>([]);
  const [selectedNotice, setSelectedNotice] = useState<number | undefined>();
  const [selectedNoticeName, setSelectedNoticeName] = useState<string>('');
  const [selectedCompany, setSelectedCompany] = useState<number | undefined>();
  const [selectedCompanyName, setSelectedCompanyName] = useState<string>('');

  const observer = useRef<IntersectionObserver | null>(null);

  const { getErrorMessage } = useErrorMessage();

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const getDataCallback = useCallback(async () => {
    if (page === lastCalledPage) return;
    setLoadingOs(true);

    try {
      const response = await getHiveWorkOrders(search, page, osPerPage);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.detail.total_pages && page === 1) {
        setTotalPages(response.detail.total_pages);
      }

      if (page === 1) {
        setWorkOrders(response.data);
      } else {
        setWorkOrders([...workOrders, ...response.data]);
        setLastCalledPage(page);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    } finally {
      setLoadingOs(false);
    }
  }, [search, page]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setLastCalledPage(0);
      setPage(1);
      setReloadData(!reloadData);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  useEffect(() => {
    if (page !== 1) {
      getDataCallback();
    }
  }, [page]);

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      return;
    }
    getDataCallback();
  }, [reloadData]);

  const lastElementRef = (node: HTMLAnchorElement): void => {
    if (loadingOs) return;

    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && page < totalPages) {
        setPage(page + 1);
      }
    });

    if (node) observer.current.observe(node);
  };

  const handleWorkOrderEligibility = async (): Promise<void> => {
    if (!selectedOS) return;
    try {
      const response = await getWorkOrderEligibility(selectedOS);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setNotices(response.data);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    } finally {
      setLoadingCompany(false);
    }
  };

  useEffect(() => {
    if (selectedOS) {
      setLoadingCompany(true);
      handleWorkOrderEligibility();
    } else {
      setNotices([]);
      setSelectedNotice(undefined);
      setSelectedNoticeName('');
      setSelectedCompany(undefined);
      setSelectedCompanyName('');
    }
  }, [selectedOS]);

  const handleAutoAssign = useCallback(async () => {
    const osIds = workOrders.map((os) => os.id);

    const data = {
      work_orders_ids: osIds,
    };

    try {
      const response = await createAutoRequest(data);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }
      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }
      setSnackbarMessage('Ordens de serviço atribuídas com sucesso!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      if (search.length > 0) {
        setSearch('');
      } else {
        setLastCalledPage(0);
        setPage(1);
        setReloadData(!reloadData);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  }, [workOrders]);

  return (
    <GridContainer container spacing={4}>
      <Grid item xs={5}>
        <GridTitle>Lista de OS</GridTitle>
        <GridStyled item>
          <StyledInputBase
            placeholder="buscar informação da OS"
            inputProps={{ 'aria-label': 'pesquisar', maxLength: 11 }}
            type="search"
            value={search}
            startAdornment={
              <InputAdornment position="start" sx={{ color: '#004D43' }}>
                {IconSearchMS}
              </InputAdornment>
            }
            onChange={(e) => {
              if (e.target.value.length > 0) {
                setSearch(e.target.value);
              }
              if (e.target.value.length === 0) {
                setSearch('');
              }
            }}
            onFocus={(e) => {
              e.target.value = '';
              setSearch('');
            }}
          />
          <CardContainer>
            <Button
              sx={{ borderRadius: '16px', mb: '20px' }}
              disabled={workOrders.length === 0}
              onClick={handleAutoAssign}
            >
              atribuir automaticamente
            </Button>
            {workOrders.length > 0 &&
              workOrders.map((workOrder) => (
                <CardStyled
                  key={workOrder.id}
                  onClick={() => {
                    if (selectedOS === workOrder.id) {
                      setSelectedOS(undefined);
                      setSelectedOSNumber('');
                    } else {
                      setSelectedOS(workOrder.id);
                      setSelectedOSNumber(`${workOrder.reference_number}`);
                    }
                  }}
                  className={workOrder.id === selectedOS ? 'selected' : ''}
                >
                  <span>
                    <strong>número OS:</strong> {workOrder.reference_number}
                  </span>
                  <span>
                    <strong>nome do cliente:</strong> {workOrder.client_name}
                  </span>
                  <span>
                    <strong>tipo do cliente:</strong>{' '}
                    {convertClientKind(workOrder.client_kind)}
                  </span>
                  <span>
                    <strong>cidade:</strong> {workOrder.city}
                  </span>
                  <span>
                    <strong>localização:</strong> {workOrder.street} -{' '}
                    {workOrder.number}
                  </span>
                </CardStyled>
              ))}
            {loadingOs ? (
              [1, 2, 3, 4, 5].map((item) => (
                <SkeletonStyled
                  key={item}
                  variant="rectangular"
                  animation="pulse"
                />
              ))
            ) : (
              <Box ref={lastElementRef} />
            )}
          </CardContainer>
        </GridStyled>
      </Grid>
      <GridButton item xs={2}>
        <OutlinedButton
          onClick={() => setOpenModal(!openModal)}
          disabled={!selectedOS || !selectedCompany}
        >
          atribuir {IconKeyboardDoubleArrowRightMS}
        </OutlinedButton>
      </GridButton>
      <Grid item xs={5}>
        <GridTitle>Credenciados</GridTitle>
        <GridStyled item>
          {selectedOS ? (
            <>
              <StyledInputBase
                placeholder="buscar por CNPJ"
                inputProps={{ 'aria-label': 'pesquisar', maxLength: 11 }}
                type="search"
                startAdornment={
                  <InputAdornment position="start" sx={{ color: '#004D43' }}>
                    {IconSearchMS}
                  </InputAdornment>
                }
              />
              <CardContainer>
                {notices.length > 0 ? (
                  notices.map((notice) => (
                    <Fragment key={notice.public_notice_id}>
                      {notice.licensed_companies.length > 0 &&
                        notice.licensed_companies.map((data) => (
                          <CardStyled
                            sx={{ height: '250px' }}
                            onClick={() => {
                              if (
                                selectedCompany === data.licensed_company_id &&
                                selectedNotice === notice.public_notice_id
                              ) {
                                setSelectedCompany(undefined);
                                setSelectedCompanyName('');
                                setSelectedNotice(undefined);
                                setSelectedNoticeName('');
                              } else {
                                setSelectedCompany(data.licensed_company_id);
                                setSelectedCompanyName(data.name);
                                setSelectedNotice(notice.public_notice_id);
                                setSelectedNoticeName(notice.name);
                              }
                            }}
                            className={
                              selectedCompany === data.licensed_company_id
                                ? 'selected'
                                : ''
                            }
                          >
                            <CompanyHeader>
                              <StyledImage
                                image={data.logo_url}
                                title="empresa"
                              />
                              {data.name}
                            </CompanyHeader>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 8,
                              }}
                            >
                              <span>
                                <strong>CNPJ:</strong> {data.document}
                              </span>
                              <span>
                                <strong>Cidade:</strong> {data.city}
                              </span>
                              <span>
                                <strong>OS:</strong>
                                <strong style={{ color: '#00B39B' }}>
                                  {' '}
                                  ativa
                                </strong>
                              </span>
                              <Rating value={data.rating} />
                            </div>
                          </CardStyled>
                        ))}
                    </Fragment>
                  ))
                ) : (
                  <Typography>
                    {loadingCompany
                      ? ''
                      : 'Nenhum edital ativo cobre a tipologia desta Ordem de Serviço'}
                  </Typography>
                )}
                {loadingCompany &&
                  [1, 2, 3, 4, 5].map((item) => (
                    <SkeletonStyled
                      key={item}
                      variant="rectangular"
                      animation="pulse"
                    />
                  ))}
              </CardContainer>
            </>
          ) : (
            <Typography>selecione uma OS</Typography>
          )}
        </GridStyled>
      </Grid>
      <AssignModal
        osId={selectedOS}
        osNumber={selectedOSNumber}
        noticeId={selectedNotice}
        noticeName={selectedNoticeName}
        companyId={selectedCompany}
        companyName={selectedCompanyName}
        open={openModal}
        handleClose={() => setOpenModal(!openModal)}
        modalCallback={() => {
          if (search.length > 0) {
            setSearch('');
          } else {
            setLastCalledPage(0);
            setPage(1);
            setReloadData(!reloadData);
          }
        }}
      />
    </GridContainer>
  );
}

/* eslint-disable react/require-default-props */
import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';

import { convertToFloatNumber } from '../../helpers';

interface CustomTextFieldProps {
  id: string;
  label: string;
  value: string;
  setValue: (value: string) => void;
  disabled?: boolean;
  maxLength?: number;
  required?: boolean;
  onChange?: (value: boolean) => void;
}

export function CustomTextField({
  id,
  label,
  value,
  setValue,
  disabled,
  maxLength,
  required,
  onChange,
}: CustomTextFieldProps): JSX.Element {
  return (
    <TextField
      id={id}
      label={label}
      disabled={disabled}
      required={required}
      color="secondary"
      value={value}
      inputProps={{ maxLength }}
      onChange={(e) => {
        setValue(e.target.value);
        if (onChange) {
          onChange(true);
        }
      }}
      sx={{ width: '100%', '& .MuiInputBase-root': { borderRadius: '16px' } }}
    />
  );
}

interface SelectTextFieldProps {
  id: string;
  label: string;
  value: number;
  setValue: (value: number) => void;
  selectOptions: { value: number; label: string }[];
  disable?: boolean;
  allSelectable?: boolean;
}

export function SelectTextField({
  id,
  label,
  value,
  setValue,
  selectOptions,
  disable,
  allSelectable,
}: SelectTextFieldProps): JSX.Element {
  return (
    <TextField
      id={id}
      disabled={disable || false}
      select
      label={label}
      color="secondary"
      value={value}
      onChange={(e) => {
        setValue(Number(e.target.value));
      }}
      sx={{ width: '100%', '& .MuiInputBase-root': { borderRadius: '16px' } }}
    >
      {selectOptions.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          sx={{
            '&:hover': { backgroundColor: '#00B39B66' },
            ...(!allSelectable && {
              '&:first-of-type': {
                color: '#7c7c7c',
                '&.Mui-selected': { backgroundColor: '#EDEDED' },
                '&:hover': {
                  backgroundColor: '#EDEDED',
                  cursor: 'auto',
                },
              },
            }),
          }}
        >
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
}

interface MultilineTextFieldProps {
  id: string;
  label: string;
  value: string;
  setValue: (value: string) => void;
}

export function MultilineTextField({
  id,
  label,
  value,
  setValue,
}: MultilineTextFieldProps): JSX.Element {
  return (
    <TextField
      id={id}
      multiline
      rows={6}
      label={label}
      color="secondary"
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      sx={{ width: '100%', '& .MuiInputBase-root': { borderRadius: '16px' } }}
    />
  );
}

interface NumericTextFieldProps {
  id: string;
  label: string;
  suffix: string;
  decimalSeparator?: string;
  decimalScale?: number;
  maxLength: number;
  value: number;
  setValue: (value: number) => void;
  disable?: boolean;
  error?: boolean;
  required?: boolean;
}

export function NumericTextField({
  id,
  label,
  suffix,
  decimalSeparator,
  decimalScale,
  maxLength,
  value,
  setValue,
  disable,
  error,
  required,
}: NumericTextFieldProps): JSX.Element {
  return (
    <NumericFormat
      customInput={TextField}
      disabled={disable || false}
      error={error || false}
      id={id}
      label={label}
      color="secondary"
      suffix={suffix}
      decimalSeparator={decimalSeparator || '.'}
      decimalScale={decimalScale || 0}
      allowNegative={false}
      inputProps={{ maxLength }}
      value={value}
      onChange={(e) => {
        const convertedValue = convertToFloatNumber(e.target.value);
        setValue(convertedValue || 0);
      }}
      sx={{ width: '100%', '& .MuiInputBase-root': { borderRadius: '16px' } }}
      required={required}
    />
  );
}

interface PreffixNumericTextFieldProps {
  required?: boolean;
  id: string;
  label: string;
  prefix: string;
  decimalSeparator?: string;
  decimalScale?: number;
  maxLength: number;
  value: number;
  setValue: (value: number) => void;
}

export function PreffixNumericTextField({
  required,
  id,
  label,
  prefix,
  decimalSeparator,
  decimalScale,
  maxLength,
  value,
  setValue,
}: PreffixNumericTextFieldProps): JSX.Element {
  return (
    <NumericFormat
      required={required}
      customInput={TextField}
      id={id}
      label={label}
      color="secondary"
      prefix={prefix}
      decimalSeparator={decimalSeparator || '.'}
      decimalScale={decimalScale || 0}
      thousandsGroupStyle="thousand"
      thousandSeparator=" "
      allowNegative={false}
      inputProps={{ maxLength }}
      value={value}
      onChange={(e) => {
        const convertedValue = convertToFloatNumber(e.target.value);
        setValue(convertedValue);
      }}
      sx={{ width: '100%', '& .MuiInputBase-root': { borderRadius: '16px' } }}
    />
  );
}

interface CustomFormControlProps {
  label: string;
  isChecked: boolean;
  setIsChecked: (isChecked: boolean) => void;
}

export function CustomFormControl({
  label,
  isChecked,
  setIsChecked,
}: CustomFormControlProps): JSX.Element {
  return (
    <FormControlLabel
      label={label}
      control={
        <Checkbox
          color="primary"
          checked={isChecked}
          onChange={(event) => {
            setIsChecked(event.target.checked);
          }}
        />
      }
      sx={{
        '&:hover': {
          '& .MuiButtonBase-root': {
            color: '#06DFC2',
          },
          '& .MuiTypography-root': {
            fontWeight: '600',
          },
        },
      }}
    />
  );
}

interface PathologiesFormControlProps {
  label: string;
  value: number;
  isChecked: boolean;
  onChangeCallback: (checked: boolean, value: number) => void;
  disabled: boolean;
}

export function PathologiesFormControl({
  label,
  value,
  isChecked,
  onChangeCallback,
  disabled,
}: PathologiesFormControlProps): JSX.Element {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  return (
    <FormControlLabel
      control={
        <Checkbox
          color="primary"
          disabled={disabled}
          checked={checked}
          onChange={(event) => {
            setChecked(event.target.checked);
            onChangeCallback(event.target.checked, value);
          }}
        />
      }
      sx={{
        '&:hover': {
          '& .MuiButtonBase-root': {
            color: '#06DFC2',
          },
          '& .MuiTypography-root': {
            fontWeight: '600',
          },
        },
        '& .MuiButtonBase-root': {
          color: '#06DFC2',
          '&.Mui-disabled': {
            color: '#004D43',
          },
        },
        '& .MuiTypography-root': {
          '&.Mui-disabled': {
            color: '#737373',
          },
        },
      }}
      label={label}
    />
  );
}

interface StyledSelectProps {
  id: string;
  label: string;
  value: string;
  setValue: (value: string) => void;
  selectOptions: string[];
  disabled?: boolean;
}

export function StyledSelect({
  id,
  label,
  value,
  setValue,
  selectOptions,
  disabled,
}: StyledSelectProps): JSX.Element {
  return (
    <TextField
      disabled={disabled}
      id={id}
      select
      label={label}
      color="secondary"
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      sx={{ width: '100%', '& .MuiInputBase-root': { borderRadius: '16px' } }}
    >
      <MenuItem
        disabled
        value=""
        sx={{
          color: '#000000',
          fontStyle: 'normal',
        }}
      >
        <Typography>selecione uma opção</Typography>
      </MenuItem>
      {selectOptions.map((option) => (
        <MenuItem
          key={option}
          value={option}
          sx={{
            '&:hover': { backgroundColor: '#00B39B66' },
          }}
        >
          {option}
        </MenuItem>
      ))}
    </TextField>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import { Box, LinearProgress, Typography } from '@mui/material';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getObservations } from '../../../../../api/theHive/licensedCompanies';
import { ObservationData } from '../../../../../api/theHive/licensedCompanies/types';
import { GlobalContext } from '../../../../../context/global';
import { formatDateAndTime } from '../../../../../helpers';
import useErrorMessage from '../../../../../hooks/useErrorMessage';
import { Comment, CommentBox, StyledName, ObservationBox } from './styles';

export function Observations(): JSX.Element {
  const [observations, setObservations] = useState<ObservationData[]>([]);
  const [page, setPage] = useState(1);
  const [lastCalledPage, setLastCalledPage] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);
  const obsPerPage = 10;
  const observer = useRef<IntersectionObserver | null>(null);

  const { company } = useParams();
  const companyId = parseInt(company as string, 10);

  const { getErrorMessage } = useErrorMessage();

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const getDataCallback = useCallback(async () => {
    if (page === lastCalledPage) return;
    setLoadingMore(true);

    try {
      const response = await getObservations(companyId, page, obsPerPage);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setObservations([...observations, ...response.data]);
      setLastCalledPage(page);

      if (response.detail.total_pages && response.detail.total_pages > page) {
        setPage(page + 1);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    } finally {
      setLoadingMore(false);
    }
  }, [page]);

  useEffect(() => {
    getDataCallback();
  }, []);

  const lastElementRef = (node: HTMLAnchorElement): void => {
    if (loadingMore) return;

    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && page > lastCalledPage) {
        getDataCallback();
      }
    });

    if (node) observer.current.observe(node);
  };

  return (
    <ObservationBox>
      {observations.length > 0 ? (
        <>
          {observations.map((obs) => (
            <CommentBox key={obs.id} sx={{ margin: '0 0 16px' }}>
              <Comment>
                <Box display="flex" gap="10px" alignItems="center">
                  <Typography fontSize={12}>
                    {formatDateAndTime(obs.created_at)}
                  </Typography>
                </Box>
                <StyledName />
                <Typography>{obs.contents}</Typography>
              </Comment>
            </CommentBox>
          ))}
          <Box ref={lastElementRef} />
        </>
      ) : (
        <Typography mb="20px">sem observações</Typography>
      )}
      {loadingMore && (
        <Box display="flex" justifyContent="center" my="40px">
          <LinearProgress sx={{ width: '60%' }} />
        </Box>
      )}
    </ObservationBox>
  );
}

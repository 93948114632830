import {
  Box,
  Button,
  Grid,
  IconButton,
  styled,
  TextField,
  Typography,
} from '@mui/material';

export const BackButton = styled(IconButton)(() => ({
  margin: '-20px 0 -10px -30px',
  '& span': {
    fontSize: 40,
    color: '#00B39B',
  },
}));

const SectionStyles = {
  background: '#FFFFFE',
  boxShadow: '0px 3px 6px #00000029',
  borderRadius: 4,
};

export const Section = styled(Box)(() => ({
  ...SectionStyles,
  padding: '40px 30px',
  marginTop: '21px',
}));

export const GridSection = styled(Grid)(() => ({
  ...SectionStyles,
  padding: '40px 30px',
  marginTop: '21px',
}));

export const GridItem = styled(Grid)(() => ({
  display: 'flex',
  gap: 8,
  marginTop: '28px',
}));

export const SectionTitle = styled(Typography)(() => ({
  fontSize: '21px',
  fontWeight: '600',
  color: '#707070',
}));

export const InputTitle = styled(Typography)(() => ({
  fontSize: '16px',
}));

export const StyledInput = styled(Typography)(() => ({
  fontWeight: '500',
  fontSize: '16px',
  marginRight: 8,
}));

export const EditButton = styled(Button)(() => ({
  borderRadius: '10px',
  width: '100%',
  border: '2px solid #00B39B',
  color: '#00B39B',
  fontWeight: '600',
  backgroundColor: '#FAFCFF',
  lineHeight: 1.2,
  '&:hover': {
    backgroundColor: '#00B39B',
  },
  '&:active': {
    backgroundColor: '#00B39B',
  },
}));

export const CommentBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '28px',
  marginTop: '16px',
}));

export const Comment = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
}));

export const StyledName = styled(Box)(() => ({
  fontWeight: '600',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}));

export const StyledTextField = styled(TextField)(() => ({
  width: '100%',
  '& .MuiInputBase-root': {
    borderRadius: '16px',
  },
}));

export const SubmitBox = styled(Box)(() => ({
  display: 'flex',
  marginTop: '21px',
  flex: 1,
  justifyContent: 'end',
}));

export const SubmitButton = styled(Button)(() => ({
  width: '200px',
  backgroundColor: '#004D43',
  '&:hover': {
    filter: 'brightness(85%)',
  },
}));

export const textFiledDefaultStyle = {
  marginTop: '16px',
  width: '100%',
  '& .MuiInputBase-root': { borderRadius: '16px' },
  '& .MuiSvgIcon-root': {
    color: '#004D43',
  },
  '& .MuiFormLabel-root.Mui-error': {
    color: '#595959 !important',
  },
};

export const PatternFormatStyle = {
  width: '100%',
  '& .MuiInputBase-root': { borderRadius: '16px' },
  '& .MuiSvgIcon-root': {
    color: '#004D43',
  },
  '& .MuiFormLabel-root.Mui-error': {
    color: '#595959 !important',
  },
};

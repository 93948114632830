import { Grid } from '@mui/material';
import MUIModal from '@mui/material/Modal';
import React, { useContext, useState } from 'react';

import { updateAnnouncement } from '../../../../../../api/theHive/announcement';
import { IconCloseMS } from '../../../../../../constants/icons';
import { GlobalContext } from '../../../../../../context/global';
import useErrorMessage from '../../../../../../hooks/useErrorMessage';
import {
  CancelButton,
  CloseButton,
  SaveAnnouncement,
  StyledbBoxContainer,
  TitleMoldal,
  StyledTextFieldMultiline,
} from './styles';
import { IModalViewAnnouncementsProps } from './types';

export function ModalViewAnnouncements({
  isModalOpen,
  onCloseModal,
  title,
  announcement,
  onAnnouncementUpdated,
}: IModalViewAnnouncementsProps): JSX.Element {
  const [announcementMessage, setAnnouncementMessage] = useState(
    announcement?.contents || ''
  );
  const [isLoading, setIsLoading] = useState(false);
  const { getErrorMessage } = useErrorMessage();
  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const handleChangeAnnouncement = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    setAnnouncementMessage(event.target.value);
  };

  const handleSaveAnnouncement = async (): Promise<void> => {
    try {
      setIsLoading(true);

      const updateData = {
        contents: announcementMessage,
        is_visible: announcement.is_visible,
      };

      await updateAnnouncement(announcement.id, updateData);

      if (onAnnouncementUpdated) {
        await onAnnouncementUpdated();
      }

      setSnackbarMessage('Comunicado atualizado com sucesso');
      setErrorMessage(false);
      setOpenSnackbar(true);

      onCloseModal();
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <MUIModal open={isModalOpen} onClose={onCloseModal}>
      <StyledbBoxContainer>
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Grid item xs={3.5}>
            <TitleMoldal>{title}</TitleMoldal>
          </Grid>
          <Grid item mr={4}>
            <CloseButton onClick={onCloseModal}>{IconCloseMS}</CloseButton>
          </Grid>
        </Grid>

        <Grid item container justifyContent="center" mt={4}>
          <Grid item xs={11}>
            <StyledTextFieldMultiline
              value={announcementMessage}
              onChange={handleChangeAnnouncement}
              disabled={isLoading}
            />
          </Grid>
        </Grid>

        <Grid item container justifyContent="center" mt={2}>
          <Grid item mr={2}>
            <CancelButton onClick={onCloseModal} disabled={isLoading}>
              Cancelar
            </CancelButton>
          </Grid>
          <Grid item ml={2}>
            <SaveAnnouncement
              onClick={handleSaveAnnouncement}
              disabled={isLoading}
            >
              {isLoading ? 'Salvando...' : 'Salvar'}
            </SaveAnnouncement>
          </Grid>
        </Grid>
      </StyledbBoxContainer>
    </MUIModal>
  );
}

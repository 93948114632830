import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from '@mui/material';
import React, { useState } from 'react';

import { IconVisibilityMS } from '../../../../../../constants/icons';
import { ModalViewAnnouncements } from '../ModalViewAnnouncements';
import {
  TableCellHeader,
  StyledCell,
  StyledRow,
  StyledPagination,
  StyledCellIcon,
} from './styles';
import { AnnouncementData, AnnouncementTableProps } from './types';

export function AnnouncementTable({
  data,
  page,
  totalPages,
  setPage,
  onAnnouncementUpdated,
}: AnnouncementTableProps): JSX.Element {
  const [showViewAnnouncementModal, setShowViewAnnouncementModal] =
    useState(false);
  const [selectedAnnouncement, setSelectedAnnouncement] =
    useState<AnnouncementData | null>(null);

  const handleShowViewAnnouncementModal = (
    announcement: AnnouncementData
  ): void => {
    setSelectedAnnouncement(announcement);
    setShowViewAnnouncementModal(true);
  };

  const handleCloseViewAnnouncementModal = (): void => {
    setShowViewAnnouncementModal(false);
    setSelectedAnnouncement(null);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ): void => {
    setPage(value);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCellHeader colSpan={3}>Comunicados</TableCellHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item) => (
              <StyledRow key={`row-${item.id}`}>
                <StyledCell>{item.subject}</StyledCell>
                <StyledCellIcon>
                  <IconButton
                    onClick={() => handleShowViewAnnouncementModal(item)}
                    sx={{
                      color: '#06DFC2',
                      height: '32px',
                      width: '32px',
                    }}
                  >
                    {IconVisibilityMS}
                  </IconButton>
                </StyledCellIcon>
              </StyledRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <StyledPagination
        page={page}
        count={totalPages}
        onChange={handlePageChange}
      />

      {selectedAnnouncement && (
        <ModalViewAnnouncements
          title={selectedAnnouncement.subject}
          announcement={{
            id: selectedAnnouncement.id,
            subject: selectedAnnouncement.subject,
            contents: selectedAnnouncement.contents,
            is_visible: selectedAnnouncement.is_visible,
          }}
          onCloseModal={handleCloseViewAnnouncementModal}
          isModalOpen={showViewAnnouncementModal}
          onAnnouncementUpdated={onAnnouncementUpdated}
        />
      )}
    </>
  );
}

export default AnnouncementTable;

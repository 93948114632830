import { Box, Typography, styled, Button, IconButton } from '@mui/material';

export const BackButton = styled(IconButton)(() => ({
  margin: '-20px 0 -10px -30px',
  '& span': {
    fontSize: 40,
    color: '#00B39B',
  },
}));

export const NoticeTitle = styled(Typography)(() => ({
  fontSize: '21px',
  color: '#707070',
  fontFamily: 'Montserrat',
  fontWeight: 600,
  display: 'flex',
  alignItems: 'center',
  height: '100%',
}));

export const InputTitle = styled(Typography)(() => ({
  color: '#707070',
  paddingBottom: '4px',
  paddingRight: '12px',
  fontSize: '16px',
}));

export const StyledInput = styled(Typography)(() => ({
  fontWeight: '600',
  fontSize: '18px',
  color: '#707070',
}));

export const NoticeContainer = styled(Box)(() => ({
  padding: '10px 10px',
}));

export const OutlinedButton = styled(Button)(() => ({
  width: '184px',
  display: 'flex',
  gap: '10px',
  border: '1px solid #00B39B',
  borderRadius: '10px',
  color: '#00B39B',
  fontWeight: '600',
  height: '56px',
  backgroundColor: '#FAFCFF',
  textTransform: 'lowercase',
  '&:hover': {
    backgroundColor: '#00B39B',
  },
  '&:disabled': {
    color: '#00B39B',
  },
}));

export const TableSection = styled(Box)(() => ({
  marginTop: '42px',
}));

export const AddButton = styled(Button)(() => ({
  display: 'flex',
  gap: '10px',
  border: '1px solid #00B39B',
  borderRadius: '30px',
  color: '#FAFCFF',
  fontWeight: '600',
  height: '56px',
  backgroundColor: '#00B39B',
  textTransform: 'lowercase',
  fontFamily: 'Montserrat',

  '&:hover': {
    backgroundColor: '#FAFCFF',
    color: '#00B39B',
  },
  '&:disabled': {
    color: '#00B39B',
  },
}));

import { IconNotificationsMS } from '../../constants/icons';
import { GridContainer, TitleBox, TitleTypography } from './styles';
import { NotificationsTable } from './Table';

export function Notifications(): JSX.Element {
  return (
    <GridContainer>
      <TitleBox>
        <TitleTypography>{IconNotificationsMS} Notificações</TitleTypography>
      </TitleBox>
      <NotificationsTable />
    </GridContainer>
  );
}

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback, useContext } from 'react';

import { getNoticeCoverage } from '../../../../../../../api/theHive/noticeCoverage';
import { CoverageDetail } from '../../../../../../../api/theHive/noticeCoverage/types';
import { GlobalContext } from '../../../../../../../context/global';
import useErrorMessage from '../../../../../../../hooks/useErrorMessage';

interface CoverageData {
  mainRegions: {
    cityId: number;
    displacementFee: number;
    city: string;
    id?: any;
    subregions: {
      cityId: number;
      subregion?: string;
    }[];
  }[];
}

interface UseCoverageOperationsProps {
  noticeId: number;
  onSuccess?: () => void;
}

interface CoverageOperationsReturn {
  isLoading: boolean;
  coverageData: CoverageData | null;
  getCoverage: () => Promise<boolean>;
}



export const useFetchCoverageOperations = ({
  noticeId,
  onSuccess,
}: UseCoverageOperationsProps): CoverageOperationsReturn => {
  const [isLoading, setIsLoading] = useState(false);
  const [coverageData, setCoverageData] = useState<CoverageData | null>(null);
  const { getErrorMessage } = useErrorMessage();
  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const handleApiOperation = async (
  operation: () => Promise<CoverageDetail>,
  successMessage: string
): Promise<boolean> => {
  setIsLoading(true);
  try {
    const response = await operation();

    if (response.detail?.status_code === 0) {
      setErrorMessage(false);
      setCoverageData(response.data as unknown as CoverageData);
      onSuccess?.();
      return true;
    }
    
    throw new Error(response.detail?.description || 'Operation failed');
  } catch (error) {
    setSnackbarMessage(getErrorMessage(error));
    setErrorMessage(true);
    setOpenSnackbar(true);
    return false;
  } finally {
    setIsLoading(false);
  }
};


const getCoverage = useCallback(async (): Promise<boolean> => {
  return handleApiOperation(
    () => getNoticeCoverage(noticeId),
    'Cobertura obtida com sucesso',
  );
}, [noticeId]);

  return {
    isLoading,
    coverageData,
    getCoverage,
  };
};